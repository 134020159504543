import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from "@angular-material-components/datetime-picker";
import { NgxMatMomentModule } from "@angular-material-components/moment-adapter";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule, MatInputModule, MatMenuModule, MatNativeDateModule, MatDialogModule, MatTabsModule, MatGridListModule, MatPaginatorModule, MatDatepickerModule, MatSelectModule, MatButtonModule } from "@angular/material";
import { MentionModule } from "angular-mentions";
import { SelectAutocompleteModule } from "mat-select-autocomplete";
import { NgFileDragDropModule } from "ng-file-drag-drop";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { AmountFormatDirective } from "src/app/directive/AmountFormatDirective";
import { DirectiveModule } from "src/app/directive/directive.module";
import { UploadMappingComponent } from "src/app/settings/upload-mapping/upload-mapping/upload-mapping.component";
import { SharedModulesModule } from "src/app/shared-files/shared-modules/shared-modules.module";
import { CredlixBoardRoutingModule } from "./credlix-board-routing.module";
import { DeimalNumberDirective } from "./deimal-number.directive";
import { AddNewLeadComponent } from "./k-board/add-new-lead/add-new-lead.component";
import { BoardLaneComponent } from "./k-board/board-lane/board-lane.component";
import { AddBuyerDetailsComponent } from "./k-board/board-lane/card-details/add-buyer-details/add-buyer-details.component";
import { ApprovedBuyerListComponent } from "./k-board/board-lane/card-details/approved-buyer-list/approved-buyer-list.component";
import { CardDetailsComponent } from "./k-board/board-lane/card-details/card-details.component";
import { CommentsTabComponent } from "./k-board/board-lane/card-details/comments-tab/comments-tab.component";
import { FillInsuranceComponent } from "./k-board/board-lane/card-details/fill-insurance/fill-insurance.component";
import { FilterHistoryDialogComponent } from "./k-board/board-lane/card-details/filter-history-dialog/filter-history-dialog.component";
import { HistoryTabComponent } from "./k-board/board-lane/card-details/history-tab/history-tab.component";
import { InsuranceDataListComponent } from "./k-board/board-lane/card-details/insurance-data-list/insurance-data-list.component";
import { LaneTaskDocumentsTabComponent } from "./k-board/board-lane/card-details/lane-task-documents-tab/lane-task-documents-tab.component";
import { LaneTaskUsersTabComponent } from "./k-board/board-lane/card-details/lane-task-users-tab/lane-task-users-tab.component";
import { PreQualifiedLaneMoveComponent } from "./k-board/board-lane/card-details/pre-qualified-lane-move/pre-qualified-lane-move.component";
import { UpdateMilestoneCnfComponent } from "./k-board/board-lane/card-details/update-milestone-cnf/update-milestone-cnf.component";
import { WatcherPopupComponent } from "./k-board/board-lane/card-details/watcher-popup/watcher-popup.component";
import { WhatsappTemplateComponent } from "./k-board/board-lane/card-details/whatsapp-template/whatsapp-template.component";
import { BriefBuyerComponent } from "./k-board/board-lane/underwriting/brief-buyer/brief-buyer.component";
import { ProductDetailsComponent } from "./k-board/board-lane/underwriting/product-details/product-details.component";
import { TradingHistoryComponent } from "./k-board/board-lane/underwriting/trading-history/trading-history.component";
import { UnderwritingComponent } from "./k-board/board-lane/underwriting/underwriting.component";
import { UploadFileBuyerComponent } from "./k-board/board-lane/upload-file-buyer/upload-file-buyer.component";
import { UploadPanjivaDialogComponent } from "./k-board/board-lane/upload-panjiva-dialog/upload-panjiva-dialog.component";
import { ViewLaneComponent } from "./k-board/board-lane/view-lane/view-lane.component";
import { BulkGtDialogComponent } from "./k-board/bulk-gt-dialog/bulk-gt-dialog.component";
import { FunnnelDashboardComponent } from "./k-board/funnnel-dashboard/funnnel-dashboard.component";
import { KBoardFiltersComponent } from "./k-board/k-board-filters/k-board-filters.component";
import { KBoardComponent } from "./k-board/k-board.component";
import { LaneMoveComponent } from "./k-board/lanes-list/lane-move/lane-move.component";
import { LanesListComponent } from "./k-board/lanes-list/lanes-list.component";
import { KUserManagementComponent } from "./k-user-management/k-user-management.component";
import { LosTrackerCommentDailogComponent } from "./los-tracker/los-tracker-comment-dailog/los-tracker-comment-dailog.component";
import { LosTrackerComponent } from "./los-tracker/los-tracker.component";
import { TermsheetlistingComponent } from "./termsheetlisting/termsheetlisting.component";
import { FunnelCommentComponent } from './k-board/funnel-comment/funnel-comment.component';
import { LosFiltersComponent } from "./k-board/los-filters/los-filters.component";
import { LeadOnQualifierComponent } from "./k-board/lanes-list/lead-on-qualifier/lead-on-qualifier.component";
import { CreditTrackerCommentComponent } from "./credit-tracker/credit-tracker-comment/credit-tracker-comment.component";
import { SentToUserComponent } from "./k-board/board-lane/card-details/gst-auto-generate/sent-to-user/sent-to-user.component";
import { LeadDetailsComponent } from './k-board/board-lane/lead-details/lead-details.component';
import { NewLeadsComponent } from './k-board/board-lane/new-leads/new-leads.component';
import { DetailsDocumentsComponent } from './k-board/board-lane/details-documents/details-documents.component';
import { GstAnalysisComponent } from './k-board/board-lane/gst-analysis/gst-analysis.component';
import { UnderwritingDocComponent } from './k-board/board-lane/underwriting-doc/underwriting-doc.component';
import { UnderwritingStageComponent } from './k-board/board-lane/underwriting-stage/underwriting-stage.component';
import { PostSanctionDocComponent } from './k-board/board-lane/post-sanction-doc/post-sanction-doc.component';
import { DocValidationComponent } from './k-board/board-lane/doc-validation/doc-validation.component';
import { CAMSComponent } from './k-board/board-lane/cams/cams.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { FileDailogComponent } from './k-board/board-lane/file-dailog/file-dailog.component';
import { PipeModule } from "src/app/pipe/pipe.module";
import { GstAutoGenerateComponent } from "./k-board/board-lane/card-details/gst-auto-generate/gst-auto-generate.component";
import { CreditTrackerComponent } from "./credit-tracker/credit-tracker.component";
import { LosOneDriveComponent } from "./k-board/board-lane/card-details/los-one-drive/los-one-drive.component";
import { RemarksDailogComponent } from './k-board/board-lane/remarks-dailog/remarks-dailog.component';
import { DefferedPopupComponent } from './k-board/board-lane/deffered-popup/deffered-popup.component';
import { DomesticHistoryComponent } from './k-board/board-lane/domestic-history/domestic-history.component';
import { ScoreCardComponent } from "./k-board/board-lane/card-details/score-card/score-card.component";
import { SentForConsentPopupComponent } from './k-board/board-lane/sent-for-consent-popup/sent-for-consent-popup.component';
import { ShowConsentStatusComponent } from "./k-board/board-lane/card-details/show-consent-status/show-consent-status.component";

@NgModule({
  declarations:
    [AmountFormatDirective, KBoardComponent, FilterHistoryDialogComponent,
      BoardLaneComponent, LanesListComponent, LaneMoveComponent,
      CardDetailsComponent, KBoardFiltersComponent, AddNewLeadComponent,
      KUserManagementComponent, UploadPanjivaDialogComponent,
      UnderwritingComponent, BriefBuyerComponent,
      TradingHistoryComponent, ProductDetailsComponent,
      DeimalNumberDirective, BulkGtDialogComponent,
      TermsheetlistingComponent, HistoryTabComponent,
      WatcherPopupComponent, CommentsTabComponent,
      LaneTaskUsersTabComponent, LaneTaskDocumentsTabComponent,
      ViewLaneComponent, FillInsuranceComponent,
      WhatsappTemplateComponent, InsuranceDataListComponent,
      LosTrackerComponent, LosTrackerCommentDailogComponent,
      ApprovedBuyerListComponent, AddBuyerDetailsComponent,
      UploadFileBuyerComponent, UpdateMilestoneCnfComponent, PreQualifiedLaneMoveComponent, LosFiltersComponent, 
      FunnnelDashboardComponent, FunnelCommentComponent,
      DocValidationComponent, CAMSComponent, FileDailogComponent,
      GstAutoGenerateComponent, SentToUserComponent, LeadDetailsComponent, 
      NewLeadsComponent, DetailsDocumentsComponent, GstAnalysisComponent, UnderwritingDocComponent, UnderwritingStageComponent, PostSanctionDocComponent,
      CreditTrackerCommentComponent,
      CreditTrackerComponent,LeadOnQualifierComponent,LosOneDriveComponent, RemarksDailogComponent, DefferedPopupComponent, DomesticHistoryComponent, SentForConsentPopupComponent,ShowConsentStatusComponent],
  imports: [
    CommonModule,
    CredlixBoardRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DirectiveModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    Ng2SearchPipeModule,
    InfiniteScrollModule,
    SharedModulesModule,
    DragDropModule, MatMenuModule,
    SelectAutocompleteModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    MatNativeDateModule,
    MentionModule,
    NgFileDragDropModule,
    MatDialogModule,
    MatTabsModule,
    MatGridListModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatCheckboxModule,
    PipeModule
  ],
  entryComponents: [
    LanesListComponent,
    UploadPanjivaDialogComponent,
    AddNewLeadComponent,
    LaneMoveComponent,
    KBoardFiltersComponent,
    UploadMappingComponent,
    BulkGtDialogComponent,
    CommentsTabComponent,
    LaneTaskUsersTabComponent,
    HistoryTabComponent,
    WatcherPopupComponent,
    LaneTaskDocumentsTabComponent,
    ViewLaneComponent,
    FilterHistoryDialogComponent,
    CreditTrackerCommentComponent,
    FillInsuranceComponent, WhatsappTemplateComponent, InsuranceDataListComponent, LosTrackerCommentDailogComponent, AddBuyerDetailsComponent, UploadFileBuyerComponent, UpdateMilestoneCnfComponent, PreQualifiedLaneMoveComponent, LosFiltersComponent, FunnelCommentComponent, SentToUserComponent, FileDailogComponent,LeadOnQualifierComponent,RemarksDailogComponent,DefferedPopupComponent,ScoreCardComponent,SentForConsentPopupComponent,ShowConsentStatusComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  //exports:[DragDropModule]
})
export class CredlixBoardModule { }
