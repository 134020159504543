import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { KanbanService } from '../kanban.service';
import { LanesListComponent } from './lanes-list/lanes-list.component';
import { KBoardFiltersComponent } from './k-board-filters/k-board-filters.component';
import { AddNewLeadComponent } from './add-new-lead/add-new-lead.component';
import { Router } from '@angular/router';
import { UploadPanjivaDialogComponent } from './board-lane/upload-panjiva-dialog/upload-panjiva-dialog.component';
import { BoardLaneComponent } from './board-lane/board-lane.component';
import { PresaleService } from '../../pre-sales/presale.service';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import { PagesService } from 'src/app/pages/pages.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-k-board',
  templateUrl: './k-board-v1.component.html',
  styleUrls: ['./k-board.component.scss']
})
export class KBoardComponent implements OnInit {
  searchQuery: string = '';
  selectedFilter: string = '';
  filters = [
    { label: 'Filter 1', value: 'filter1' },
    { label: 'Filter 2', value: 'filter2' },
    // Add more filters as needed
  ];
  searchSubject: Subject<any> = new Subject<any>();
  boardId: any;
  sendFilteredReqObj = {};
  searchText: string;
  showCloseIcon: boolean = false;
  isLoading: boolean = false;
  laneAccessOn: boolean = false;
  breakpoint: number;
  boardStats: any;
  preQualifiedCount: number = 0
  recordDate: number = 0;
  recordData: any;
  recordList: any = [
    { day: "Today", value: 0 }, { day: "Last 7 Days", value: 7 }, 
    { day: "Last 30 Days", value: 30 },{day: "Leads Till date", value: -1}
  ];
  permission = [];
  placeHolder:string='Exim ID, Company Name';
  @ViewChild(BoardLaneComponent) boardLaneComp: BoardLaneComponent;
  isVisibleLanes: any = true;
  isSobFilterApply: boolean = false;
  isBusinessLoan: boolean;
  constructor(public _kService: KanbanService, public router: Router,
    public dialog: MatDialog, public preSaleService: PresaleService,
    public toasterService: ToastrService, public pagesService: PagesService,
    public cRef: ChangeDetectorRef) {
    this.isVisibleLanes
  }

  boardList = [];
  boardLane: any = [
    {
      "name": "all-leads",
      "displayName": "All Leads"
    }
  ];
  dashboard = [
    {
      "title": "All Leads",
      "class": "all-leads",
      "slug": "all-leads",
      "background": "transparent linear-gradient(114deg, #EB4886 0%, #BA54A4 100%) 0% 0% no-repeat padding-box;"
    },
    {
      "title": "Fresh Leads",
      "class": "fresh-leads",
      "slug": "fresh-leads",
      "background": "transparent linear-gradient(104deg, #A4D308 0%, #7D9613 100%) 0% 0% no-repeat padding-box;"
    },
    {
      "title": "Data Pending",
      "class": "data-pending",
      "slug": "data-pending",
      "background": "transparent linear-gradient(101deg, #42B6E0 0%, #6592DA 100%) 0% 0% no-repeat padding-box;"
    },
    {
      "title": "Termsheet Issued",
      "class": "follow-up",
      "slug": "termsheet-issued",
      "background": "transparent linear-gradient(104deg, #F2AC21 0%, #F5805A 100%) 0% 0% no-repeat padding-box;"
    },

    {
      "title": "Non-Moving",
      "class": "non-moving",
      "slug": "non-moving",
      "background": "transparent linear-gradient(104deg, #D9232D 0%, #F5681E 100%) 0% 0% no-repeat padding-box;"
    },
    {
      "title": "Rejected",
      "class": "rejected",
      "slug": "cold",
      "background": "transparent linear-gradient(101deg, #42B6E0 0%, #6592DA 100%) 0% 0% no-repeat padding-box;"
    }
  ]

  selectedDashItem: any = this.boardLane[0];
  selectedBoardName: string = 'exim';
  selectedTabIndex:number;
  ngOnInit() {
    this.getPreQualifiedCount();
    let getSessionStorage = JSON.parse(localStorage.getItem("USERINFO"));
    this.permission = getSessionStorage.role.role['permissions'];
    this.searchSubject.pipe(debounceTime(2000)).subscribe((value: any) => {
      this.onSearchChange(value)
    });
    this._kService.refreshKboard.subscribe(val => {
      if (val) {
        this.getPreQualifiedCount();
        this.fetchBoardLane(null);
      }
    })
    this.setBreakPoint();
    this.getBoardList();
    this.pagesService.moveToLostCurrent.subscribe(res => {
      if (res) {
        this.selectedDashItem = this.boardLane[0];

      }
    })
    this._kService.isVisibleLanes.subscribe((newValue) => {
      this.isVisibleLanes = newValue;
    });

  }

  ngAfterViewInit() {
    this.cRef.detectChanges();
  }

  onSearch(searchTerm) {
    this.searchSubject.next(searchTerm);
  }

  reloadPage() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(currentUrl);
    });
  }

  onResize(event) {
    //this.breakpoint = (event.target.innerWidth <= 400) ? 1 : 6;
    this.setBreakPoint();
  }

  setBreakPoint() {
    if ((window.innerWidth <= 400)) {
      this.breakpoint = 1
    } else if ((window.innerWidth > 400) && (window.innerWidth <= 768)) {
      this.breakpoint = 3
    } else if ((window.innerWidth > 768) && (window.innerWidth <= 1390)) {
      this.breakpoint = 5;
    } else if ((window.innerWidth > 1390)) {
      this.breakpoint = 7;
    }
  }
  
  selectLaneCard = (dashItem, index) => {
    if (this.selectedDashItem.name !== dashItem.name) {
      // sessionStorage.clear();
    
      if(this.getTabIndex == 1){
        let domesticObj = {
          isDomestic : true,
          selectedLane : index ? index : 0
         }
        sessionStorage.setItem("domesticData",JSON.stringify(domesticObj));
        let eximObj = sessionStorage.getItem('eximData');
        if(eximObj){
          let obj = JSON.parse(eximObj)
          obj.isExim = false;
        sessionStorage.setItem("eximData",JSON.stringify(obj));
        }else{
          let eximObj = {
            isExim: false,
            selectedLane: 0
          }
          sessionStorage.setItem("eximData",JSON.stringify(eximObj));
        }
      } else {
        let eximObj = {
          isExim : true,
          selectedLane : index ? index : 0
         }
        sessionStorage.setItem("eximData",JSON.stringify(eximObj));
        let domesticObj = sessionStorage.getItem('domesticData');
        if(domesticObj){
          let obj = JSON.parse(domesticObj)
          obj.isDomestic = false;
        sessionStorage.setItem("domesticData",JSON.stringify(obj));
        }else{
          let domesticObj = {
            isDomestic: false,
            selectedLane: 0            
          }
          sessionStorage.setItem("domesticData",JSON.stringify(domesticObj));
        }
      }
      this._kService.isTabChanageFromTile = true;
      this.selectedDashItem = dashItem;
      this.isLoading = !this.isLoading;
      if (this.selectedDashItem['name'] == "pre-qualified") {
        this.getRecordCount()
      }
      if (this.selectedDashItem['name'] == "all-leads" && this.isSobFilterApply) {
        this.isSobFilterApply = false;
      }
    }
  }

  get isPreQualifiedLane() {
    if (this.selectedDashItem != null && this.selectedDashItem['name'] == "pre-qualified") {
      return true;
    }
  }

  getBoardStats() {
    let tmpQueryLanes = [];
    if (this.boardLaneResponse != null && (this.boardStats == null || this.boardStats[this.boardId] == null)) {
      for (let i = 0; i < this.boardLaneResponse['result']['levelAccess'].length; i++) {
        tmpQueryLanes.push(this.boardLaneResponse['result']['levelAccess'][i]['laneDTO'].id);
      }
      tmpQueryLanes;
      let obj = {
        "where": {
          "lane.$id": {
            "type": "objectid_in",
            "value": tmpQueryLanes
          },
          "isDeleted": {
            "type": "search",
            "value": false
          }
        }
      }
    }
  }

  getBoardList() {
    this._kService.getBoardList().subscribe({
      next:(res:any) => {
      this.boardList = res['result'];
      if (this.boardList != null && this.boardList.length > 0) {
        this.boardId = this.boardList[0].id;
        this.submitSearch();
      }
    },
    complete:()=>this.selectedTabIndex =  sessionStorage.domesticData && JSON.parse(sessionStorage.domesticData).isDomestic? 1 : 0
    })
  }

  togglekBoardUserManagement() {
    this.laneAccessOn = !this.laneAccessOn;
    if (this.laneAccessOn) {
      this.fetchBoardLane(null);
    }
  }

  //Setting tab chnages like back and open
  chnageSettingTab() {
    this.laneAccessOn = !this.laneAccessOn;
    this.fetchBoardLane(null)
  }

  openKBoardUserManagement() {
    this.router.navigate(['/KboardUsermanagement/' + this.boardId]);
  }

  getLaneList() {
    const dialogRef = this.dialog.open(LanesListComponent, {
      width: '373px',
      height: '100%',
      panelClass: 'eximSetting'
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  boardFiltersOpen() {
    const dialogRef = this.dialog.open(KBoardFiltersComponent, {
      width: '373px',
      height: '100%',
      data: {
        boardId: this.boardId,
        reqObj: this.sendFilteredReqObj
      },
      panelClass: 'eximSetting'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.sendFilteredReqObj = result
      if (!result.close)
        this.getBoardList();

    });
  }

  getPreQualifiedCount() {
    return new Promise((resolve, reject) => {
      try {
        const businessType = this.selectedBoardName == 'exim' ? 'Exim' : 'Domestic';
        this.preSaleService.getPreQualifiedCount(businessType).subscribe(res => {
          this.preQualifiedCount = res['total'];
          resolve(true);
        })
      } catch (error) {
        console.error("getPreQualifiedCount : ", error);
        resolve(false);
      }
    });
  }

  fetchLaneConfigById;
  boardLaneResponse;
  fetchBoardLane(boardIdTmp: string) {
    // this.getLaneSubscription.unsubscribe();
    // if(sessionStorage.length>0)
    this.boardLane = [];
    this.boardLaneResponse = [];
    let tmp1: any = boardIdTmp != null ? boardIdTmp : this.boardId;
    this._kService.getLaneWithUserAccess(tmp1, '').subscribe(res => {
      this.boardId = tmp1;
      this.boardLaneResponse = res;
      this._kService.totalRecords = res['count'];
      let tmp = []
      tmp = [
        {
          "name": "all-leads",
          "displayName": "All Leads"
        },
      ];
      const boardNames = ['exim', 'domestic'];
      if (boardNames.includes(this.selectedBoardName) && this.permission.includes('VIEW_PRE_QUALIFIED')) {
        tmp.push({
          "name": "pre-qualified",
          "displayName": "Pre Qualified",
          "totalLanetask": this.preQualifiedCount
        })
      }
      for (let i = 0; i < res['result']['levelAccess'].length; i++) {
        let t = res['result']['levelAccess'][i].laneDTO;
        t['class'] = "data-pending";
        if (res['result']['levelAccess'][i].laneDTO.position == 1) {
          this.fetchLaneConfigById = res['result']['levelAccess'][i].laneDTO.id;
        }
        tmp.push(t);
      }
      this.boardLane = tmp;
      this.boardLane = this.boardLane.sort((a, b) => a.position - b.position);
      let domesticData = JSON.parse(sessionStorage.getItem("domesticData"));
      let eximData = JSON.parse(sessionStorage.getItem("eximData"));
      if(this.getTabIndex == 1){
        this.selectedDashItem = domesticData ? this.boardLane[Number(domesticData.selectedLane)] : this.boardLane[0];
      }else{
        this.selectedDashItem = eximData ? this.boardLane[Number(eximData.selectedLane)] : this.boardLane[0];
      }
      if (eximData && eximData.selectedLane == 1) {
        this.getRecordCount();
      }
      this.getBoardStats();
    })
  }

  addNewLead() {
    const dialogRef = this.dialog.open(AddNewLeadComponent, {
      disableClose: true,
      width: '350px',
      height: '250px',
      data: {
        boardId: this.fetchLaneConfigById,
        boardName: this.selectedBoardName,
        boardType: this.getTabIndex == 0 ? 'EXIM' : 'DOMESTIC'
      },
      panelClass: 'addnewleadoverlay'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        // this.getBoardList();
        this.reloadPage();
      }
    });
  }

  getTabIndex: number
  getBoardTabChange(event) {
    this.getTabIndex = event.index
    this.placeHolder = event.index == 1? "Domestic ID, Company Name":"Exim ID, Company Name";
    this.searchText = ''
    this.showCloseIcon = false;
    this.boardLane = [];
    this.boardLaneResponse = [];
    this.selectedBoardName = this.boardList[event.index].name;
    // this.getLaneSubscription ? this.getLaneSubscription.unsubscribe() : null;
    this.getPreQualifiedCount();
    this.fetchBoardLane(this.boardList[event.index].id)
  }


  uploadPanjivaFile(status) {
    const dialogRef = this.dialog.open(UploadPanjivaDialogComponent, {
      disableClose: true,
      width: '450px',
      height: '240px',
      data: status,
      panelClass: 'addnewleadoverlay'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.ngOnInit();
      }
    });
  }

  storeSearchResponse = [];
  showListing: boolean = false;
  autoSuggestloading: boolean = false

  submitSearch() {
    if (this.searchText && this.searchText.trim() == "") {
      this.resetSearchText();
    }
    this.selectedDashItem = this.boardLane[0];
    this.sendFilteredReqObj = {
      "searchText": this.searchText
    }
    this.showListing = false;
  }

  onSearchChange(value) {
    this.autoSuggestloading = true
    this.showCloseIcon = true;

    if (value) {
      if (value.trim().length > 1) {
        let tmpQueryLanes = [];
        if (this.boardLaneResponse && this.boardLaneResponse['result'] && this.boardLaneResponse['result']['levelAccess'] && this.boardLaneResponse['result']['levelAccess'].length > 0) {
          for (let i = 0; i < this.boardLaneResponse['result']['levelAccess'].length; i++) {
            tmpQueryLanes.push(this.boardLaneResponse['result']['levelAccess'][i]['laneDTO'].id);
          }
        }
        let obj = {
          "where":
          {
            "boardType": {
              "type": "search",
              "value": this.selectedBoardName.toUpperCase()
            },
            "multi_match_query": {
              "type": "multi_match_query",
              "value": [
                {
                  "field": "displayName",
                  "value": this.searchText,
                  "type": "like"
                },
                {
                  "field": "displaySequenceName",
                  "value": this.searchText,
                  "type": "like"
                },
                {
                  "field": "buyerName",
                  "value": this.searchText,
                  "type": "like"
                }
              ]
            }
          },
          "sort": { "field": "createdAt", "order": "desc" },
          "from": 0, "size": 20
        }
        // setTimeout(function () {
        //   this.autoSuggestloading = false;
        // }.bind(this), 500);
        this._kService.getLaneTask(obj).subscribe(res => {
          this.autoSuggestloading = false;
          this.storeSearchResponse = [].concat(res['result']);
          this.showListing = true;

        })
        //this.boardLaneComp.getLaneTask(obj);
      }
    }
    else {
      this.showCloseIcon = false;
      this.boardLaneComp.ngAfterViewInit();
    }
  }


  getCardDetails(list) {
    if (this.getTabIndex == 0)
      window.open('/#/cardDetails/' + list.id, "_blank");
    else {
      window.open('/#/lead-details/' + `${list.lane.name}` + '/' + list.id, "_blank")
    }
    this.showListing = false;
  }

  hideLanes() {
    this.isVisibleLanes = !this.isVisibleLanes;
    this._kService.setValue(this.isVisibleLanes);
  }

  resetSearchText() {
    this.searchText = "";
    this.showCloseIcon = false;
    this.showListing = false;
    //this.boardLaneComp.ngAfterViewInit();
  }

  animal: string;
  name: string;

  openDialog(): void {
    this.router.navigate(['/bulkListing/' + this.boardId]);
    // const dialogRef = this.dialog.open(BulkGtDialogComponent, {
    //   width: '516px',
    //   height: '600px',
    //   data: this.boardId
    // });
  }
  
  changeDays(event) {
    let selectedDay = event.value;
    this.getRecordCount(selectedDay);
  }

  getRecordCount(val?) {
    let obj = {
      "timePeriod": val ? val : this.recordDate,
      "businessType": this.selectedBoardName == 'exim' ? 'Exim' : 'Domestic'
    }
    this.preSaleService.getRecords(obj).subscribe(res => {
      this.recordData = res['result'];
    })
  }

  downloadLeadSummary() {
    let obj = {
      timePeriod: this.recordDate
    }
    this.preSaleService.downloadLeadSummary(obj).subscribe(res => {
      var contentDispositionHeader = res.headers.get('content-disposition');
      let result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
      result = result.replace(/"/g, '')
      saveAs(res.body, result);
      return result.replace(/"/g, '');
    })
  }

  openApprovedBuyer() {
    this.router.navigate(['credlixboard/approvedBuyers'])
  }
  
  sobFilter() {
    this.isSobFilterApply = !this.isSobFilterApply
  }

  getSOBFilterClass() {
    if (this.isSobFilterApply) {
      return 'active';
    }
  }
  onBusinessLoanToggle() {
    this.isBusinessLoan = !this.isBusinessLoan;
    this._kService.setBusinessLoanValue(this.isBusinessLoan);
  }
}
