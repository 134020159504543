<div class="main_content" style="background: #F7F7F7 0% 0% no-repeat padding-box;">
  <div class="eximParentComponent">
    <div class="headerPart">
      <div class="summary-div" *ngIf="isPreQualifiedLane">
        <div class="inner-summary-div">
          <div class="border-right p-5">
            <mat-form-field appearance="outline" class="mog3-matselect">
              <label>Select Date</label>
              <mat-select placeholder="Select Date" [(ngModel)]="recordDate" (selectionChange)="changeDays($event)">
                <mat-option *ngFor="let record of recordList" [value]="record.value">{{record.day}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="inner-summary-box">
            <div class="img-div red">
              <img src="/assets/icons/report.svg" alt="1000 Total Assignments" />
            </div>
            <p><strong>{{recordData?.totalAssignment}}</strong><br /> <span>Total Assignments</span></p>
          </div>
          <div class="inner-summary-box">
            <div class="img-div orange">
              <img src="/assets/icons/funnel.svg" alt="1000 Total Assignments" />
            </div>
            <p><strong>{{recordData?.leadWorkedUpon}}</strong><br /> <span>Worked upon</span></p>
          </div>
          <div class="inner-summary-box">
            <div class="img-div green">
              <img src="/assets/icons/sucess.svg" alt="1000 Total Assignments" />
            </div>
            <p><strong>{{recordData?.qualificationDone}}</strong><br /> <span>Qualification Done</span></p>
          </div>
          <div clas="inner-summary-box">
            <div class="alignDownload">
              <mat-icon (click)="downloadLeadSummary()" matTooltip="Download Lead Summary"
                matTooltipClass="new-tooltip">download</mat-icon>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="left">
          <span class="los-title" >L.O.S</span>
        </div> -->
    </div>
    <div class="board-dashboard" *ngIf="boardLane.length > 0 && !laneAccessOn">
      <div class="button-div" rowHeight="4:1" (window:resize)="onResize($event)" gutterSize="1.2rem"
        *ngIf="isVisibleLanes">
        <div class="lane-grid" *ngFor="let dashItem of boardLane; let i = index" (click)="selectLaneCard(dashItem, i);"
          [ngClass]="{'all-leads': dashItem.name == selectedDashItem.name}">
          <button class="dash-button dash-button-font" [ngClass]="{'trans': dashItem.name == selectedDashItem.name}">
            {{dashItem.displayName}} <span>({{dashItem.displayName === 'All Leads' ? this._kService.totalRecords :
              dashItem.totalLanetask}})</span>
          </button>
        </div>
      </div>
      <div *ngIf="isVisibleLanes &&isPreQualifiedLane && selectedBoardName != 'exim'" style="height: 0;">
      <p><mat-slide-toggle labelPosition="before" (change)="onBusinessLoanToggle()"><span class="businessLoanBtn">Business Loan</span></mat-slide-toggle></p>
      </div>
    </div>
    <div class="maincardbox" *ngIf="boardLane.length > 0 && !laneAccessOn">
      <div class="cardbelowmain" *ngIf="boardLane.length > 0 && !laneAccessOn">
        <div class="cardbelow-box" *ngIf="!isPreQualifiedLane">
          <p>All Leads ({{_kService.totalRecords}})</p>
          <div *ngIf="!laneAccessOn">
            <div class="search-container">
              <div class="srchBox">
                <mat-icon aria-hidden="false" aria-label="Company Name" fontIcon="home"
                  class="material-symbols-outlined comments-icon">search</mat-icon>
                <input type="text" placeholder={{placeHolder}} [(ngModel)]="searchText"
                  (input)="onSearch(searchText)" (keyup.enter)="submitSearch()" [matMenuTriggerFor]="searchMenu" />
                <mat-icon aria-hidden="false" aria-label="Company Name" fontIcon="home" matTooltip="clear"
                  matTooltipClass="new-tooltip" class="material-symbols-outlined comments-icon"
                  (click)="resetSearchText();submitSearch()" *ngIf="searchText && searchText != ''">close</mat-icon>
              </div>
            </div>
            <mat-menu #searchMenu="matMenu" class="searchMenu">
              <div class="searchedListing" *ngIf="showListing && searchText.length>1">
                <div class="sk-fading-circle" *ngIf="autoSuggestloading">
                  <div class="sk-circle1 sk-circle"></div>
                  <div class="sk-circle2 sk-circle"></div>
                  <div class="sk-circle3 sk-circle"></div>
                  <div class="sk-circle4 sk-circle"></div>
                  <div class="sk-circle5 sk-circle"></div>
                  <div class="sk-circle6 sk-circle"></div>
                  <div class="sk-circle7 sk-circle"></div>
                  <div class="sk-circle8 sk-circle"></div>
                  <div class="sk-circle9 sk-circle"></div>
                  <div class="sk-circle10 sk-circle"></div>
                  <div class="sk-circle11 sk-circle"></div>
                  <div class="sk-circle12 sk-circle"></div>
                </div>
                <div *ngIf="storeSearchResponse.length>0 && autoSuggestloading == false">
                  <ul *ngFor="let list of storeSearchResponse; let i = index">
                    <li (click)="getCardDetails(list)">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxLayout="row" fxLayoutAlign="center center" class="margin-right  company-icon-div">
                          <mat-icon aria-hidden="false" aria-label="Company Name" fontIcon="home"
                            matTooltip="Company Name" matTooltipClass="new-tooltip"
                            class="material-symbols-outlined company-icon">corporate_fare</mat-icon>

                        </div>
                        <div fxLayout="column" fxLayoutAlign="center start" style="width: 100%;">
                          <span class="table-title" fxLayout="row" fxLayoutAlign="start center"
                            style="width: 100%;"><label class="lbLabel">Lead Name:</label> <span
                              class="ldname">{{list.name || "N/A"}}</span></span>
                          <span class="table-title" fxLayout="row" fxLayoutAlign="start center"
                            style="width: 100%;"><label class="lbLabel">Status :</label> <span
                              class="ldname">{{list.lane.name }}</span></span>
                          <span class="table-title" fxLayout="row" fxLayoutAlign="start center"
                            style="width: 100%;"><label class="lbLabel">ID:</label> <span
                              class="ldname">{{list.displayId}}</span></span>
                        </div>
                      </div>

                    </li>

                  </ul>
                  <!-- <ul  class="auto-all" (click)="submitSearch()">
                        <div fxLayout="row" fxLayoutAlign="center center">
                          <span class="table-title" fxLayout="row" fxLayoutAlign="start center" ><label class="lbLabel" style="width: 100%;" >View All Results for : {{searchText}} <span class="enterLabel">(Press Enter)</span></label> </span>
                        </div>
                      </ul> -->
                </div>
              </div>
            </mat-menu>

          </div>
          <div class="lead-box">
            <div class="add-new-lead" *ngIf="!laneAccessOn">
              <div fxLayout="row" fxLayoutAlign="start center" (click)="addNewLead()">
                <div fxLayout="row" fxLayoutAlign="center center" class="margin-right  company-buyer-div">
                  <mat-icon aria-hidden="false" aria-label="Company Name" fontIcon="home" matTooltip="Buyers"
                    matTooltipClass="new-tooltip" class="material-symbols-outlined buyer-icon">add</mat-icon>

                </div>
                <div fxLayout="column" fxLayoutAlign="center start">
                  <span class="icon-text-label">Create Lead</span>
                </div>
              </div>
            </div>
            <div fxLayout="row" class="bulk-new-lead" *ngIf="!laneAccessOn">
              <div class="bulk-new-lead" fxLayout="row" fxLayoutAlign="start center"
                [matMenuTriggerFor]="menuFileOption">
                <div fxLayout="row" fxLayoutAlign="center center" class="margin-right  company-buyer-div">
                  <mat-icon aria-hidden="false" aria-label="Company Name" fontIcon="home" matTooltip="Buyers"
                    matTooltipClass="new-tooltip" class="material-symbols-outlined lab-icon">library_add</mat-icon>

                </div>
                <div fxLayout="column" fxLayoutAlign="center start">
                  <span class="icon-text-label">Bulk Lead Creation</span>
                </div>
              </div>
              <mat-menu #menuFileOption="matMenu">
                <button mat-menu-item (click)="uploadPanjivaFile(false)">
                  Upload Panjiva File
                </button>
                <button mat-menu-item (click)="uploadPanjivaFile(true)">
                  Bulk Upload
                </button>
              </mat-menu>
            </div>
            <div class="btn-hover" *ngIf="this.selectedDashItem.name == 'all-leads' &&  this.getTabIndex == 0">
              <div fxLayout="row">
                <div class="sobButton">
                  <button class="btnSetting" mat-button (click)="sobFilter()" [ngClass]="getSOBFilterClass()">New
                    Signups
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="btn-hover" *ngIf="!laneAccessOn">
            <div fxLayout="row">
              <div class="right-2" *hasPermission="['MANAGE_KANBAN']">
                <button class="btnSetting" mat-button (click)="laneAccessOn = !laneAccessOn">
                  <mat-icon aria-hidden="false" aria-label="Company Name" fontIcon="home" matTooltip="Manage Access"
                    matTooltipClass="new-tooltip"
                    class="material-symbols-outlined admin-icon">manage_accounts</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="end center">
            <div class="right-2 ">
              <button class="btnSetting" mat-button (click)="hideLanes()">
                <mat-icon *ngIf="isVisibleLanes">expand_less</mat-icon>
                <mat-icon *ngIf="!isVisibleLanes">keyboard_arrow_down</mat-icon>
              </button>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="end center" style="width: 20%;" *ngIf="laneAccessOn" class="board-back">
            <div fxLayout="row" (click)="chnageSettingTab();">
              <div fxLayout="row" class="right-2" *hasPermission="['MANAGE_KANBAN']">
                <mat-icon aria-hidden="false" aria-label="Company Name" fontIcon="home" matTooltip="Manage Access"
                  matTooltipClass="new-tooltip" class="material-symbols-outlined buyer-icon">arrow_back</mat-icon>

                <div fxLayout="column" fxLayoutAlign="center start">
                  <span class="icon-text-label"> Back To Board</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content-sec">
      <div fxLayout="row" fxLayoutAlign="end center" style="width: 100%; position: absolute;
      top: 2%;">
      </div>
      <mat-tab-group class="k-board-matgrp remove-border-bottom" (selectedTabChange)="getBoardTabChange($event)" [selectedIndex]="selectedTabIndex">
        <mat-tab *ngFor="let tab of boardList; let index = index">
          <ng-template mat-tab-label>
            <span class="los-title">{{tab.name}} LOS</span>

          </ng-template>
          <ng-container *ngIf="tab != null && !laneAccessOn">
            <app-board-lane #child [loading]="isLoading" [laneTile]="selectedDashItem" [board_id]="boardId"
              [filteredObj]="sendFilteredReqObj" [boardLaneResponseObj]="boardLaneResponse"
              [sobFilter]="isSobFilterApply" [boardType]="tab.type" *ngIf="index==getTabIndex">
            </app-board-lane>
          </ng-container>
          <ng-container *ngIf="laneAccessOn">
            <app-k-user-management #child [board_id]="boardId"></app-k-user-management>
          </ng-container>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
