import { Component, OnInit, ViewChild } from '@angular/core'
import { ThemePalette } from '@angular/material/core'
import { MatMenuTrigger } from '@angular/material/menu'
import { MatDialog } from '@angular/material/dialog'
import { FileDailogComponent } from '../file-dailog/file-dailog.component'
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'
import { ToastrService } from 'ngx-toastr'
import { KanbanService } from '../../../kanban.service'
import { ActivatedRoute } from '@angular/router'
import { saveAs } from 'file-saver'
import { error } from 'console'
import { SentForConsentPopupComponent } from '../sent-for-consent-popup/sent-for-consent-popup.component'
import { ShowConsentStatusComponent } from '../card-details/show-consent-status/show-consent-status.component'

export interface Task {
	name: string
	completed: boolean
	color: ThemePalette
	subtasks?: Task[]
}
@Component({
	selector: 'app-details-documents',
	templateUrl: './details-documents.component.html',
	styleUrls: ['./details-documents.component.scss'],
})
export class DetailsDocumentsComponent implements OnInit {
	@ViewChild('menuTrigger') menuTrigger: MatMenuTrigger

	states = this.kService.statesOfIndia
	showSave: boolean = true
	fieldEnable: boolean = true
	sidPidName = ''
	bankList = BANK_LIST
	file: any
	files: File[] = []
	folder: { name: string; filesNew: File[] } | null = null
	auditFolder: { name: string; filesNew: File[] } | null = null
	salesFolder: { name: string; filesNew: File[] } | null = null
	stockFolder: { name: string; filesNew: File[] } | null = null
	debtorFolder: { name: string; filesNew: File[] } | null = null
	creditorFolder: { name: string; filesNew: File[] } | null = null
	bankFolder: { name: string; filesNew: File[] } | null = null
	sacntionFolder: { name: string; filesNew: File[] } | null = null
	commercialFolder: { name: string; filesNew: File[] } | null = null
	gstFolder: { name: string; filesNew: File[] } | null = null
	aomoFolder: { name: string; filesNew: File[] } | null = null
	cibilFolder: { name: string; filesNew: File[] } | null = null
	borrowerFolder: { name: string; filesNew: File[] } | null = null
	promoterFolder: { name: string; filesNew: File[] } | null = null
	borrowerCustomerFolder: { name: string; filesNew: File[] } | null = null
	borrowerSupplierFolder: { name: string; filesNew: File[] } | null = null
	ccStatementFolder: { name: string; filesNew: File[] } | null = null
	debtFolder: { name: string; filesNew: File[] } | null = null
	monthlyPaymentFolder: { name: string; filesNew: File[] } | null = null
	saralFolder: { name: string; filesNew: File[] } | null = null
	computationFolder: { name: string; filesNew: File[] } | null = null
	pAndLFolder: { name: string; filesNew: File[] } | null = null
	isProgressVisible = false
	panelOpenState = true
	detailForm: FormGroup
	dataSource3: Array<any> = []
	laneData: any
	displayColumns3: Array<string> = ['BuyerName', 'BuyerAddress', 'BuyerRating', 'BuyerIndusrty', 'BuyerturnOver', 'LimitRequested', 'TendorDays', 'Action']
	laneTaskId: any
	directorId: any
	tabName: string
	newLeadLaneData: any
	aoaAndIncomeCheck: boolean
	propritorCheck: boolean
	shareName: String = ''
	showCompanyPan: String = ''
	showCin: boolean = false
	business = ''
	showQuestion: boolean = false
	selectedDirector: any
	minDate: Date;
	maxDate: Date = new Date();
	selectedDirectorId:any
	isEligibleToOnboard: boolean = false
	isReadyForCosent: boolean = false
	selectedLaneId: any
	laneIdList: any
	nextLane: boolean = false
	selectedTabIndex: number = 0

	constructor(
		public dialog: MatDialog,
		private fb: FormBuilder,
		public toasterService: ToastrService,
		private activatedRoute: ActivatedRoute,
		public kService: KanbanService,
	) {
		const today = new Date()
		this.minDate = new Date(today.getFullYear() - 80, today.getMonth(), today.getDate())
	}

	task: Task = {
		name: 'Checl All',
		completed: false,
		color: 'primary',
		subtasks: [
			{ name: 'Check 1', completed: false, color: 'primary' },
			{ name: 'Check 2', completed: false, color: 'primary' },
			{ name: 'Check 3', completed: false, color: 'primary' },
		],
	}

	allComplete: boolean = false

	someComplete(): boolean {
		return this.task.subtasks.filter((t) => t.completed).length > 0 && !this.allComplete
	}

	setAll(completed: boolean) {
		this.allComplete = completed
		this.task.subtasks.forEach((t) => (t.completed = completed))
	}

	updateAllComplete() {
		this.allComplete = this.task.subtasks != null && this.task.subtasks.every((t) => t.completed)
	}

	cancel() {
		this.menuTrigger.closeMenu()
	}

	multiFileUpload(event: any) {
		const newFiles = Array.from(event.target.files) as File[]
		this.files = this.files.concat(newFiles)
	}

	removeFolder() {
		this.folder = null
		this.auditFolder = null
		this.salesFolder = null
		this.stockFolder = null
		this.debtorFolder = null
		this.creditorFolder = null
		this.sacntionFolder = null
		this.commercialFolder = null
		this.gstFolder = null
		this.aomoFolder = null
		this.cibilFolder = null
		this.bankFolder = null
		this.borrowerFolder = null
		this.promoterFolder = null
		this.borrowerCustomerFolder = null
		this.borrowerSupplierFolder = null
		this.ccStatementFolder = null
		this.debtFolder = null
		this.monthlyPaymentFolder = null
		this.saralFolder = null
		this.computationFolder = null
		this.pAndLFolder = null
	}

	openDialog(keyWord): void {
		const dialogRef = this.dialog.open(FileDailogComponent, {
			width: '500px',
			data: { dataType: keyWord, laneTaskId: this.laneTaskId, editable: this.fieldEnable },
			panelClass: 'file-dialog-container',
		})

		dialogRef.afterClosed().subscribe((result) => {
			this.fetchdata(this.laneTaskId)
			// if (result) {
			//   this.folder.filesNew = result;
			// }
			//commenting this fetch datacalling method to Fix the duplication of froms in documentation Tab
			// this.fetchdata(this.laneTaskId);
		})
	}
	openDialogueBank(index) {
		const dialogRef = this.dialog.open(FileDailogComponent, {
			width: '500px',
			data: {
				dataType: 'BANK_STATEMENTS',
				laneTaskId: this.laneTaskId,
				id: this.bankDetails.at(index).get('id').value,
				editable: this.fieldEnable,
			},
			panelClass: 'file-dialog-container',
		})

		dialogRef.afterClosed().subscribe((result) => {
			this.fetchdata(this.laneTaskId)
		})
	}

	showConsentDeatils() {
		const dialogRef = this.dialog.open(ShowConsentStatusComponent, {
			disableClose: true,
			width: '800px',
			height: '400px',
			panelClass: 'show-consent-status',
			data: this.laneTaskId,
		})
	}

	ngOnInit() {
		this.initialiseForm()
		this.activatedRoute.params.subscribe((x) => {
			this.laneTaskId = x.id
			this.kService.setLaneTaskId(x.id)
		})
		this.fetchdata(this.laneTaskId)
		this.companyPanReplicate()
		this.typeOfcompanyReplicate()
		this.getTabValue(0)
	}

	initialiseForm() {
		this.detailForm = this.fb.group({
			companyDetails: this.fb.group({
				supplierName: [{ value: null, disabled: true }],
				typeOfCompany: [null],
				natureOfBusiness: [null],
				companyPAN: [null],
				companyPANFile: [null],
				cinNo: [null],
				cinNoFile: [null],
				gstinNo: [null],
				gstinNoFile: [null],
			}),
			businessUsersDetails: this.fb.array([]),
			documentations: this.fb.group({
				kycDocument: this.fb.group({
					typeOfCompany: [],
					gstCertificate: [],
					gstCertificateFile: [],
					udhyamAadharCertificate: [null],
					udhyamAadharCertificateFile: [],
					incorporationCertificateFile: [],
				}),
				companyDocuments: this.fb.group({
					auditedStatment: [[]],
					salesPurchaseOrder: [[]],
					stockStatement: [[]],
					debtorStatement: [[]],
					creditorStatement: [[]],
					// bankStatements: [],
					sanctionLetters: [[]],
					commTransactionDoc: [[]],
					gstReturns: [[]],
					aoaAndMoa: [[]],
					cibilData: [[]],
					borrowerNote: [[]],
					promoterNote: [[]],
					borrowerCustomerLedger: [[]],
					borrowerSupplierLedger: [[]],
					ccStatment: [[]],
					debtPosition: [[]],
					monthlyRepayment: [[]],
					saralCopy: [[]],
					computationIncome: [[]],
					plBalanceSheet: [[]],
				}),
				bankDetails: this.fb.array([]),
				residenceDocuments: this.fb.group({
					waterBill: [null],
					electricityBill: [null],
					ownershipProof: [null],
				}),
				creditQuestionaire: this.fb.group({
					isBaseLocationBeyond100km: [null],
					isRatingBelowBBplus: [null],
					isTradingEntity: [null],
					isRelyingOnGovtEntitiesForSales: [null],
					hasActiveOperationsLessThan36Months: [null],
					hasNetworthLessThan2_50Cr: [null],
					hasOperatingScaleLessThan15Cr: [null],
					hasNegativeEBITDAInLast2FY: [null],
				}),
			}),
		})
	}
	multiFileUploadFolder(event: any, type: any, id?: any) {
		const newFiles = Array.from(event.target.files) as File[]
		if (type == 'AUDITED_STATEMENT') {
			if (!this.auditFolder) {
				this.auditFolder = { name: 'Folder', filesNew: [] }
			}
			this.auditFolder.filesNew = this.auditFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.auditFolder.filesNew, 'bulk upload', type)
		} else if (type == 'SALES_OR_PURCHASE_ORDER') {
			if (!this.salesFolder) {
				this.salesFolder = { name: 'Folder', filesNew: [] }
			}
			this.salesFolder.filesNew = this.salesFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.salesFolder.filesNew, 'bulk upload', type)
		} else if (type == 'STOCK_STATEMENT') {
			if (!this.stockFolder) {
				this.stockFolder = { name: 'Folder', filesNew: [] }
			}
			this.stockFolder.filesNew = this.stockFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.stockFolder.filesNew, 'bulk upload', type)
		} else if (type == 'DEBTOR_STATEMENT') {
			if (!this.debtorFolder) {
				this.debtorFolder = { name: 'Folder', filesNew: [] }
			}
			this.debtorFolder.filesNew = this.debtorFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.debtorFolder.filesNew, 'bulk upload', type)
		} else if (type == 'CREDITOR_STATEMENT') {
			if (!this.creditorFolder) {
				this.creditorFolder = { name: 'Folder', filesNew: [] }
			}
			this.creditorFolder.filesNew = this.creditorFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.creditorFolder.filesNew, 'bulk upload', type)
		} else if (type == 'SANCTION_LETTERS') {
			if (!this.sacntionFolder) {
				this.sacntionFolder = { name: 'Folder', filesNew: [] }
			}
			this.sacntionFolder.filesNew = this.sacntionFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.sacntionFolder.filesNew, 'bulk upload', type)
		} else if (type == 'COMMERCIAL_TRANSACTION') {
			if (!this.commercialFolder) {
				this.commercialFolder = { name: 'Folder', filesNew: [] }
			}
			this.commercialFolder.filesNew = this.commercialFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.commercialFolder.filesNew, 'bulk upload', type)
		} else if (type == 'GST_RETURNS') {
			if (!this.gstFolder) {
				this.gstFolder = { name: 'Folder', filesNew: [] }
			}
			this.gstFolder.filesNew = this.gstFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.gstFolder.filesNew, 'bulk upload', type)
		} else if (type == 'AOA_AND_MOA') {
			if (!this.aomoFolder) {
				this.aomoFolder = { name: 'Folder', filesNew: [] }
			}
			this.aomoFolder.filesNew = this.aomoFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.aomoFolder.filesNew, 'bulk upload', type)
		} else if (type == 'CIBIL_DATA') {
			if (!this.cibilFolder) {
				this.cibilFolder = { name: 'Folder', filesNew: [] }
			}
			this.cibilFolder.filesNew = this.cibilFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.cibilFolder.filesNew, 'bulk upload', type)
		} else if (type == 'BORROWER_ENTITY_NOTE') {
			if (!this.borrowerFolder) {
				this.borrowerFolder = { name: 'Folder', filesNew: [] }
			}
			this.borrowerFolder.filesNew = this.borrowerFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.borrowerFolder.filesNew, 'bulk upload', type)
		} else if (type == 'PROMOTER_BACKGROUND_NOTE') {
			if (!this.promoterFolder) {
				this.promoterFolder = { name: 'Folder', filesNew: [] }
			}
			this.promoterFolder.filesNew = this.promoterFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.promoterFolder.filesNew, 'bulk upload', type)
		} else if (type == 'BORROWER_CUSTOMER_LEDGER') {
			if (!this.borrowerCustomerFolder) {
				this.borrowerCustomerFolder = { name: 'Folder', filesNew: [] }
			}
			this.borrowerCustomerFolder.filesNew = this.borrowerCustomerFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.borrowerCustomerFolder.filesNew, 'bulk upload', type)
		} else if (type == 'BORROWER_SUPPLIER_LEDGER') {
			if (!this.borrowerSupplierFolder) {
				this.borrowerSupplierFolder = { name: 'Folder', filesNew: [] }
			}
			this.borrowerSupplierFolder.filesNew = this.borrowerSupplierFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.borrowerSupplierFolder.filesNew, 'bulk upload', type)
		} else if (type == 'CC_STATEMENT') {
			if (!this.ccStatementFolder) {
				this.ccStatementFolder = { name: 'Folder', filesNew: [] }
			}
			this.ccStatementFolder.filesNew = this.ccStatementFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.ccStatementFolder.filesNew, 'bulk upload', type)
		} else if (type == 'DEBT_POSITION') {
			if (!this.debtFolder) {
				this.debtFolder = { name: 'Folder', filesNew: [] }
			}
			this.debtFolder.filesNew = this.debtFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.debtFolder.filesNew, 'bulk upload', type)
		} else if (type == 'MONTHLY_REPAYMENT') {
			if (!this.monthlyPaymentFolder) {
				this.monthlyPaymentFolder = { name: 'Folder', filesNew: [] }
			}
			this.monthlyPaymentFolder.filesNew = this.monthlyPaymentFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.monthlyPaymentFolder.filesNew, 'bulk upload', type)
		} else if (type == 'COMPUTATION_INCOME') {
			if (!this.computationFolder) {
				this.computationFolder = { name: 'Folder', filesNew: [] }
			}
			this.computationFolder.filesNew = this.computationFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.computationFolder.filesNew, 'bulk upload', type)
		} else if (type == 'SARAL_COPY') {
			if (!this.saralFolder) {
				this.saralFolder = { name: 'Folder', filesNew: [] }
			}
			this.saralFolder.filesNew = this.saralFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.saralFolder.filesNew, 'bulk upload', type)
		} else if (type == 'PL_BALANCE_SHEET') {
			if (!this.pAndLFolder) {
				this.pAndLFolder = { name: 'Folder', filesNew: [] }
			}
			this.pAndLFolder.filesNew = this.pAndLFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.pAndLFolder.filesNew, 'bulk upload', type)
		}
		if (id) {
			const element = document.getElementById(id)
			if (element) {
				element.style.border = '1px solid #c4c4c4'
			}
		}
	}
	multiFileUploadBankFolder(event: any, type: any, index: any, id?: any) {
		const newFiles = Array.from(event.target.files) as File[]
		if (!this.bankFolder) {
			this.bankFolder = { name: 'Folder', filesNew: [] }
		}
		this.bankFolder.filesNew = this.bankFolder.filesNew.concat(newFiles)
		// this.uploadMultipleBankFiles(this.bankFolder.filesNew, 'bulk upload', type,index)
		if (this.bankFolder.filesNew.length > 10) {
			this.toasterService.error('Only 10 Files Can be Uploaded At A Time!')
			this.bankFolder = null
			return
		}
		let formData = new FormData()
		this.bankFolder.filesNew.forEach((x) => {
			formData.append('files', x)
		})
		let refrenceId = this.bankDetails.at(index).get('id').value ? this.bankDetails.at(index).get('id').value : null
		this.kService.uploadBulkFiles(formData, this.laneTaskId, 'bulk upload', type, refrenceId).subscribe({
			next: (resp: any) => {
				this.bankDetails.at(index).get('bankStatements').setValue(resp.result.documentIds)
				this.bankDetails.at(index).get('id').setValue(resp.result.refrenceId)
			},
			complete: () => (this.bankFolder = null),
		})
		if (id) {
			const element = document.getElementById(id)
			if (element) {
				element.style.border = '1px solid #c4c4c4'
			}
		}
	}
	resetFiles() {
		this.folder = null
		this.auditFolder = null
		this.salesFolder = null
		this.stockFolder = null
		this.debtorFolder = null
		this.creditorFolder = null
		this.sacntionFolder = null
		this.commercialFolder = null
		this.gstFolder = null
		this.aomoFolder = null
		this.cibilFolder = null
		this.bankFolder = null
		this.borrowerFolder = null
		this.promoterFolder = null
		this.borrowerCustomerFolder = null
		this.borrowerSupplierFolder = null
		this.ccStatementFolder = null
		this.debtFolder = null
		this.monthlyPaymentFolder = null
		this.saralFolder = null
		this.computationFolder = null
		this.pAndLFolder = null
	}
	uploadMultipleFiles(files, remark, type) {
		if (files.length > 25) {
			this.toasterService.error('Only 25 Files Can be Uploaded At A Time!')
			this.resetFiles()
			return
		}
		let formData = new FormData()
		files.forEach((x) => {
			formData.append('files', x)
		})
		this.kService.uploadBulkFiles(formData, this.laneTaskId, remark, type, null).subscribe({
			next: (resp: any) => {
				if (type == 'AUDITED_STATEMENT') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								auditedStatment: resp.result.documentIds,
							},
						},
					})
				} else if (type == 'SALES_OR_PURCHASE_ORDER') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								salesPurchaseOrder: resp.result.documentIds,
							},
						},
					})
				} else if (type == 'STOCK_STATEMENT') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								stockStatement: resp.result.documentIds,
							},
						},
					})
				} else if (type == 'DEBTOR_STATEMENT') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								debtorStatement: resp.result.documentIds,
							},
						},
					})
				} else if (type == 'CREDITOR_STATEMENT') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								creditorStatement: resp.result.documentIds,
							},
						},
					})
				}
				// else if (type == 'BANK_STATEMENTS') {
				// 	this.detailForm.patchValue({
				// 		documentations: {
				// 			companyDocuments: {
				// 				bankStatements: resp.result,
				// 			},
				// 		},
				// 	})
				// }
				else if (type == 'SANCTION_LETTERS') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								sanctionLetters: resp.result.documentIds,
							},
						},
					})
				} else if (type == 'COMMERCIAL_TRANSACTION') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								commTransactionDoc: resp.result.documentIds,
							},
						},
					})
				} else if (type == 'GST_RETURNS') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								gstReturns: resp.result.documentIds,
							},
						},
					})
				} else if (type == 'AOA_AND_MOA') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								aoaAndMoa: resp.result.documentIds,
							},
						},
					})
				} else if (type == 'CIBIL_DATA') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								cibilData: resp.result.documentIds,
							},
						},
					})
				} else if (type == 'BORROWER_ENTITY_NOTE') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								borrowerNote: resp.result.documentIds,
							},
						},
					})
				} else if (type == 'PROMOTER_BACKGROUND_NOTE') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								promoterNote: resp.result.documentIds,
							},
						},
					})
				} else if (type == 'BORROWER_CUSTOMER_LEDGER') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								borrowerCustomerLedger: resp.result.documentIds,
							},
						},
					})
				} else if (type == 'BORROWER_SUPPLIER_LEDGER') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								borrowerSupplierLedger: resp.result.documentIds,
							},
						},
					})
				} else if (type == 'CC_STATEMENT') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								ccStatment: resp.result.documentIds,
							},
						},
					})
				} else if (type == 'DEBT_POSITION') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								debtPosition: resp.result.documentIds,
							},
						},
					})
				} else if (type == 'MONTHLY_REPAYMENT') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								monthlyRepayment: resp.result.documentIds,
							},
						},
					})
				} else if (type == 'SARAL_COPY') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								saralCopy: resp.result.documentIds,
							},
						},
					})
				} else if (type == 'COMPUTATION_INCOME') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								computationIncome: resp.result.documentIds,
							},
						},
					})
				} else if (type == 'PL_BALANCE_SHEET') {
					this.detailForm.patchValue({
						documentations: {
							companyDocuments: {
								plBalanceSheet: resp.result.documentIds,
							},
						},
					})
				}
				// this.fetchdata(this.laneTaskId)
			},
			error: (err: any) => {
				this.resetFiles()
				this.toasterService.error('Something Went Wrong Or File Too large!')
			},
		})
	}
	showFoldeFileCount(type) {
		let show = false
		let documents = (this.detailForm.get('documentations') as FormGroup).get('companyDocuments')
		if (type == 'AUDITED_STATEMENT') {
			show = documents.get('auditedStatment').value && documents.get('auditedStatment').value.length ? true : false
		} else if (type == 'SALES_OR_PURCHASE_ORDER') {
			show = documents.get('salesPurchaseOrder').value && documents.get('salesPurchaseOrder').value.length ? true : false
		} else if (type == 'STOCK_STATEMENT') {
			show = documents.get('stockStatement').value && documents.get('stockStatement').value.length ? true : false
		} else if (type == 'DEBTOR_STATEMENT') {
			show = documents.get('debtorStatement').value && documents.get('debtorStatement').value.length ? true : false
		} else if (type == 'CREDITOR_STATEMENT') {
			show = documents.get('creditorStatement').value && documents.get('creditorStatement').value.length ? true : false
		}
		//  else if (type == 'BANK_STATEMENTS') {
		// 	show = documents.get('bankStatements').value && documents.get('bankStatements').value.length ? true : false
		// }
		else if (type == 'SANCTION_LETTERS') {
			show = documents.get('sanctionLetters').value && documents.get('sanctionLetters').value.length ? true : false
		} else if (type == 'COMMERCIAL_TRANSACTION') {
			show = documents.get('commTransactionDoc').value && documents.get('commTransactionDoc').value.length ? true : false
		} else if (type == 'GST_RETURNS') {
			show = documents.get('gstReturns').value && documents.get('gstReturns').value.length ? true : false
		} else if (type == 'AOA_AND_MOA') {
			show = documents.get('aoaAndMoa').value && documents.get('aoaAndMoa').value.length ? true : false
		} else if (type == 'CIBIL_DATA') {
			show = documents.get('cibilData').value && documents.get('cibilData').value.length ? true : false
		} else if (type == 'BORROWER_ENTITY_NOTE') {
			show = documents.get('borrowerNote').value && documents.get('borrowerNote').value.length ? true : false
		} else if (type == 'PROMOTER_BACKGROUND_NOTE') {
			show = documents.get('promoterNote').value && documents.get('promoterNote').value.length ? true : false
		} else if (type == 'BORROWER_CUSTOMER_LEDGER') {
			show = documents.get('borrowerCustomerLedger').value && documents.get('borrowerCustomerLedger').value.length ? true : false
		} else if (type == 'BORROWER_SUPPLIER_LEDGER') {
			show = documents.get('borrowerSupplierLedger').value && documents.get('borrowerSupplierLedger').value.length ? true : false
		} else if (type == 'CC_STATEMENT') {
			show = documents.get('ccStatment').value && documents.get('ccStatment').value.length ? true : false
		} else if (type == 'DEBT_POSITION') {
			show = documents.get('debtPosition').value && documents.get('debtPosition').value.length ? true : false
		} else if (type == 'MONTHLY_REPAYMENT') {
			show = documents.get('monthlyRepayment').value && documents.get('monthlyRepayment').value.length ? true : false
		} else if (type == 'SARAL_COPY') {
			show = documents.get('saralCopy').value && documents.get('saralCopy').value.length ? true : false
		} else if (type == 'COMPUTATION_INCOME') {
			show = documents.get('computationIncome').value && documents.get('computationIncome').value.length ? true : false
		} else if (type == 'PL_BALANCE_SHEET') {
			show = documents.get('plBalanceSheet').value && documents.get('plBalanceSheet').value.length ? true : false
		}
		return show
	}

	showFolderFileCountBank(index) {
		let show = false
		show = this.bankDetails.at(index).get('bankStatements').value && this.bankDetails.at(index).get('bankStatements').value.length ? true : false
		return show
	}

	businessDetailKyc(control, type) {
		if (type == 'kyc') return control.controls.kycDocument.value
		else return control.controls.panCard.value
	}
	getFileCount(type) {
		let show = 0
		let documents = (this.detailForm.get('documentations') as FormGroup).get('companyDocuments')
		if (type == 'AUDITED_STATEMENT') {
			show = documents.get('auditedStatment').value.length
		} else if (type == 'SALES_OR_PURCHASE_ORDER') {
			show = documents.get('salesPurchaseOrder').value.length
		} else if (type == 'STOCK_STATEMENT') {
			show = documents.get('stockStatement').value.length
		} else if (type == 'DEBTOR_STATEMENT') {
			show = documents.get('debtorStatement').value.length
		} else if (type == 'CREDITOR_STATEMENT') {
			show = documents.get('creditorStatement').value.length
		} else if (type == 'SANCTION_LETTERS') {
			show = documents.get('sanctionLetters').value.length
		} else if (type == 'COMMERCIAL_TRANSACTION') {
			show = documents.get('commTransactionDoc').value.length
		} else if (type == 'GST_RETURNS') {
			show = documents.get('gstReturns').value.length
		} else if (type == 'AOA_AND_MOA') {
			show = documents.get('aoaAndMoa').value.length
		} else if (type == 'CIBIL_DATA') {
			show = documents.get('cibilData').value.length
		} else if (type == 'BORROWER_ENTITY_NOTE') {
			show = documents.get('borrowerNote').value.length
		} else if (type == 'PROMOTER_BACKGROUND_NOTE') {
			show = documents.get('promoterNote').value.length
		} else if (type == 'BORROWER_CUSTOMER_LEDGER') {
			show = documents.get('borrowerCustomerLedger').value.length
		} else if (type == 'BORROWER_SUPPLIER_LEDGER') {
			show = documents.get('borrowerSupplierLedger').value.length
		} else if (type == 'CC_STATEMENT') {
			show = documents.get('ccStatment').value.length
		} else if (type == 'DEBT_POSITION') {
			show = documents.get('debtPosition').value.length
		} else if (type == 'MONTHLY_REPAYMENT') {
			show = documents.get('monthlyRepayment').value.length
		} else if (type == 'SARAL_COPY') {
			show = documents.get('saralCopy').value.length
		} else if (type == 'COMPUTATION_INCOME') {
			show = documents.get('computationIncome').value.length
		} else if (type == 'PL_BALANCE_SHEET') {
			show = documents.get('plBalanceSheet').value.length
		}
		return show
	}
	getFileCountBank(index) {
		return this.bankDetails.at(index).get('bankStatements').value.length
	}
	getTabValue(event) {
		if (event == 0) {
			this.tabName = 'company details'
			const companyControls = this.detailForm.get('companyDetails') as FormGroup
			Object.keys(companyControls.controls).forEach((controlname) => {
				let control = companyControls.get(controlname)
				if (['supplierName', 'typeOfCompany', 'natureOfBusiness', 'companyPAN', 'companyPANFile', 'gstinNo', 'gstinNoFile'].includes(controlname)) {
					control.setValidators(Validators.required)
				}
				if (controlname == 'cinNo') {
					let checkValue = companyControls.get('typeOfCompany').value
					if (checkValue == 'PRIVATE' || checkValue == 'PUBLIC') {
						control.setValidators([Validators.required, Validators.minLength(21), Validators.maxLength(21), Validators.pattern(/^[A-Za-z0-9]*$/)])
					}
				}
				control.updateValueAndValidity()
			})
			this.detailForm.get('companyDetails').updateValueAndValidity()
			this.clearValidatorsBusniess()
			this.clearValidatorDocuments()
		} else if (event == 1) {
			this.tabName = 'bussiness user'
			if (this.businessUsersDetails.length > 0) {
				this.businessUsersDetails.controls.forEach((element: FormGroup) => {
					Object.keys(element.controls).forEach((controlname) => {
						let control = element.get(controlname)
						const formControlNames = ['name', 'dob', 'gender', 'citizenship', 'contactNo', 'address', 'city', 'state', 'panCard', 'kycDocument']
						if (formControlNames.includes(controlname)) {
							control.setValidators(Validators.required)
						}
						if (controlname === 'sharePercentage' && !this.propritorCheck) {
							control.setValidators(Validators.required)
						}
						if (controlname === 'email') {
							control.setValidators([Validators.required, Validators.email])
						}
						if (controlname === 'pincode') {
							control.setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(6)])
						}
						control.updateValueAndValidity()
					})
				})
				this.businessUsersDetails.updateValueAndValidity()
			}
			this.clearValidatorCompanyDetails()
			this.clearValidatorDocuments()
		} else if (event == 2) {
			this.tabName = 'documentation'
			const controls = this.detailForm.get('documentations') as FormGroup
			Object.keys(controls.controls).forEach((controlName) => {
				let subControl = controls.get(controlName) as FormGroup
				Object.keys(subControl.controls).forEach((subControlName) => {
					let finalControls = subControl.get(subControlName)
					if (subControlName == 'udhyamAadharCertificate') {
						finalControls.setValidators([Validators.pattern(/^[A-Z]{5}-[A-Z]{2}-\d{2}-\d{7}$/)])
					}
					if (
						[
							'auditedStatment',
							'cibilData',
							'borrowerNote',
							'promoterNote',
							'debtPosition',
							'monthlyRepayment',
							'saralCopy',
							'computationIncome',
						].includes(subControlName)
					) {
						finalControls.setValidators([Validators.required])
					}
					if (this.business == 'SID' && subControlName == 'borrowerCustomerLedger') {
						finalControls.setValidators([Validators.required])
					}
					if (this.business == 'PID' && subControlName == 'borrowerSupplierLedger') {
						finalControls.setValidators([Validators.required])
					}
					finalControls.updateValueAndValidity()
				})
			})
			this.bankDetails.controls.forEach((element: FormGroup) => {
				Object.keys(element.controls).forEach((controlname) => {
					let control = element.get(controlname)
					if (controlname == 'bankStatements') {
						control.setValidators([Validators.required])
					}
					control.updateValueAndValidity()
				})
				this.bankDetails.updateValueAndValidity()
			})
			this.detailForm.get('documentations').updateValueAndValidity()
			this.clearValidatorsBusniess()
			this.clearValidatorCompanyDetails()
		}
	}

	clearValidatorsBusniess() {
		if (this.businessUsersDetails.length > 0) {
			this.businessUsersDetails.controls.forEach((element: FormGroup) => {
				Object.keys(element.controls).forEach((controlname) => {
					let control = element.get(controlname)
					control.clearValidators()
					control.updateValueAndValidity()
				})
			})
			this.businessUsersDetails.updateValueAndValidity()
		}
	}

	clearValidatorCompanyDetails() {
		const controls = this.detailForm.get('companyDetails') as FormGroup
		Object.keys(controls.controls).forEach((controlname) => {
			let control = controls.get(controlname)
			control.clearValidators()
			control.updateValueAndValidity()
		})
		this.detailForm.get('companyDetails').updateValueAndValidity()
	}

	clearValidatorDocuments() {
		const controls = this.detailForm.get('documentations') as FormGroup
		Object.keys(controls.controls).forEach((controlName) => {
			let subControl = controls.get(controlName) as FormGroup
			Object.keys(subControl.controls).forEach((subControlName) => {
				let finalControls = subControl.get(subControlName)
				finalControls.clearValidators()
				finalControls.updateValueAndValidity()
			})
		})
		this.bankDetails.controls.forEach((element: FormGroup) => {
			Object.keys(element.controls).forEach((controlname) => {
				let control = element.get(controlname)
				control.clearValidators()
				control.updateValueAndValidity()
			})
		})
		this.detailForm.get('documentations').updateValueAndValidity()
	}

	fetchdata(x) {
		if (x !== null && x !== 'undefined') {
			this.kService.fetchData(x).subscribe({
				next: (resp: any) => {
					this.kService.setLaneData(resp.result)
					this.folder = null
					this.auditFolder = null
					this.salesFolder = null
					this.stockFolder = null
					this.debtorFolder = null
					this.creditorFolder = null
					this.bankFolder = null
					this.sacntionFolder = null
					this.commercialFolder = null
					this.gstFolder = null
					this.aomoFolder = null
					this.cibilFolder = null
					this.bankFolder = null
					this.borrowerFolder = null
					this.promoterFolder = null
					this.borrowerCustomerFolder = null
					this.borrowerSupplierFolder = null
					this.ccStatementFolder = null
					this.debtFolder = null
					this.monthlyPaymentFolder = null
					this.saralFolder = null
					this.computationFolder = null
					this.pAndLFolder = null
					this.selectedLaneId = resp.result.laneId
					this.isEligibleToOnboard = resp.result.isEligibleToOnboard
					if (resp.result.domesticData && resp.result.domesticData['NEW_LEADS']) {
						this.newLeadLaneData = resp.result.domesticData['NEW_LEADS']
						this.laneData = resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED']
						this.detailForm.patchValue({
							companyDetails: {
								supplierName: resp.result.domesticData['NEW_LEADS'].supplierName,
								typeOfCompany: resp.result.domesticData['NEW_LEADS'].typeOfCompany,
								natureOfBusiness: resp.result.domesticData['NEW_LEADS'].natureOfBusiness,
								companyPAN: resp.result.domesticData['NEW_LEADS'].companyPan,
								gstinNo: resp.result.domesticData['NEW_LEADS'].gstinNo,
							},
						})
						this.sidPidName = resp.result.domesticData['NEW_LEADS'].businessProduct == 'SID' ? 'Sales' : 'Purchase'
						let checkValue = resp.result.domesticData['NEW_LEADS'].typeOfCompany
						if (checkValue == 'PROPRIETORSHIP' || checkValue == 'PARTNERSHIP' || checkValue == 'LLP') {
							this.aoaAndIncomeCheck = false
							this.propritorCheck = checkValue == 'PROPRIETORSHIP' ? true : false
						} else {
							this.aoaAndIncomeCheck = true
						}
						if (checkValue == 'PRIVATE' || checkValue == 'PUBLIC' || checkValue == 'LLP') {
							this.shareName = 'Share Holding Pattern'

							if (checkValue != 'LLP') {
								const companyDetailFormGroup = this.detailForm.get('companyDetails') as FormGroup
								companyDetailFormGroup
									.get('cinNo')
									.setValidators([
										Validators.required,
										Validators.minLength(21),
										Validators.maxLength(21),
										Validators.pattern(/^[A-Za-z0-9]*$/),
									])
								// (this.detailForm.get('companyDetails') as FormGroup).get('cinNoFile').setValidators([Validators.required])
								this.showCin = true
								this.detailForm.updateValueAndValidity()
							}
						} else if (checkValue == 'PARTNERSHIP') {
							this.shareName = 'Profit Sharing'
						}
						this.addCompanyPan(resp.result.domesticData['NEW_LEADS'].typeOfCompany)
						this.business = resp.result.domesticData['NEW_LEADS'].businessProduct
						this.showQuestion = this.business == 'PID' ? true : false
						if (this.business == 'SID' || this.business == 'DEALER_FINANCE') {
							this.kService.setBuyerValue('Buyer')
							// if (this.business == 'SID') {
							// 	((this.detailForm.get('documentations') as FormGroup).get('companyDocuments') as FormGroup).get('borrowerCustomerLedger').setValidators([Validators.required])
							// }
						} else {
							// if (this.business == 'PID') {
							// 	((this.detailForm.get('documentations') as FormGroup).get('companyDocuments') as FormGroup).get('borrowerSupplierLedger').setValidators([Validators.required])
							// }
							this.kService.setBuyerValue('Supplier')
						}
					}
					if (
						resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'] &&
						resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].companyDetails
					) {
						this.detailForm.patchValue({
							companyDetails: {
								cinNo: resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].companyDetails.cinNo,
								cinNoFile: resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].companyDetails.cinNoFile,
								companyPANFile: resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].companyDetails.companyPANFile,
								gstinNoFile: resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].companyDetails.gstinNoFile,
							},
						})
						let data = this.detailForm.get('companyDetails') as FormGroup
						data.get('supplierName').setValue(resp.result.domesticData['NEW_LEADS'].supplierName)
						data.get('typeOfCompany').setValue(resp.result.domesticData['NEW_LEADS'].typeOfCompany)
						data.get('natureOfBusiness').setValue(resp.result.domesticData['NEW_LEADS'].natureOfBusiness)
						;((this.detailForm.get('documentations') as FormGroup).get('kycDocument') as FormGroup)
							.get('typeOfCompany')
							.setValue(resp.result.domesticData['NEW_LEADS'].typeOfCompany)
					}
					if (
						resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'] &&
						resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].businessUsersDetails &&
						resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].businessUsersDetails.length > 0
					) {
						this.createBusinessUserDetails(resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].businessUsersDetails)
					} else {
						while (this.businessUsersDetails.length) {
							this.businessUsersDetails.removeAt(0)
						}
						// this.businessUsersDetails.push(this.createBusinessUserDetails())
					}
					if (
						resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'] &&
						resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations &&
						resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.bankDetails &&
						resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.bankDetails.length > 0
					) {
						this.createBankDetails(resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.bankDetails)
					} else {
						while (this.bankDetails.length) {
							this.bankDetails.removeAt(0)
						}
						this.bankDetails.push(this.createBankDetails())
					}
					if (
						resp.result.domesticData &&
						resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'] &&
						resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations
					) {
						this.detailForm.patchValue({
							documentations: {
								kycDocument: {
									gstCertificate:
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.kycDocument &&
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.kycDocument.gstCertificate
											? resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.kycDocument.gstCertificate
											: null,
									gstCertificateFile:
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.kycDocument &&
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.kycDocument.gstCertificateFile
											? resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.kycDocument.gstCertificateFile
											: null,
									udhyamAadharCertificate:
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.kycDocument &&
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.kycDocument.udhyamAadharCertificate
											? resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.kycDocument.udhyamAadharCertificate
											: null,
									udhyamAadharCertificateFile:
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.kycDocument &&
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.kycDocument.udhyamAadharCertificateFile
											? resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.kycDocument
													.udhyamAadharCertificateFile
											: null,
									incorporationCertificateFile:
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.kycDocument &&
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.kycDocument.incorporationCertificateFile
											? resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.kycDocument
													.incorporationCertificateFile
											: null,
								},
								residenceDocuments: {
									waterBill:
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.residenceDocuments &&
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.residenceDocuments.waterBill
											? resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.residenceDocuments.waterBill
											: null,
									electricityBill:
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.residenceDocuments &&
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.residenceDocuments.electricityBill
											? resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.residenceDocuments.electricityBill
											: null,
									ownershipProof:
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.residenceDocuments &&
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.residenceDocuments.ownershipProof
											? resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.residenceDocuments.ownershipProof
											: null,
								},
								creditQuestionaire: {
									isBaseLocationBeyond100km:
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.creditQuestionaire &&
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.creditQuestionaire
											.isBaseLocationBeyond100km
											? resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.creditQuestionaire
													.isBaseLocationBeyond100km
											: 'NA',
									isRatingBelowBBplus:
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.creditQuestionaire &&
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.creditQuestionaire.isRatingBelowBBplus
											? resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.creditQuestionaire
													.isRatingBelowBBplus
											: 'NA',
									isTradingEntity:
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.creditQuestionaire &&
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.creditQuestionaire.isTradingEntity
											? resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.creditQuestionaire.isTradingEntity
											: 'NA',
									isRelyingOnGovtEntitiesForSales:
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.creditQuestionaire &&
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.creditQuestionaire
											.isRelyingOnGovtEntitiesForSales
											? resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.creditQuestionaire
													.isRelyingOnGovtEntitiesForSales
											: 'NA',
									hasActiveOperationsLessThan36Months:
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.creditQuestionaire &&
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.creditQuestionaire
											.hasActiveOperationsLessThan36Months
											? resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.creditQuestionaire
													.hasActiveOperationsLessThan36Months
											: 'NA',
									hasNetworthLessThan2_50Cr:
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.creditQuestionaire &&
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.creditQuestionaire
											.hasNetworthLessThan2_50Cr
											? resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.creditQuestionaire
													.hasNetworthLessThan2_50Cr
											: 'NA',
									hasOperatingScaleLessThan15Cr:
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.creditQuestionaire &&
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.creditQuestionaire
											.hasOperatingScaleLessThan15Cr
											? resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.creditQuestionaire
													.hasOperatingScaleLessThan15Cr
											: 'NA',
									hasNegativeEBITDAInLast2FY:
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.creditQuestionaire &&
										resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.creditQuestionaire
											.hasNegativeEBITDAInLast2FY
											? resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.creditQuestionaire
													.hasNegativeEBITDAInLast2FY
											: 'NA',
								},
								companyDocuments: resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.companyDocuments,
							},
						})
						// this.patchCompanyDocuments(resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].documentations.companyDocuments)
					}
					this.editable(resp)
				},
				error: (err: any) => {
					// this.businessUsersDetails.push(this.createBusinessUserDetails())
					this.bankDetails.push(this.createBankDetails())
				},
				complete:()=>{
					if(this.selectedDirectorId && this.selectedTabIndex == 1){
						let seleDirectorIndex = this.laneData.businessUsersDetails.findIndex(obj=>obj.id==this.selectedDirectorId);
						this.selectedDirector = seleDirectorIndex
					}
				}
			})
		}
	}
	editable(resp) {
		this.kService.hasAccess.subscribe((access) => {
			this.kService.laneListIds.subscribe((x) => {
				this.laneIdList = x
				if (x && Object.keys(x).length > 0) {
					let obj = x.filter((element) => element.laneName == 'application-documents-submitted')
					if (resp.result.laneId == obj[0].laneId && access) {
						this.showSave = true
						this.detailForm.enable()
						this.fieldEnable = true
					} else {
						this.showSave = false
						this.detailForm.disable()
						this.fieldEnable = false
						this.isEligibleToOnboard = false
						this.nextLane = true
					}
				}
				;(this.detailForm.get('companyDetails') as FormGroup).get('supplierName').disable()
				;(this.detailForm.get('companyDetails') as FormGroup).get('typeOfCompany').disable()
				;(this.detailForm.get('companyDetails') as FormGroup).get('natureOfBusiness').disable()
				;(this.detailForm.get('companyDetails') as FormGroup).get('companyPAN').disable()
				;(this.detailForm.get('companyDetails') as FormGroup).get('gstinNo').disable()
				for (let i = 0; i < this.businessUsersDetails.length; i++) {
					this.businessUsersDetails.at(i).get('typeOfCompany').disable()
				}
			})
		})
		this.showConsent()
	}
	fetchByCin() {
		let cinNo = (this.detailForm.get('companyDetails') as FormGroup).get('cinNo').value
		if (cinNo) {
			this.kService.fetchUsingCin(cinNo).subscribe({
				next: (resp: any) => {
					if (resp.success) {
						this.toasterService.success('Details collected successfully. Kindly include more details about the directors.')
						this.cinUserFilteration(resp.result)
					} else {
						this.toasterService.error('Failed to obtain details. Kindly add more information about the directors.')
					}
				},
				error: (err: any) => this.toasterService.error('Failed to obtain details. Kindly add more information about the directors.'),
			})
		} else {
			this.toasterService.error('Please Enter CIN No Before Fetching!')
		}
	}
	cinUserFilteration(details) {
		let data = details.concat(this.businessUsersDetails.value)
		let requiredMap: Map<string, any> = new Map()
		data.forEach((element) => {
			if (!requiredMap.has(element.name)) {
				requiredMap.set(element.name, element)
			}
		})
		let businessUsers = Array.from(requiredMap.values())
		if (businessUsers.length > 0) {
			this.selectedTabIndex = 1
		}
		this.createBusinessUserDetails(businessUsers)
	}
	addCompanyPan(companyType) {
		if (companyType == 'PROPRIETORSHIP') {
			this.showCompanyPan = 'Individual'
		} else {
			this.showCompanyPan = 'Company'
		}
	}

	patchCompanyDocuments(companyDocs: any) {
		if (companyDocs) {
			Object.keys(companyDocs).forEach((key) => {
				this.detailForm.patchValue({
					documentations: {
						companyDocuments: {
							key: companyDocs[key],
						},
					},
				})
				console.log(this.detailForm.getRawValue())
			})
		}
	}

	createBusinessUserDetails(details: any[] = null) {
		if (details && details.length > 0) {
			while (this.businessUsersDetails.length) {
				this.businessUsersDetails.removeAt(0)
			}
			// this.detailForm.patchValue({businessUsersDetails : this.fb.array([])});
			details.forEach((detail: any) => {
				let businessUserDetail
				if (this.propritorCheck) {
					businessUserDetail = this.fb.group({
						typeOfCompany: [{ value: this.newLeadLaneData.typeOfCompany, disabled: true }],
						name: [detail.name, Validators.required],
						dob: [detail.dob, Validators.required],
						gender: [detail.gender, Validators.required],
						citizenship: [detail.citizenship ? detail.citizenship : 'INDIAN', Validators.required],
						contactNo: [detail.contactNo, Validators.required],
						email: [detail.email, [Validators.required, Validators.email]],
						dinNo: [detail.dinNo],
						sharePercentage: [detail.sharePercentage],
						address: [detail.address, Validators.required],
						addressTwo: [detail.addressTwo],
						city: [detail.city, Validators.required],
						state: [detail.state, Validators.required],
						pincode: [detail.pincode, [Validators.minLength(6), Validators.maxLength(6), Validators.required]],
						panCard: [detail.panCard, Validators.required],
						panCardFile: [detail.panCardFile],
						kycDocument: [detail.kycDocument, Validators.required],
						kycDocumentFile: [detail.kycDocumentFile],
						id: [detail.id],
						wheatherDscRegistered: [detail.wheatherDscRegistered ? detail.wheatherDscRegistered : 'NO'],
					})
				} else {
					businessUserDetail = this.fb.group({
						typeOfCompany: [{ value: this.newLeadLaneData.typeOfCompany, disabled: true }],
						name: [detail.name, Validators.required],
						dob: [detail.dob, Validators.required],
						gender: [detail.gender, Validators.required],
						citizenship: [detail.citizenship ? detail.citizenship : 'INDIAN', Validators.required],
						contactNo: [detail.contactNo, Validators.required],
						email: [detail.email, [Validators.required, Validators.email]],
						dinNo: [detail.dinNo],
						sharePercentage: [detail.sharePercentage, Validators.required],
						address: [detail.address, Validators.required],
						addressTwo: [detail.addressTwo],
						city: [detail.city, Validators.required],
						state: [detail.state, Validators.required],
						pincode: [detail.pincode, [Validators.minLength(6), Validators.maxLength(6), Validators.required]],
						panCard: [detail.panCard, Validators.required],
						panCardFile: [detail.panCardFile],
						kycDocument: [detail.kycDocument, Validators.required],
						kycDocumentFile: [detail.kycDocumentFile],
						id: [detail.id],
						wheatherDscRegistered: [detail.wheatherDscRegistered ? detail.wheatherDscRegistered : 'NO'],
					})
				}
				this.businessUsersDetails.push(businessUserDetail)
			})
		}
		if (this.propritorCheck) {
			return this.fb.group({
				typeOfCompany: [{ value: this.newLeadLaneData.typeOfCompany, disabled: true }],
				name: [null, Validators.required],
				dob: [null, Validators.required],
				gender: [null, Validators.required],
				citizenship: ['INDIAN', Validators.required],
				contactNo: [null, Validators.required],
				email: [null, [Validators.required, Validators.email]],
				dinNo: [null],
				sharePercentage: [],
				address: [null, Validators.required],
				addressTwo: [],
				city: [null, Validators.required],
				state: [null, Validators.required],
				pincode: [null, [Validators.minLength(6), Validators.maxLength(6), Validators.required]],
				panCard: [null, Validators.required],
				panCardFile: [],
				kycDocument: [null, Validators.required],
				kycDocumentFile: [],
				id: [null],
				wheatherDscRegistered: ['NO'],
			})
		} else {
			return this.fb.group({
				typeOfCompany: [{ value: this.newLeadLaneData.typeOfCompany, disabled: true }],
				name: [null, Validators.required],
				dob: [null, Validators.required],
				gender: [null, Validators.required],
				citizenship: ['INDIAN', Validators.required],
				contactNo: [null, Validators.required],
				email: [null, [Validators.required, Validators.email]],
				dinNo: [null],
				sharePercentage: [null, Validators.required],
				address: [null, Validators.required],
				addressTwo: [],
				city: [null, Validators.required],
				state: [null, Validators.required],
				pincode: [null, [Validators.minLength(6), Validators.maxLength(6), Validators.required]],
				panCard: [null, Validators.required],
				panCardFile: [],
				kycDocument: [null, Validators.required],
				kycDocumentFile: [],
				id: [null],
				wheatherDscRegistered: ['NO'],
			})
		}
	}

	createBankDetails(details: [] = null) {
		if (details && details.length > 0) {
			while (this.bankDetails.length) {
				this.bankDetails.removeAt(0)
			}
			details.forEach((details: any) => {
				const bankDetails = this.fb.group({
					id: [details.id],
					bankAccountNumber: [details.bankAccountNumber],
					ifscCode: [details.ifscCode],
					accountType: [details.accountType],
					bankAccountName: [details.bankAccountName],
					bankStatements: [details.bankStatements],
				})
				this.bankDetails.push(bankDetails)
			})
		}
		return this.fb.group({
			id: [null],
			bankAccountNumber: [null],
			ifscCode: [null],
			accountType: [null],
			bankAccountName: [null],
			bankStatements: [[]],
		})
	}
	addBank(index) {
		let bankData = this.bankDetails.at(index).value
		if (bankData.bankAccountNumber && bankData.ifscCode && bankData.accountType && bankData.bankAccountName) {
			this.bankDetails.push(this.createBankDetails())
		} else {
			this.toasterService.error('Please Fill Bank Details Before Adding New Bank!')
		}
	}

	removeBank(index) {
		this.bankDetails.removeAt(index)
		if (index == 0) {
			this.bankDetails.push(this.createBankDetails())
		}
	}

	restrictPhoneLength(event: any): void {
		const input = event.target as HTMLInputElement
		const allowedPattern = /^[0-9]$/
		const inputChar = String.fromCharCode(event.keyCode)
		const isValidChar = allowedPattern.test(inputChar)
		if (!isValidChar || input.value.length >= 10) {
			event.preventDefault()
		}
	}
	restrictPinCode(event) {
		const input = event.target as HTMLInputElement
		const allowedPattern = /^[0-9]$/
		const inputChar = String.fromCharCode(event.keyCode)
		const isValidChar = allowedPattern.test(inputChar)
		if (!isValidChar || input.value.length >= 6) {
			event.preventDefault()
		}
	}
	restrictAccountNo(event) {
		const input = event.target as HTMLInputElement
		const allowedPattern = /^[0-9]$/
		const inputChar = String.fromCharCode(event.keyCode)
		const isValidChar = allowedPattern.test(inputChar)
		if (!isValidChar || input.value.length >= 16) {
			event.preventDefault()
		}
	}
	restrictPan(event) {
		const input = event.target as HTMLInputElement
		const allowedPattern = /^[a-zA-Z0-9]$/
		const inputChar = String.fromCharCode(event.keyCode)
		const isValidChar = allowedPattern.test(inputChar)
		if (!isValidChar || input.value.length >= 10) {
			event.preventDefault()
		}
	}
	restrictIfsc(event) {
		const input = event.target as HTMLInputElement
		const allowedPattern = /^[a-zA-Z0-9]$/
		const inputChar = String.fromCharCode(event.keyCode)
		const isValidChar = allowedPattern.test(inputChar)
		if (!isValidChar || input.value.length >= 11) {
			event.preventDefault()
		}
	}
	restrictCin(event) {
		const input = event.target as HTMLInputElement
		const allowedPattern = /^[a-zA-Z0-9]$/
		const inputChar = String.fromCharCode(event.keyCode)
		const isValidChar = allowedPattern.test(inputChar)
		if (!isValidChar || input.value.length >= 21) {
			event.preventDefault()
		}
	}
	restrictGstin(event) {
		const input = event.target as HTMLInputElement
		const allowedPattern = /^[a-zA-Z0-9]$/
		const inputChar = String.fromCharCode(event.keyCode)
		const isValidChar = allowedPattern.test(inputChar)
		if (!isValidChar || input.value.length >= 15) {
			event.preventDefault()
		}
	}
	restrictDin(event) {
		const input = event.target as HTMLInputElement
		const allowedPattern = /^[0-9]$/
		const inputChar = String.fromCharCode(event.keyCode)
		const isValidChar = allowedPattern.test(inputChar)
		if (!isValidChar || input.value.length >= 8) {
			event.preventDefault()
		}
	}
	restrictShare(event) {
		const input = event.target as HTMLInputElement
		const allowedPattern = /^[0-9]$/
		const inputChar = String.fromCharCode(event.keyCode || event.which)
		// Check if the input character is valid (a number)
		const isValidChar = allowedPattern.test(inputChar)
		// Get the current input value as a number
		const currentValue = parseInt(input.value + inputChar)
		// Prevent input if it's not a valid character or exceeds 100
		if (!isValidChar || currentValue > 100 || input.value.length >= 3) {
			event.preventDefault()
		}
	}
	restrictName(event) {
		const input = event.target as HTMLInputElement
		const allowedPattern = /^[a-zA-Z\s]*$/
		const inputChar = String.fromCharCode(event.keyCode)
		if (!allowedPattern.test(inputChar)) {
			event.preventDefault()
		}
		if (input.value.endsWith(' ') && inputChar === ' ') {
			event.preventDefault()
		}
	}
	restrictAadhar(event) {
		const input = event.target as HTMLInputElement
		const allowedPattern = /^[0-9]$/
		const inputChar = String.fromCharCode(event.keyCode)
		const isValidChar = allowedPattern.test(inputChar)
		if (!isValidChar || input.value.length >= 12) {
			event.preventDefault()
		}
	}
	restrictAadharLength(event) {
		if (event.target.value.length > 18) {
			return false
		}
	}
	removeLastSpace(event) {
		const input = event.target as HTMLInputElement
		input.value = input.value.trim()
	}
	get businessUsersDetails() {
		return this.detailForm.get('businessUsersDetails') as FormArray
	}
	get bankDetails() {
		return (this.detailForm.get('documentations') as FormGroup).get('bankDetails') as FormArray
	}

	companyPanReplicate() {
		let companyDetail = this.detailForm.get('companyDetails') as FormGroup
		// let documentations = (this.detailForm.get('documentations') as FormGroup).get('kycDocument');
		companyDetail.get('companyPAN').valueChanges.subscribe((x) => {
			this.detailForm.patchValue({
				documentations: {
					kycDocument: {
						companyPanCard: x,
						directorKYC: x,
					},
				},
			})
		})
	}

	typeOfcompanyReplicate() {
		let companyDetail = this.detailForm.get('companyDetails') as FormGroup
		// let documentations = (this.detailForm.get('documentations') as FormGroup).get('kycDocument');
		companyDetail.get('typeOfCompany').valueChanges.subscribe((x) => {
			this.detailForm.patchValue({
				documentations: {
					kycDocument: {
						typeOfCompany: x,
					},
				},
			})
		})
	}
	fileUpload(event: any, fileType: any, id?: any) {
		this.isProgressVisible = true
		let details = this.detailForm.get('companyDetails')
		let documents = (this.detailForm.get('documentations') as FormGroup).get('kycDocument')
		let residenceDocuments = (this.detailForm.get('documentations') as FormGroup).get('residenceDocuments')
		if (fileType == 'COMPANY_PAN') {
			// if (!details.get('companyPAN').value) {
			// 	this.toasterService.error('Please Enter Company Pan Before Uploading')
			// 	return
			// }
			if (event[0]) {
				this.uploadSingleFile(
					event[0],
					details.get('companyPAN').value,
					fileType,
					details.get('companyPANFile').value ? details.get('companyPANFile').value : null,
				)
			} else {
				this.uploadSingleFile(
					event.target.files[0],
					details.get('companyPAN').value,
					fileType,
					details.get('companyPANFile').value ? details.get('companyPANFile').value : null,
				)
			}
		} else if (fileType == 'CIN_NUMBER') {
			// if (!details.get('cinNo').value) {
			// 	this.toasterService.error('Please Enter CIN Pan Before Uploading')
			// 	return
			// }
			if (event[0]) {
				this.uploadSingleFile(event[0], details.get('cinNo').value, fileType, details.get('cinNoFile').value ? details.get('cinNoFile').value : null)
			} else {
				this.uploadSingleFile(
					event.target.files[0],
					details.get('cinNo').value,
					fileType,
					details.get('cinNoFile').value ? details.get('cinNoFile').value : null,
				)
			}
		} else if (fileType == 'GSTIN_NUMBER') {
			// if (!details.get('gstinNo').value) {
			// 	this.toasterService.error('Please Enter GST Number Before Uploading')
			// 	return
			// }
			if (event[0]) {
				this.uploadSingleFile(
					event[0],
					details.get('gstinNo').value,
					fileType,
					details.get('gstinNoFile').value ? details.get('gstinNoFile').value : null,
				)
			} else {
				this.uploadSingleFile(
					event.target.files[0],
					details.get('gstinNo').value,
					fileType,
					details.get('gstinNoFile').value ? details.get('gstinNoFile').value : null,
				)
			}
		}
		// else if (fileType == 'UTILITY_BILLS') {
		// 	if (event[0]) {
		// 		this.uploadSingleFile(event[0], '', fileType, documents.get('utilityBills').value ? documents.get('utilityBills').value : null)
		// 	} else {
		// 		this.uploadSingleFile(event.target.files[0], '', fileType, documents.get('utilityBills').value ? documents.get('utilityBills').value : null)
		// 	}
		// }
		else if (fileType == 'GST_REGISTRATION') {
			// if (!documents.get('gstCertificate').value) {
			// 	this.toasterService.error('Please Enter GST Number Before Uploading')
			// 	return
			// }
			if (event[0]) {
				this.uploadSingleFile(
					event[0],
					documents.get('gstCertificate').value,
					fileType,
					documents.get('gstCertificateFile').value ? documents.get('gstCertificateFile').value : null,
				)
			} else {
				this.uploadSingleFile(
					event.target.files[0],
					documents.get('gstCertificate').value,
					fileType,
					documents.get('gstCertificateFile').value ? documents.get('gstCertificateFile').value : null,
				)
			}
		} else if (fileType == 'UDHYAM_AADHAR') {
			// if (!documents.get('udhyamAadharCertificate').value) {
			// 	this.toasterService.error('Please Enter Aadhar Certificate Number Before Uploading')
			// 	return
			// }
			if (event[0]) {
				this.uploadSingleFile(
					event[0],
					documents.get('udhyamAadharCertificate').value,
					fileType,
					documents.get('udhyamAadharCertificateFile').value ? documents.get('udhyamAadharCertificateFile').value : null,
				)
			} else {
				this.uploadSingleFile(
					event.target.files[0],
					documents.get('udhyamAadharCertificate').value,
					fileType,
					documents.get('udhyamAadharCertificateFile').value ? documents.get('udhyamAadharCertificateFile').value : null,
				)
			}
		} else if (fileType == 'INCORPORATION_CERTIFICATE') {
			if (event[0]) {
				this.uploadSingleFile(
					event[0],
					'',
					fileType,
					documents.get('incorporationCertificateFile').value ? documents.get('incorporationCertificateFile').value : null,
				)
			} else {
				this.uploadSingleFile(
					event.target.files[0],
					'',
					fileType,
					documents.get('incorporationCertificateFile').value ? documents.get('incorporationCertificateFile').value : null,
				)
			}
		} else if (fileType == 'WATER_BILL') {
			if (event[0]) {
				this.uploadSingleFile(event[0], '', fileType, residenceDocuments.get('waterBill').value ? residenceDocuments.get('waterBill').value : null)
			} else {
				this.uploadSingleFile(
					event.target.files[0],
					'',
					fileType,
					residenceDocuments.get('waterBill').value ? residenceDocuments.get('waterBill').value : null,
				)
			}
		} else if (fileType == 'ELECTRICITY_BILL') {
			if (event[0]) {
				this.uploadSingleFile(
					event[0],
					'',
					fileType,
					residenceDocuments.get('electricityBill').value ? residenceDocuments.get('electricityBill').value : null,
				)
			} else {
				this.uploadSingleFile(
					event.target.files[0],
					'',
					fileType,
					residenceDocuments.get('electricityBill').value ? residenceDocuments.get('electricityBill').value : null,
				)
			}
		} else if (fileType == 'OWNERSHIP_PROOF') {
			if (event[0]) {
				this.uploadSingleFile(
					event[0],
					'',
					fileType,
					residenceDocuments.get('ownershipProof').value ? residenceDocuments.get('ownershipProof').value : null,
				)
			} else {
				this.uploadSingleFile(
					event.target.files[0],
					'',
					fileType,
					residenceDocuments.get('ownershipProof').value ? residenceDocuments.get('ownershipProof').value : null,
				)
			}
		}

		if (id) {
			const element = document.getElementById(id)
			if (element) {
				element.style.border = '1px solid #c4c4c4'
			}
		}
	}
	addDirector(index) {
		if (index > 8) {
			this.toasterService.error('Maximum Directors Added!')
			return
		}
		if (index == -1) {
			this.businessUsersDetails.push(this.createBusinessUserDetails(null))
			return
		}
		let itemArr = (this.businessUsersDetails.at(index) as FormGroup).value
		if (
			itemArr.name &&
			itemArr.dob &&
			itemArr.gender &&
			itemArr.citizenship &&
			itemArr.contactNo &&
			itemArr.email &&
			itemArr.address &&
			itemArr.city &&
			itemArr.state &&
			itemArr.pincode &&
			itemArr.panCard &&
			itemArr.panCardFile &&
			itemArr.kycDocument &&
			itemArr.kycDocumentFile
		) {
			this.businessUsersDetails.push(this.createBusinessUserDetails(null))
			this.selectedDirector = this.businessUsersDetails.length - 1
		} else {
			this.toasterService.error('Please Add Details Before Adding New Director!')
		}
	}
	deleteDirector(index) {
		this.businessUsersDetails.removeAt(index)
	}
	uploadSingleFile(file, value, type?, oldDocId?) {
		this.kService.uploadSingleFile(this.laneTaskId, type, value, file, oldDocId).subscribe({
			next: (resp: any) => {
				if (resp.success == false) {
					this.toasterService.error(resp.message)
					return
				}
				if (type == 'COMPANY_PAN') {
					this.detailForm.patchValue({
						companyDetails: {
							companyPANFile: resp.result.id,
						},
					})
					this.detailForm.patchValue({
						documentations: {
							kycDocument: {
								companyPanCardFile: resp.result.id,
								directorKYCfile: resp.result.id,
							},
						},
					})
				} else if (type == 'CIN_NUMBER') {
					this.detailForm.patchValue({
						companyDetails: {
							cinNoFile: resp.result.id,
						},
					})
				} else if (type == 'GSTIN_NUMBER') {
					this.detailForm.patchValue({
						companyDetails: {
							gstinNoFile: resp.result.id,
						},
					})
				}
				//  else if (type == 'UTILITY_BILLS') {
				// 	this.detailForm.patchValue({
				// 		documentations: {
				// 			kycDocument: {
				// 				utilityBills: resp.result.id,
				// 			},
				// 		},
				// 	})
				// }
				else if (type == 'GST_REGISTRATION') {
					this.detailForm.patchValue({
						documentations: {
							kycDocument: {
								gstCertificateFile: resp.result.id,
							},
						},
					})
				} else if (type == 'UDHYAM_AADHAR') {
					this.detailForm.patchValue({
						documentations: {
							kycDocument: {
								udhyamAadharCertificateFile: resp.result.id,
							},
						},
					})
				} else if (type == 'INCORPORATION_CERTIFICATE') {
					this.detailForm.patchValue({
						documentations: {
							kycDocument: {
								incorporationCertificateFile: resp.result.id,
							},
						},
					})
				} else if (type == 'WATER_BILL') {
					this.detailForm.patchValue({
						documentations: {
							residenceDocuments: {
								waterBill: resp.result.id,
							},
						},
					})
				} else if (type == 'ELECTRICITY_BILL') {
					this.detailForm.patchValue({
						documentations: {
							residenceDocuments: {
								electricityBill: resp.result.id,
							},
						},
					})
				} else if (type == 'OWNERSHIP_PROOF') {
					this.detailForm.patchValue({
						documentations: {
							residenceDocuments: {
								ownershipProof: resp.result.id,
							},
						},
					})
				}
			},
		})
	}
	showCompanyDetailsDoc(type) {
		let details = this.detailForm.get('companyDetails')
		let documents = (this.detailForm.get('documentations') as FormGroup).get('kycDocument')
		let residenceDocuments = (this.detailForm.get('documentations') as FormGroup).get('residenceDocuments')
		if (type == 'COMPANY_PAN') {
			return details.get('companyPANFile').value ? true : false
		} else if (type == 'CIN_NUMBER') {
			return details.get('cinNoFile').value ? true : false
		} else if (type == 'GSTIN_NUMBER') {
			return details.get('gstinNoFile').value ? true : false
		}
		//  else if (type == 'UTILITY_BILLS') {
		// 	return documents.get('utilityBills').value ? true : false
		// }
		else if (type == 'GST_REGISTRATION') {
			return documents.get('gstCertificateFile').value ? true : false
		} else if (type == 'UDHYAM_AADHAR') {
			return documents.get('udhyamAadharCertificateFile').value ? true : false
		} else if (type == 'INCORPORATION_CERTIFICATE') {
			return documents.get('incorporationCertificateFile').value ? true : false
		} else if (type == 'WATER_BILL') {
			return residenceDocuments.get('waterBill').value ? true : false
		} else if (type == 'ELECTRICITY_BILL') {
			return residenceDocuments.get('electricityBill').value ? true : false
		} else if (type == 'OWNERSHIP_PROOF') {
			return residenceDocuments.get('ownershipProof').value ? true : false
		}
	}
	showUpload(type, index) {
		if (type == 'pan') {
			return this.businessUsersDetails.at(index).get('panCardFile').value ? true : false
		} else {
			return this.businessUsersDetails.at(index).get('kycDocumentFile').value ? true : false
		}
	}
	fileUploadInBusinessDetails(event: any, fileType: any, index: number) {
		this.isProgressVisible = true
		let businessDetails = this.businessUsersDetails.at(index)
		if (fileType == 'PAN_CARD') {
			// if (!businessDetails.get('panCard').value) {
			// 	this.toasterService.error('Please Enter Pan Number Before Uploading')
			// 	return
			// }
			if (event[0]) {
				this.uploadBusinessSingleFile(
					event[0],
					businessDetails.get('panCard').value,
					fileType,
					this.businessUsersDetails.at(index).get('id').value ? this.businessUsersDetails.at(index).get('id').value : null,
					index,
					businessDetails.get('panCardFile').value ? businessDetails.get('panCardFile').value : null,
				)
			} else {
				this.uploadBusinessSingleFile(
					event.target.files[0],
					businessDetails.get('panCard').value,
					fileType,
					this.businessUsersDetails.at(index).get('id').value ? this.businessUsersDetails.at(index).get('id').value : null,
					index,
					businessDetails.get('panCardFile').value ? businessDetails.get('panCardFile').value : null,
				)
			}
		} else if (fileType == 'KYC_DOCUMENT') {
			// if (!businessDetails.get('kycDocument').value) {
			// 	this.toasterService.error('Please Enter Aadhar Card Number Before Uploading')
			// 	return
			// }
			if (event[0]) {
				this.uploadBusinessSingleFile(
					event[0],
					businessDetails.get('kycDocument').value,
					fileType,
					this.businessUsersDetails.at(index).get('id').value ? this.businessUsersDetails.at(index).get('id').value : null,
					index,
					businessDetails.get('kycDocumentFile').value ? businessDetails.get('kycDocumentFile').value : null,
				)
			} else {
				this.uploadBusinessSingleFile(
					event.target.files[0],
					businessDetails.get('kycDocument').value,
					fileType,
					this.businessUsersDetails.at(index).get('id').value ? this.businessUsersDetails.at(index).get('id').value : null,
					index,
					businessDetails.get('kycDocumentFile').value ? businessDetails.get('kycDocumentFile').value : null,
				)
			}
		}
	}
	uploadBusinessSingleFile(file, value, type, directorId, index, oldDocId) {
		this.kService.uploadSingleBusinessFile(this.laneTaskId, type, value, file, directorId, oldDocId).subscribe({
			next: (resp: any) => {
				if (resp.success) {
					// this.directorId = resp.result && resp.result.directorId ? resp.result.directorId : null
					if (type == 'PAN_CARD') {
						if(resp.result.ocrData){
						this.businessUsersDetails.at(index).patchValue({
							dob: resp.result.ocrData.dob,
							id: resp.result.directorId,
							panCardFile: resp.result.id,
							panCard: resp.result.ocrData.documentNo,
						})}else{
							this.businessUsersDetails.at(index).patchValue({
								id: resp.result.directorId,
								panCardFile: resp.result.id,
							})	
						}
					} else if (type == 'KYC_DOCUMENT') {
						if(resp.result.ocrData){
						this.businessUsersDetails.at(index).patchValue({
							kycDocumentFile: resp.result.id,
							id: resp.result.directorId,
							address: resp.result.ocrData.address,
							gender: resp.result.ocrData.gender,
							city: resp.result.ocrData.city,
							name: resp.result.ocrData.name,
							pincode: resp.result.ocrData.pincode,
							state: resp.result.ocrData.state,
							kycDocument: resp.result.ocrData.documentNo,
						})}else{
							this.businessUsersDetails.at(index).patchValue({
								kycDocumentFile: resp.result.id,
								id: resp.result.directorId
							})	
						}
					}
				} else {
					this.toasterService.error(resp.message)
				}
			},
		})
	}
	removeEmptyObjects(array: any[]): any[] {
		return array.filter((obj) => {
			// Check if every property in the object is null
			return !Object.values(obj).every((value) => value === null)
		})
	}
	validateBusinessUsers(payload: any): boolean {
		const seenEmails = new Set<string>()
		const seenContacts = new Set<string>()

		for (const user of payload) {
			if (!user.email || !user.contactNo) {
				this.toasterService.error('Email or Contact Number is missing for Directors!')
				return false
			}

			const email = user.email.trim()
			const contactNo = user.contactNo
			if (seenEmails.has(email)) {
				this.toasterService.error(`Directors Email Cannot Be Same!!!`)
				return false
			}
			if (seenContacts.has(contactNo)) {
				this.toasterService.error(`Directors Contact No Cannot Be Same!!!`)
				return false
			}
			seenEmails.add(email)
			seenContacts.add(contactNo)
		}

		return true
	}
	submitForm() {
		// Remove empty objects from the businessUsersDetails array
		const cleanedBusinessUsersDetails = this.removeEmptyObjects(this.detailForm.getRawValue().businessUsersDetails)

		// Create a new payload with the cleaned array
		const payload = {
			...this.detailForm.getRawValue(),
			businessbusinessUsersDetails: cleanedBusinessUsersDetails,
		}
		if (payload.documentations.kycDocument.udhyamAadharCertificate) {
			if (!((this.detailForm.get('documentations') as FormGroup).get('kycDocument') as FormGroup).get('udhyamAadharCertificate').valid) {
				this.toasterService.error('Udhyam Aadhar Should Be In XXXXX-XX-00-0000000 Format')
				return
			}
		}
		if (payload.businessUsersDetails.length > 1) {
			if (!this.validateBusinessUsers(payload.businessUsersDetails)) {
				return
			}
		}
		if (this.detailForm.valid) {
			if(this.selectedTabIndex == 1){
				this.selectedDirectorId = this.businessUsersDetails.at(this.selectedDirector ? this.selectedDirector : 0).get('id').value;
			}
			this.kService.saveDetailsAndDocuments(payload, this.laneTaskId).subscribe({
				next: (resp: any) => {
					if (resp.success) {
						this.toasterService.success('Details Saved Successfully!')
					} else {
						this.toasterService.error(resp.message)
					}
					this.fetchdata(this.laneTaskId)
				},
			})
		} else {
			this.focusFirstInvalidField(this.detailForm.controls.companyDetails)
			this.focusFirstInvalidFile((this.detailForm.controls['documentations'] as FormGroup).controls['companyDocuments'])
			this.focusFirstInvalidField((this.detailForm.controls['documentations'] as FormGroup).controls['bankDetails'])
			this.toasterService.error('Fill Mandatory field First And Upload Files Too')
			console.log(this.detailForm)
		}
	}

	private focusFirstInvalidField(form): void {
		for (const field in form.controls) {
			if (form.controls[field].invalid) {
				const invalidControl = form.controls[field]
				const invalidFieldElement = document.getElementById(field)
				console.log('field name', field)
				if (invalidFieldElement) {
					invalidControl.markAsTouched()
					invalidFieldElement.focus()
					invalidFieldElement.style.border = '1px solid red'
				}
			}
		}
	}

	private focusFirstInvalidFile(form: any): void {
		for (const field in form.controls) {
			if (form.controls[field].invalid) {
				const invalidControl = form.controls[field]
				const invalidFieldElement = document.getElementById(field)
				const element = document.getElementById(field)
				if (element) {
					element.style.border = '1px solid red'
				}
				console.log('field name', field)
				if (invalidFieldElement) {
					invalidControl.markAsTouched()
					invalidFieldElement.focus()
				}
			}
		}
	}

	viewDocument(type) {
		let id = ''
		let details = this.detailForm.get('companyDetails')
		let documents = (this.detailForm.get('documentations') as FormGroup).get('kycDocument')
		let residenceDocuments = (this.detailForm.get('documentations') as FormGroup).get('residenceDocuments')
		if (type == 'COMPANY_PAN') {
			id = details.get('companyPANFile').value
		} else if (type == 'CIN_NUMBER') {
			id = details.get('cinNoFile').value
		} else if (type == 'GSTIN_NUMBER') {
			id = details.get('gstinNoFile').value
		}
		//  else if (type == 'UTILITY_BILLS') {
		// 	id = documents.get('utilityBills').value
		// }
		else if (type == 'GST_REGISTRATION') {
			id = documents.get('gstCertificateFile').value
		} else if (type == 'UDHYAM_AADHAR') {
			id = documents.get('udhyamAadharCertificateFile').value
		} else if (type == 'INCORPORATION_CERTIFICATE') {
			id = documents.get('incorporationCertificateFile').value
		} else if (type == 'WATER_BILL') {
			id = residenceDocuments.get('waterBill').value
		} else if (type == 'ELECTRICITY_BILL') {
			id = residenceDocuments.get('electricityBill').value
		} else if (type == 'OWNERSHIP_PROOF') {
			id = residenceDocuments.get('ownershipProof').value
		}
		this.downloadFile(id)
	}
	viewBusinessDocument(type, index) {
		let id = ''
		if (type == 'pan') {
			id = this.businessUsersDetails.at(index).get('panCardFile').value
		} else {
			id = this.businessUsersDetails.at(index).get('kycDocumentFile').value
		}
		this.downloadFile(id)
	}

	downloadFile(id) {
		this.kService.downloadSingleUploadDocument(id).subscribe({
			next: (resp: any) => {
				this.downloadSheetCommonMethod(resp)
			},
		})
	}
	downloadAllFiles() {
		this.kService.downloadAllFiles(this.laneTaskId).subscribe({
			next: (resp: any) => {
				this.downloadSheetCommonMethod(resp)
			},
		})
	}
	sentForConsent() {
		let primary
		this.newLeadLaneData.contact.forEach((element) => {
			if (element.isPrimary) {
				element.wheatherDscRegistered = 'Yes'
				primary = element
			}
		})
		const dialogRef = this.dialog
			.open(SentForConsentPopupComponent, {
				disableClose: true,
				width: '600px',
				height: '400px',
				panelClass: 'sent-for-consent',
				data: {
					directorIds: this.laneData.businessUsersDetails,
					laneTaskId: this.laneTaskId,
					primaryConact: primary,
				},
			})
			.afterClosed()
			.subscribe((result) => {
				this.fetchdata(this.laneTaskId)
			})
	}
	resultDownload
	downloadSheetCommonMethod(res) {
		var contentDispositionHeader = res.headers.get('content-disposition')
		this.resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1]
		let fileName = this.resultDownload.replace(/"/g, '')
		saveAs(res.body, fileName)
		return fileName
	}

	titleCase(str: string): string {
		if (!str) return str
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ')
	}
	showConsent() {
		if (this.businessUsersDetails.length > 0 && !this.isEligibleToOnboard && !this.nextLane) {
			this.isReadyForCosent = true
			return true
		} else {
			this.isReadyForCosent = false
			return false
		}
	}
	moveToApprove() {
		let toIdObj = this.laneIdList.filter((x) => x.displayName == 'Approved Onboarding')
		let toId = toIdObj[0].laneId
		this.kService.laneChangeCall(this.laneTaskId, this.selectedLaneId, toId).subscribe({
			next: (resp: any) => {
				this.toasterService.success('Lane Approved Succesfully!')
				this.fetchdata(this.laneTaskId)
			},
			error: (err: any) => {
				this.toasterService.error(err && err.error && err.error.message ? err.error.message : 'Something went wrong!')
				// this.reloadPage();
			},
			// complete: () => this.fetchdata(this.laneTaskId),
		})
	}
}

const BANK_LIST = [
	'Andhra Pragathi Grameena Bank',
	'Andhra Pradesh Grameena Vikas Bank',
	'Airtel Payments Bank Ltd',
	'Aryavart Bank',
	'Assam Gramin Vikash Bank',
	'Au Small Finance Bank Ltd.',
	'Axis Bank Ltd.',
	'Bandhan Bank Ltd.',
	'Bangiya Gramin Vikash Bank',
	'Bank of Baroda',
	'Bank of India',
	'Bank of Maharashtra',
	'Baroda Gujarat Gramin Bank',
	'Baroda Rajasthan Kshetriya Gramin Bank',
	'Baroda UP Bank',
	'Canara Bank',
	'Capital Small Finance Bank Ltd',
	'Central Bank of India',
	'Chaitanya Godavari GB',
	'Chhattisgarh Rajya Gramin Bank',
	'City Union Bank Ltd.',
	'Coastal Local Area Bank Ltd',
	'CSB Bank Limited',
	'Dakshin Bihar Gramin Bank',
	'DCB Bank Ltd.',
	'Dhanlaxmi Bank Ltd.',
	'Ellaquai Dehati Bank',
	'Equitas Small Finance Bank Ltd',
	'ESAF Small Finance Bank Ltd.',
	'Export-Import Bank of India',
	'Federal Bank Ltd.',
	'FINO Payments Bank Ltd',
	'HDFC Bank Ltd.',
	'Himachal Pradesh Gramin Bank',
	'ICICI Bank Ltd.',
	'IDBI Bank Limited',
	'IDFC FIRST Bank Limited',
	'India Post Payments Bank Ltd',
	'Indian Bank',
	'Indian Overseas Bank',
	'IndusInd Bank Ltd.',
	'J&K Grameen Bank',
	'Jammu & Kashmir Bank Ltd.',
	'Jana Small Finance Bank Ltd',
	'Jharkhand Rajya Gramin Bank',
	'Jio Payments Bank Ltd',
	'Karnataka Bank Ltd.',
	'Karnataka Gramin Bank',
	'Karnataka Vikas Gramin Bank',
	'Karur Vysya Bank Ltd.',
	'Kerala Gramin Bank',
	'Kotak Mahindra Bank Ltd.',
	'Krishna Bhima Samruddhi LAB Ltd',
	'Madhya Pradesh Gramin Bank',
	'Madhyanchal Gramin Bank',
	'Maharashtra Gramin Bank',
	'Manipur Rural Bank',
	'Meghalaya Rural Bank',
	'Mizoram Rural Bank',
	'Nainital Bank Ltd.',
	'Nagaland Rural Bank',
	'National Bank for Agriculture and Rural Development',
	'National Housing Bank',
	'North East Small Finance Bank Ltd',
	'NSDL Payments Bank Limited',
	'Odisha Gramya Bank',
	'Paschim Banga Gramin Bank',
	'Paytm Payments Bank Ltd',
	'Prathama U.P. Gramin Bank',
	'Punjab & Sind Bank',
	'Punjab Gramin Bank',
	'Punjab National Bank',
	'Puduvai Bharathiar Grama Bank',
	'Rajasthan Marudhara Gramin Bank',
	'RBL Bank Ltd.',
	'Saptagiri Grameena Bank',
	'Sarva Haryana Gramin Bank',
	'Saurashtra Gramin Bank',
	'Shivalik Small Finance Bank Ltd',
	'Small Industries Development Bank of India',
	'South Indian Bank Ltd.',
	'State Bank of India',
	'Suryoday Small Finance Bank Ltd.',
	'Tamil Nadu Grama Bank',
	'Tamilnad Mercantile Bank Ltd.',
	'Telangana Grameena Bank',
	'Tripura Gramin Bank',
	'UCO Bank',
	'Ujjivan Small Finance Bank Ltd.',
	'Union Bank of India',
	'Unity Small Finance Bank Ltd',
	'Utkal Grameen Bank',
	'Uttarakhand Gramin Bank',
	'Uttar Bihar Gramin Bank',
	'Uttarbanga Kshetriya Gramin Bank',
	'Vidharbha Konkan Gramin Bank',
	'YES Bank Ltd.',
	'Others',
]
