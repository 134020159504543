import { C } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { KanbanService } from 'src/app/component/credlix-board/kanban.service';

@Component({
  selector: 'app-score-card',
  templateUrl: './score-card.component.html',
  styleUrls: ['./score-card.component.scss']
})
export class ScoreCardComponent implements OnInit {

  buyerDetailsForm: FormGroup;
  buyerList: any = [];
  supplierForm: FormGroup;
  supplierCount: number = 0;
  supplierDetails: any;
  panelOpenState = false;

  buyerTradeRelationArray = ['vintageTrading', 'pastDilution', 'dependency', 'salesContract', 'incoterm', 'overDues'];
  selectedObj: any = {};
  buyerArray: any = [];
  isEditable : boolean = false;

  constructor(public dialogRef: MatDialogRef<ScoreCardComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public formBuilder: FormBuilder, public kanBanService: KanbanService, public tostrService: ToastrService) {
    const items = [];
    this.buyerDetailsForm = this.formBuilder.group({
      details: this.formBuilder.array(items)
    })
  }

  ngOnInit() {
    this.supplierForm = this.formBuilder.group({
      turnOver: ['', Validators.required],
      debt: ['', Validators.required],
      pat: ['', Validators.required],
      marketCap: ['', Validators.required],
      netWorth: ['', Validators.required],
      industry: ['', Validators.required],
      restructring: ['', Validators.required],
      vintage: ['', Validators.required],
      entityType: ['', Validators.required],
      relevance: ['', Validators.required],
      sophastication: ['', Validators.required],
    })
    this.buyerList = this.data.buyerList;
    this.getSupplierDetailsById(this.data.laneId);
    this.buyerDetailsForm = this.formBuilder.group({
      details: this.formBuilder.array([this.createBuyerForm()])
    })
    this.setBuyerForm(this.buyerList);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  scoreCardAccessCheck(data, supplierDetailsData){       // disable all score card form
    // if(data && supplierDetailsData && supplierDetailsData.isCreditTeam == false && data.laneDetails.keys == 'underwriting' || supplierDetailsData.isEditDisable == false){
    if(supplierDetailsData && supplierDetailsData.isCreditTeam == false && supplierDetailsData.isEditDisable){
      this.supplierForm.disable();
      this.isEditable = true;
    }
  }

  createBuyerForm(): FormGroup {
    return this.formBuilder.group({
      buyerId: ['', Validators.required],
      buyerName: ['', Validators.required],
      buyerGeography: ['', Validators.required],
      employeeStrength: ['', Validators.required],
      vintage: ['', Validators.required],
      financials: ['', Validators.required],
      turnOver: [''],
      debt: [''],
      pat: [''],
      marketCap: [''],
      margin: ['', Validators.required],
      sector: ['', Validators.required],
      bankLimits: ['', Validators.required],
      managementBackground: ['', Validators.required],
      insurerRating: ['', Validators.required],
      coface: [''],
      allianz: [''],
      vintageTrading: ['', Validators.required],
      pastDilution: ['', Validators.required],
      dependency: ['', Validators.required],
      salesContract: ['', Validators.required],
      incoterm: ['', Validators.required],
      overDues: ['', Validators.required],
      count: ['', Validators.required],
      tradeRelationScore: ['', Validators.required],
      finalCount: ['', Validators.required]
    })
  }
  setBuyerForm(arr) {
    const formArray = new FormArray([]);
    for (let y of arr) {
      let data = y.buyer;
      formArray.push(this.formBuilder.group({
        buyerId: [y ? y.id : null, Validators.required],
        buyerName: [y ? y.buyerName : null, Validators.required],
        buyerGeography: [y && y.buyer ? data.geo : null, Validators.required],
        employeeStrength: [y && y.buyer ? data.employees : null, Validators.required],
        vintage: [y && y.buyer ? data.vintage : null, Validators.required],
        financials: [y && y.buyer ? data.financials : null, Validators.required],
        turnOver: [y && y.buyer && y.buyer.financialDetails ? data.financialDetails.turnoverInMillion : null],
        debt: [y && y.buyer && y.buyer.financialDetails ? data.financialDetails.debt : null],
        pat: [y && y.buyer && y.buyer.financialDetails ? data.financialDetails.pat : null],
        marketCap: [y && y.buyer && y.buyer.financialDetails ? data.financialDetails.marketCap : null],
        margin: [y && y.buyer && y.buyer.industry ? data.industry.margin : null, Validators.required],
        sector: [y && y.buyer && y.buyer.industry ? data.industry.sector : null, Validators.required],
        bankLimits: [y && y.buyer && y.buyer ? data.bankLimitsInUSD : null, Validators.required],
        managementBackground: [y && y.buyer && y.buyer ? data.management : null, Validators.required],
        insurerRating: [y && y.buyer && y.buyer ? data.insurerRating : null, Validators.required],
        coface: [y && y.buyer && y.buyer ? data.cofaceDetails : null],
        allianz: [y && y.buyer && y.buyer ? data.allianzDetails : null],
        vintageTrading: [y && y.buyer && y.buyer.tradeRelationship ? data.tradeRelationship.vintage : null, Validators.required],
        pastDilution: [y && y.buyer && y.buyer.tradeRelationship ? data.tradeRelationship.pastDilution : null, Validators.required],
        dependency: [y && y.buyer && y.buyer.tradeRelationship ? data.tradeRelationship.dependency : null, Validators.required],
        salesContract: [y && y.buyer && y.buyer.tradeRelationship ? data.tradeRelationship.salesContractGreaterThanYear : null, Validators.required],
        incoterm: [y && y.buyer && y.buyer.tradeRelationship ? data.tradeRelationship.incoterm : null, Validators.required],
        overDues: [y && y.buyer && y.buyer.tradeRelationship ? data.tradeRelationship.overdues : null, Validators.required],
        count: y && y.buyerScore ? y.buyerScore : null,
        tradeRelationScore: y && y.tradeRelationScore ? y.tradeRelationScore : null,
        finalCount: Number(y.buyerScore || 0) + Number(y.tradeRelationScore || 0) + Number(this.supplierCount || 0)
      }))
    }
    this.buyerDetailsForm.setControl('details', formArray);
    this.buyerList = this.buyerDetailsForm.get('details') as FormArray;
  }

  nameCompare(object1, object2) {
    return object1 && object2 && object1.name == object2.name;
  }
  statusCompare(object1, object2) {
    return object1 && object2 && object1.status == object2.status;
  }
  displayValueCompare(object1, object2) {
    return object1 && object2 && object1.displayValue == object2.displayValue;
  }

  setSupplierData(data) {
    this.supplierForm.setValue({
      turnOver: data.financials.turnoverInCr ? data.financials.turnoverInCr : null,
      debt: data.financials.debt ? data.financials.debt : null,
      pat: data.financials.pat ? data.financials.pat : null,
      marketCap: data.financials.marketCap ? data.financials.marketCap : null,
      netWorth: data.financials.networth ? data.financials.networth : null,
      industry: data.industry ? data.industry : null,
      restructring: data.restructing ? data.restructing : null,
      vintage: data.managementBackground.vintage ? data.managementBackground.vintage : null,
      entityType: data.managementBackground.entityType ? data.managementBackground.entityType : null,
      relevance: data.managementBackground.relevanceManagement ? data.managementBackground.relevanceManagement : null,
      sophastication: data.managementBackground.manufacturingSophistication ? data.managementBackground.manufacturingSophistication : null
    })
  }
  saveSupplierData() {
    this.kanBanService.setSupplierDetails(this.payload).subscribe(res => {
      if (res['status'] == 200 && res['result'] && Object.keys(res['result']).length > 0) {
        this.tostrService.success('Supplier Details Saved Successfully');
      }
    });
  }
  markAllAsTouched(formName) {
    Object.keys(formName.controls).forEach(controlName => {
      formName.controls[controlName].markAsTouched();
    });
  }

  calculateSupplierScore() {
    if (this.supplierForm.valid) {
      let supplierCount = 0;
      let supplierObj = this.supplierForm.value;
      for (let i in supplierObj) {
        if (supplierObj[i] != null && supplierObj[i] != undefined && supplierObj[i].score != null) {
          supplierCount += supplierObj[i].score
        }
      }
      this.supplierCount = supplierCount;
      this.tostrService.success("Score Calculated Successfully");
    } else {
      this.markAllAsTouched(this.supplierForm);
    }
  }
  calculateBuyerScore(index, event: Event) {
    // calculate Buyer count
    event.stopPropagation();
    let buyerCount = 0;
    let tradeRelationCount = 0;
    const data = this.buyerDetailsForm.get('details') as FormArray;
    const selectedForm = data.at(index) as FormGroup;
    if (selectedForm.valid) {
      const child = selectedForm.value;
      for (let i in child) {
        const isNullOrUndefined = i !== null && i.length !== 0 && child[i] && child[i].score && child[i].score !== null && child[i].score !== undefined;
        if (isNullOrUndefined && (!this.buyerTradeRelationArray.includes(i))) {
          buyerCount += child[i].score;
        }
        if (isNullOrUndefined && (this.buyerTradeRelationArray.includes(i))) {
          tradeRelationCount += child[i].score;
        }
      }
      if (buyerCount > 0) {
        let finalCount = Number(buyerCount) + Number(tradeRelationCount) + Number(this.supplierCount);
        selectedForm.get('finalCount').setValue(finalCount);
        selectedForm.get('tradeRelationScore').setValue(tradeRelationCount);
        selectedForm.get('count').setValue(buyerCount);
      }
      this.tostrService.success("Score Calculated Successfully");
    } else {
      this.buyerMarkAllAsTouched(selectedForm);
    }
  }
  buyerMarkAllAsTouched(formName) {
    this.markAllAsTouched(formName)
    this.buyerList.controls.forEach(control => {
      control.markAsTouched();
    });
  }

  getSupplierDetailsById(id) {
    this.kanBanService.getSupplierScore(id).subscribe(res => {
      if (res['result']) {
        let data = res['result'][0];
        if (data && data.scoreCount && data.scoreCount.supplierScore) {
          this.supplierCount = res['result'][0].scoreCount.supplierScore;
        }
        if (data && data['supplierDetails'] && data['supplierDetails']['supplier']) {
          this.supplierDetails = data['supplierDetails']['supplier'];
          this.setSupplierData(this.supplierDetails);
        }
        if (data && data.buyerDetails) {
          this.buyerArray = data.buyerDetails;
          const matchingIndices = this.findAndModifyMatchingObjects(this.data.buyerList, this.buyerArray);
          this.setBuyerForm(matchingIndices);
        }
        this.scoreCardAccessCheck(this.data, data);
      }
    })
  }
  findAndModifyMatchingObjects(arr1, arr2) {
    return arr1.map(item1 => {
      const matchingItem = arr2.find(item2 => item1.id === item2.buyer.buyerId);
      if (matchingItem) {
        return { ...item1, ...matchingItem, buyerName: item1.buyerName };
      } else {
        return { ...item1 }
      }
      return null;
    }).filter(item => item !== null);
  };

  get payload() {
    let payload: any = {}
    payload.supplier = {}
    payload.supplierId = this.data.laneId;
    payload.supplier.financials = {}
    let supplierFormData = this.supplierForm.value;
    if (supplierFormData.turnOver) {
      payload.supplier.financials.turnoverInCr = {
        min: supplierFormData.turnOver.min,
        max: supplierFormData.turnOver.max
      }
    }
    if (supplierFormData.debt) {
      payload.supplier.financials.debt = {
        min: supplierFormData.debt.min,
        max: supplierFormData.debt.max
      }
    };
    if (supplierFormData.pat) {
      payload.supplier.financials.pat = {
        min: supplierFormData.pat.min,
        max: supplierFormData.pat.max
      }
    };
    if (supplierFormData.marketCap) {
      payload.supplier.financials.marketCap = {
        status: supplierFormData.marketCap.status
      }
    }
    if (supplierFormData.netWorth) {
      payload.supplier.financials.networth = {
        min: supplierFormData.netWorth.min,
        max: supplierFormData.netWorth.max
      }
    }
    if (supplierFormData.industry) {
      payload.supplier.industry = {
        name: supplierFormData.industry.name
      }
    }
    if (supplierFormData.restructring) {
      payload.supplier.restructing = {
        status: supplierFormData.restructring.status
      }
    };
    payload.supplier.managementBackground = {}
    if (supplierFormData.entityType) {
      payload.supplier.managementBackground.entityType = {
        name: supplierFormData.entityType.name
      }
    }
    if (supplierFormData.relevance) {
      payload.supplier.managementBackground.relevanceManagement = {
        status: supplierFormData.relevance.status
      }
    }
    if (supplierFormData.vintage) {
      payload.supplier.managementBackground.vintage = {
        min: supplierFormData.vintage.min,
        max: supplierFormData.vintage.max
      }
    }
    if (supplierFormData.sophastication) {
      payload.supplier.managementBackground.manufacturingSophistication = {
        status: supplierFormData.sophastication.status
      }
    }
    return payload;
  }

  saveBuyerDetails(index, event: Event) {
    event.stopPropagation();
    const data = this.buyerDetailsForm.get('details') as FormArray;
    const selectedData = data.at(index) as FormGroup;
    let selectedValue = selectedData.value;
    let obj = {
      "supplierId": this.data.laneId,
      "buyer": {
        "buyerId": selectedValue.buyerId ? selectedValue.buyerId : null,
        "geo": {
          "name": selectedValue.buyerGeography ? selectedValue.buyerGeography.name : null
        },
        "employees": {
          "min": selectedValue.employeeStrength ? selectedValue.employeeStrength.min : null,
          "max": selectedValue.employeeStrength ? selectedValue.employeeStrength.max : null
        },
        "vintage": {
          "min": selectedValue.vintage ? selectedValue.vintage.min : null,
          "max": selectedValue.vintage ? selectedValue.vintage.max : null
        },
        "financials": {
          "status": selectedValue.financials ? selectedValue.financials.status : null
        },
        "bankLimitsInUSD": {
          "min": selectedValue.bankLimits ? selectedValue.bankLimits.min : null,
          "max": selectedValue.bankLimits ? selectedValue.bankLimits.max : null
        },
        "management": {
          "status": selectedValue.managementBackground ? selectedValue.managementBackground.status : null
        },
        "insurerRating": {
          "name": selectedValue.insurerRating ? selectedValue.insurerRating.name : null
        },
        "cofaceDetails": {
          "name": selectedValue.coface ? selectedValue.coface.name : null
        },
        "allianzDetails" : {
          "name": selectedValue.allianz ? selectedValue.allianz.name : null
        },
        "financialDetails": {
          "turnoverInMillion": {
            "min": selectedValue.turnOver ? selectedValue.turnOver.min : null,
            "max": selectedValue.turnOver ? selectedValue.turnOver.max : null
          },
          "debt": {
            "min": selectedValue.debt ? selectedValue.debt.min : null,
            "max": selectedValue.debt ? selectedValue.debt.max : null
          },
          "pat": {
            "min": selectedValue.pat ? selectedValue.pat.min : null,
            "max": selectedValue.pat ? selectedValue.pat.max : null
          },
          "marketCap": {
            "status": selectedValue.marketCap ? selectedValue.marketCap.status : null
          }
        },
        "industry": {
          "margin": {
            "status": selectedValue.margin ? selectedValue.margin.status : null
          },
          "sector": {
            "name": selectedValue.sector ? selectedValue.sector.name : null
          }
        },
        "tradeRelationship": {
          "vintage": {
            "min": selectedValue.vintageTrading ? selectedValue.vintageTrading.min : null,
            "max": selectedValue.vintageTrading ? selectedValue.vintageTrading.max : null
          },
          "pastDilution": {
            "status": selectedValue.pastDilution ? selectedValue.pastDilution.status : null
          },
          "dependency": {
            "min": selectedValue.dependency ? selectedValue.dependency.min : null,
            "max": selectedValue.dependency ? selectedValue.dependency.max : null
          },
          "salesContractGreaterThanYear": {
            "status": selectedValue.salesContract ? selectedValue.salesContract.status : null
          },
          "incoterm": {
            "name": selectedValue.incoterm ? selectedValue.incoterm.name : null
          },
          "overdues": {
            "min": selectedValue.overDues ? selectedValue.overDues.min : null,
            "max": selectedValue.overDues ? selectedValue.overDues.max : null
          }
        }
      }
    }
    if (obj['financials'] && obj['financials'].status == 'not available') {
      obj['financialDetails'] = null;
    }
    this.kanBanService.saveBuyerScoreById(obj).subscribe(res => {
      if (res['status'] == 200 && res['result'] && Object.keys(res['result']).length > 0) {
        this.tostrService.success('Buyer Details Saved Successfully');
      }
    })
  }
  setValidatorsForField(index: number, fieldName: string, validators: any, value: string) {
    const control = (this.buyerDetailsForm.get('details') as FormArray).at(index).get(fieldName);
    if (control) {
      if (value == 'set') {
        control.setValidators(validators);
      } else {
        control.clearValidators();
      }
      control.updateValueAndValidity();
    }
  }

  removeValueFromControl(index: number, fieldName: string) {
    const data = this.buyerDetailsForm.get('details') as FormArray;
    const selectedForm = data.at(index) as FormGroup;
    selectedForm.get(fieldName).setValue(null);
    data.updateValueAndValidity();
  }

  changeIndustry(event, index) {
    const data = this.buyerDetailsForm.get('details') as FormArray;
    const selectedForm = data.at(index) as FormGroup;
    let validator = [Validators.required];
    if (event.name == 'COFACE') {
      this.setValidatorsForField(index, "coface", validator, "set");
      this.setValidatorsForField(index, "allianz", validator, "remove");
      this.removeValueFromControl(index,'allianz');
      this.buyerMarkAllAsTouched(selectedForm);
    } else if(event.name == 'ALLIANZ'){
      this.setValidatorsForField(index, "allianz", validator, "set");
      this.setValidatorsForField(index, "coface", validator, "remove");
      this.removeValueFromControl(index,'coface');
      this.buyerMarkAllAsTouched(selectedForm);
    } else {
      selectedForm.get('coface').setValue(null);
      const fieldList = ['coface','allianz'];
      fieldList.forEach(val =>{
        this.setValidatorsForField(index, val, validator, "remove");
      })

    }
  }

  financialChange(event, index) {
    let validator = [Validators.required];
    const data = this.buyerDetailsForm.get('details') as FormArray;
    const selectedForm = data.at(index) as FormGroup;
    const arr = ['turnOver', 'debt', 'pat', 'marketCap'];
    if (event.status == 'available') {
      arr.forEach(val => {
        this.setValidatorsForField(index, val, validator, "set");
      });
      this.buyerMarkAllAsTouched(selectedForm);
    } else {
      arr.forEach(val => {
        selectedForm.get(val).setValue(null);
        this.setValidatorsForField(index, val, validator, "remove");
      });
      this.buyerMarkAllAsTouched(selectedForm);
    }
  }
}
