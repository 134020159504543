import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasRolePermissionDirective } from './has-role-permission.directive';
import { SpecialCharValidationDirective } from './special-char-validation.directive';
import { NumberValidationDirective } from './numberValidation.directive';

@NgModule({
  declarations: [HasRolePermissionDirective, SpecialCharValidationDirective, NumberValidationDirective],
  exports: [HasRolePermissionDirective, SpecialCharValidationDirective, NumberValidationDirective],
  imports: [
    CommonModule
  ]
})
export class DirectiveModule { }
