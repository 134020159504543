import { Component, OnInit, Inject } from "@angular/core";
import { KanbanService } from "../../../kanban.service";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { saveAs } from "file-saver";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-lane-move",
  templateUrl: "./lane-move.component.html",
  styleUrls: ["./lane-move.component.scss"],
})
export class LaneMoveComponent implements OnInit {
  laneConfig = [];
  currentLane: string;
  previousLane: string;
  laneConfigPresentData = [];
  taskRemark: string;
  countryList = [];
  configFile: any;
  laneTaskIDDetail: any;
  laneTaskIdByUrl: string;
  boardId: string;
  laneTaskConfig = [];
  coldLeadMoveReason: string = "COLD_LEAD";
  uploadedDocumentList = [];
  selectedAssigneId: any;
  isReasonBtnVisible: boolean = false;
  myControl = new FormControl("");
  getboardName: string;
  getSelectedReason: any;
  filteredOptions: Observable<string[]>;
  boardLane = [{ name: "North" }, { name: "East" }, { name: "West" }];
  CredlixRejectedReason = [
    "Unapproved Geography",
    "Unapproved Buyer / Small Buyer",
    "No trade history with the buyer",
    "Merchant Exporter/Trader",
    "Multinational Company (MNC)",
    "Exporter to many countries but wants to apply for unapproved geography only",
    "High Risk product/ Product rejected by risk team",
  ];
  ClientRejectedReason = [
    "Working & Satisfied with competitor",
    "Pricing not matched",
    "Having sufficient & under utilized banking limits",
    "Cash rich companies / Self sufficient funds",
    "Working on advance basis only",
    "Afraid to share the buyer data",
    "Invoice discounting with bank at lower rate.",
    "No recent shipment",
    "No more exporting",
    "Client did not specify the reasons",
  ];
  allInsuranceInfo: any = [];
  error: any;
  laneArrayKeys: any = [
    "cold-leads",
    "new-leads",
    "account-created-sales",
    "pre-risk",
    "eligiblity-check",
    "prepare-termsheet",
    "term-sheet-acceptance",
    "document-collection-submission",
    "underwriting",
    "rpa-sent",
    "e-sign",
    "insurance",
    "buyer-acceptance",
  ];
  selectedColdLeadReason: string[] = [];
  selectedRevivalReason: any = [];

  constructor(
    public dialogRef: MatDialogRef<LaneMoveComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _kService: KanbanService,
    public toasterService: ToastrService
  ) {
    console.log(data);
    if (data["obj"]) {
      this.previousLane = data["obj"].previousLaneName;
      this.currentLane = data["obj"].currentLaneName;
      if (this.currentLane == "cold-leads") {
        this.isReasonBtnVisible = true;
      }
      if (
        data["response"] !== null &&
        data["response"] !== undefined &&
        data["response"]["presentFields"] !== null &&
        data["response"]["presentFields"] !== undefined
      ) {
        data["response"]["presentFields"].map((data) => {
          data["readOnly"] = true;
        });
        this.laneConfigPresentData = data["response"]["presentFields"].concat(
          data["response"]["missingFields"]
        );
      }
      if (
        data["obj"]["assignees"] != null &&
        data["obj"]["assignees"].length > 0
      ) {
        this.selectedAssigneId = data["obj"]["assignees"][0]["user"].name;
        this.myControl.setValue({ name: this.selectedAssigneId });
      }
      if (data["obj"]["boardName"]) {
        this.getboardName = data["obj"]["boardName"];
      }
    }
  }

  ngOnInit() {
    this.getAssigneeList();
    if (this.getboardName == "domestic") {
      this.getSelectedReason = "COLD_LEAD";
    }
  }

  onSelectReason(event) {
    this.selectedColdLeadReason = event;
    this._kService.selectedColdLeadReason = event;
  }

  validateTaskMovement(field) {
    let obj = {
      id: this.data["obj"]["id"],
    };
    this._kService.checkLaneMovementValidation(obj).subscribe((res) => {
      if (res["status"] == 200) {
        this.laneConfig = res["result"]["missingFields"];
        let presentArr = res["result"]["presentFields"];
        presentArr.map((data) => {
          data["readOnly"] = true;
        });
        let finalData = presentArr.concat(this.laneConfig);
        for (let i = 0; i < this.laneConfigPresentData.length; i++) {
          for (let j = 0; j < finalData.length; j++) {
            if (
              (this.laneConfigPresentData[i]["type"] == "file" ||
                this.laneConfigPresentData[i]["type"] == "multi_upload") &&
              this.laneConfigPresentData[i]["field"] == field &&
              (finalData[j]["type"] == "file" ||
                finalData[j]["type"] == "multi_upload") &&
              finalData[j]["field"] == field
            ) {
              this.laneConfigPresentData[i] = finalData[j];
            }
          }
        }
        //console.log(this.laneConfigPresentData);
      }
    });
  }
  closeDialog() {
    this.dialogRef.close(false);
  }

  selectColdLeadReason(event) {
    this.getSelectedReason = event;
  }

  /**
   * Submits lane move method is Saving lane task data only . when Lane is moving to forward only
   */
  SubmitLaneMove() {
    // checking lane moving direction for restrict save Api call while lane is moving to backward lane.
    if (this.isLaneIsMovingToUpword()) {
      let reqObj = {
        id: this.data["obj"].id,
        laneId: this.data["obj"].laneId,
        data: this.data["obj"]["laneTaskIDDetail"],
        remarks: this.taskRemark,
        name: this.data["obj"].exporterName,
      };
      const additionalDetails = this.data.obj.additionalDetails
      const creditTrackerDetails = this.data.obj.additionalDetails.creditTracker
      this._kService.updateLaneTask(reqObj)
        .subscribe((res) => {
          if (res["status"] === 201) {
            if (this.data.obj.previousLaneName == 'new-leads') {
              this._kService.saveAdditionalInformation(additionalDetails)
                .subscribe((response) => {
                  if (response['status'] === 200) {
                    this.assignMake();
                  }
                });
            }
            else if (this.data.obj.previousLaneName == 'pre-risk') {
              if (this.data.obj.additionalDetails && this.data.obj.additionalDetails.creditTracker) {
                this.saveCreditData(creditTrackerDetails);
              }
            }
            else {
              this.assignMake();
            }
          }
        });
    } else {
      this.assignMake();
    }
  }

  // method will return true while lane is moving to previos lane (upword direction)
  //  false   -> return while lane is moving to next lane (downword direction)
  isLaneIsMovingToUpword() {
    let laneArrayKeys = this.laneArrayKeys;
    let previousLaneName = this.data["obj"].previousLaneName;
    let targetLaneName = this.data["obj"].currentLaneName;
    //if if are moving from Cold-leads or Moving to Cold leads , in both directions dont need to perform any save Api call.
    // for above reason returning --> false. in if condition.
    if (targetLaneName == 'cold-leads' || previousLaneName == 'cold-leads') {
      return false;
    }
    else if (
      laneArrayKeys.indexOf(targetLaneName) >
      laneArrayKeys.indexOf(previousLaneName)
    ) {
      return true;
    } else {
      return false;
    }
  }


  /**
   * this method will check buyer need to save or Not, then save buyer data if needed otherwise save lane wise data(lane tasdk Data) only
   *
   */
  saveBuyerDataRow() {
    this._kService.selectedReVivalReasons = this.selectedRevivalReason ? this.selectedRevivalReason : undefined;
    if (this.isLaneIsMovingToUpword() && this.data.obj['fileUploadData'].length > 0) {
      if (this.data.obj.boardName == 'exim') {
        this.data.obj['fileUploadData'].forEach(ele => {
          if (ele.buyerIndustry == 'Others') {
            ele['buyerIndustry'] = this.data.obj['eximBuyerId'];
          }
          ele.insuranceInfo.forEach(element => {
            if (element.insuranceDataPresent == 'false') {
              this.clearInsuranceData(element);
            }
          });
        });
      }
      this._kService.createBuyerDataRow(this.data.obj['fileUploadData'], this.data.obj.id).subscribe(res => {
        if (res) {
          this.SubmitLaneMove();
        }
      },
        (error) => {
          this.toasterService.error("Buyer Data saving request failed");
        });
    }
    else {
      if (this.isLaneIsMovingToUpword() && (this.data["obj"].previousLaneName == 'account-created-sales')) {
        this.toasterService.error("Please upload buyer data First");
      }
      else if(!this.isLaneIsMovingToUpword()){
        this.SubmitLaneMove();
      }
      else if (this.data["obj"].previousLaneName != 'account-created-sales') {
        this.SubmitLaneMove();
      }
    }
  }

  clearInsuranceData(insuranceInfo) {
    insuranceInfo.insuranceStatus = null;
    insuranceInfo.insuranceRating = null;
    insuranceInfo.insuranceAppliedAmount = null;
    insuranceInfo.insuranceApprovedAmount = null;
    insuranceInfo.insuranceAppliedDate
    insuranceInfo.changeInNameOfBuyer = null;
    insuranceInfo.changeInAddressOfBuyer = null;
    insuranceInfo.insuranceApprovedDate = null;
    insuranceInfo.insuranceComment = null;

    insuranceInfo.finalInsuranceStatus = null;
    insuranceInfo.finalInsuranceRating = null;
    insuranceInfo.finalInsuranceAppliedAmount = null;
    insuranceInfo.finalInsuranceApprovedAmount = null;
    insuranceInfo.finalInsuranceAppliedDate
    insuranceInfo.finalChangeInNameOfBuyer = null;
    insuranceInfo.finalchangeInAddressOfBuyer = null;
    insuranceInfo.finalInsuranceApprovedDate = null;
    insuranceInfo.finalInsuranceComment = null;
  }

  fetchUploadedTaskDocuments() {
    let obj = {
      where: {
        "laneTask.$id": {
          type: "search",
          value: this.data["obj"]["id"],
        },
        source: {
          type: "search",
          value: "LANETASK",
        },
        isDeleted: {
          type: "search",
          value: false,
        },
      },
    };
    this._kService.searchUploadedTaskDocuments(obj).subscribe((res) => {
      this.uploadedDocumentList = res["result"];
    });
  }
  assigneeList = [];
  filterAssigneList = [];
  getAssigneeList() {
    this._kService.getKanbanUserList().subscribe((list) => {
      this.assigneeList = list["result"];
      this.assigneeList.forEach((ele) => {
        this.filterAssigneList.push(ele.userName);
      });
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(""),
        map((value) => this._filter(value || ""))
      );
    });
  }
  onAssigneeChange(event) {
    this.selectedAssigneId = event;
  }

  assignMake() {
    let req = [];
    if (this.selectedAssigneId != "UnAssigned") {
      for (let i = 0; i < this.assigneeList.length; i++) {
        if (this.selectedAssigneId == this.assigneeList[i].userName) {
          req.push({
            remarks: "test",
            userId: this.assigneeList[i].userId,
          });
        }
      }
    }
    this._kService
      .addAssigneeToLaneTask(req, this.data["obj"].id)
      .subscribe((res) => {
        if (res["status"] == 201) {
          if (this.getboardName == "domestic") {
            this.dialogRef.close([this.getSelectedReason]);
          } else {
            this.dialogRef.close(this.getSelectedReason);
          }
        }
      });
  }

  saveCreditData(obj) {
    let lanetaskID = this.data.obj.additionalDetails.laneTaskId;
    this._kService.saveCreditTrackerData(lanetaskID, obj.creditAnalyst, obj.leadUnderwriter).subscribe(data => {
      if (data['status'] == 200) {
        this.assignMake();
      }
    });
  }

  _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.filterAssigneList.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
}
