import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
  HostBinding,
  AfterViewInit,
  AfterContentInit,
  AfterContentChecked,
  ChangeDetectionStrategy,
  Renderer2,
  ChangeDetectorRef,
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from "@angular/forms";
import { KanbanService } from "../../../kanban.service";
import { SettingService } from "src/app/settings/setting.service";
import { SelectAutocompleteComponent } from "mat-select-autocomplete";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
  MatAutocompleteTrigger,
} from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { saveAs } from "file-saver";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import * as lodash from "lodash";
import * as moment from "moment";
import { SidService } from "src/app/component/sid/services/sid.service";
import { ConfirmationDialogComponent } from "src/app/component/suppliers/listing/bulkactions-dialog/confirmation-dialog/confirmation-dialog.component";
import { LaneMoveComponent } from "../../lanes-list/lane-move/lane-move.component";
import { UploadMappingComponent } from "src/app/settings/upload-mapping/upload-mapping/upload-mapping.component";
import { PagesService } from "src/app/pages/pages.service";
import { AuthService } from "src/app/auth/auth.service";
import { isEmpty } from "lodash";
import { CommentsTabComponent } from "./comments-tab/comments-tab.component";
import { LaneTaskUsersTabComponent } from "./lane-task-users-tab/lane-task-users-tab.component";
import { LaneTaskDocumentsTabComponent } from "./lane-task-documents-tab/lane-task-documents-tab.component";
import { HistoryTabComponent } from "./history-tab/history-tab.component";
import { WatcherPopupComponent } from "./watcher-popup/watcher-popup.component";
import { FillInsuranceComponent } from "./fill-insurance/fill-insurance.component";
import { InsuranceDataListComponent } from "./insurance-data-list/insurance-data-list.component";
import { ApiService } from "src/app/apiServices/api.service";
import { Moment } from "moment";
import { MatDatepicker, ThemePalette } from "@angular/material";
import { DataService } from "src/app/data.service";
import { PuppeteerService } from "src/app/apiServices/puppeteer.service";
import { ConfirmationModelComponent } from "src/app/shared-files/shared-component/confirmation-model/confirmation-model.component";
import { GstGenerateService } from "./gst-auto-generate/gst-generate.service";
import { HttpErrorResponse } from "@angular/common/http";
import { ScoreCardComponent } from "./score-card/score-card.component";

export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}

@Component({
  selector: "app-card-details",
  templateUrl: "./card-details-v1.component.html",
  styleUrls: ["./card-details.component.scss"],
})
export class CardDetailsComponent implements OnInit {
  public keyValueForm: FormGroup;
  contactForm: FormGroup;
  additionalDetilasForm: FormGroup;
  selectedlane: string;
  _: any = lodash;
  boardLane = [];
  selectedLanePosition: number;
  selectedBoardLane = [];
  toLaneId: any;
  leadName: string;
  contactPerson: string;
  gstin: string;
  platform: string;
  myControl = new FormControl();
  laneTaskIDDetail: any;
  selectedAssign: string;
  updateTaskReqObj = {};
  rpaManualDoc: any;
  rpaDocData: any;
  setSelectdAssigne: string;
  facilityLimitAmount: any = "";
  facilityLimitCurrency: any = "";
  userProfileName: string;
  filteredOptions: Observable<any>;
  todayDate: any = new Date();
  minDate = new Date();
  options = [];
  selectedUserListOptions = [];
  selectedLeadOwnerListOptions = [];
  showError = false;
  showComments = false;
  errorMessage = "";
  termSheetDocument = [];
  panelCustomOpenState: boolean = false;
  @ViewChild(SelectAutocompleteComponent)
  multiSelect: SelectAutocompleteComponent;
  @ViewChild(MatAutocompleteTrigger) _auto: MatAutocompleteTrigger;
  @ViewChild("saveButton1") saveBtn1: ElementRef;
  @ViewChild("saveButton2") saveBtn2: ElementRef;
  @ViewChild("saveButton3") saveBtn3: ElementRef;
  //tags
  allTags = [];
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagInputCtrl = new FormControl();
  assigneeInputCtrl = new FormControl();
  leadOwnerInputCtrl = new FormControl();
  filteredTags: Observable<string[]>;
  filteredUserList: Observable<string[]>;
  filteredOwnerList: Observable<string[]>;
  filteredWatcherList: Observable<string[]>;
  allSelectedTagList = [];
  allTagList = [];
  opened = false;
  demo1TabIndex;
  selectedtbs = 2;
  file: any;
  configFile: any;
  buyerConfigUploadFile: any;
  customInfo;
  isEditableAccess: boolean = true;
  uploadedDocumentList = [];
  boardId: string;
  laneTaskIdByUrl: string;
  addTaskComment: string;
  isProgressVisible: boolean = false;
  commentlist = [];
  fetchSelectedtags = [];
  panelOpenState = [];
  countryList = [];
  laneTaskConfig = [];
  exporterArray: Array<any> = [];
  discountRateArray: Array<any> = [{paymentTerms: 30, minimumDaysCharged: 25}, {paymentTerms: 45, minimumDaysCharged: 30}, {paymentTerms: 60, minimumDaysCharged: 45}, {paymentTerms: 90, minimumDaysCharged: 60}];
  bankChargeArray = [];
  bankRateSetting = [];
  faciliyFeeAmount = [];
  newDynamic: any = {};
  newDiscountObj = {};
  fundedAmount: number;
  disbursement: any;
  facilityFee: number;
  factoringFee: number;
  validCustomFields: boolean;
  gracePeriod: any;
  isDiscussedWithCreditTeam: boolean = false;
  isPersonalGuaranteeSelected: boolean = false;
  otherConditions: string;
  chargesBeyndGracePeriod: any;
  fileUploadData = [];
  aggrementSigningDate: any;
  aggrementEffectiveDate: any;
  getTimepicker: any;
  SupplierName: any;
  profileSaveBtn: string = "Edit";
  SupplierAddress: string;
  leadExporterName: string;
  doctype: string = "GSTIN";
  gstinList: any = ["GSTIN", "CIN", "PAN"];
  displayedColumn = [
    "status",
    "rating",
    "appliedAmount",
    "approvedAmount",
    "buyerAddress",
    "comment",
  ];
  leadsSourceList = [
    "Inside Sales",
    "Direct Calling",
    "Marketing/Inbound",
    "Sponsored Event/Assoc",
    "Event & Exhibition",
    "CP Polytrade",
    "CP RXIL",
    "CP VTX",
    "CP Banks",
    "Reference",
    "Trade reboot",
    "CP Eximpe",
    "CP Adan Corporate",
    "CP Uthra",
    "CP MNS Group",
    "CP Sunheri Marketing Pvt.Ltd.",
    "CP Dipen Shah",
    "Others",
  ];
  docNumber: string;
  businessState: string;
  loginUserName: string;
  loginUserEmail: string;
  loginPhoneNumber: number;
  loginDesignation: string;
  businessType: string;
  discountingFee: string;
  fundingRate: string;
  rpaApprovedAmount: string;
  exporterName: string;
  currentUser: string;
  getGSTIN: string;
  termSheetForm: FormGroup;
  eximBuyerInd: string;
  isPrepareTermSheet: boolean = false;
  whatsappActive: boolean;
  @ViewChild("tagListInput") tagListInput: ElementRef<HTMLInputElement>;
  @ViewChild("userListInput") userListInput: ElementRef<HTMLInputElement>;
  @ViewChild("userLeadListInput")
  userLeadListInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;
  @ViewChild("autoAssignee") matAutoAssigneecomplete: MatAutocomplete;
  @ViewChild("autoLeadOwner") matAutoLeadOwner: MatAutocomplete;
  @ViewChild("autoWatchers") matAutoWatcherComplete: MatAutocomplete;
  @ViewChild("autocompleteTrigger") matACTrigger: MatAutocompleteTrigger;
  @ViewChild("autocompleteAssigneeTrigger")
  matACAssignnTrigger: MatAutocompleteTrigger;
  @ViewChild("autocompleteLeadOwnerTrigger")
  matACLeadOwnerTrigger: MatAutocompleteTrigger;

  numericNumberReg = /^(100(\.0{1,2})?|(\d{1,2})(\.\d{1,2})?)$/;
  supplierList = ["Trader", "Manufacturer"];
  plantVisit = ["Yes", "No"];
  creditMemo = ["newClientOnboard", "existigClient"];
  noOfBuyers: Number;
  selectedTabIndex: any;
  formattedAmount: any = "";
  formattedSetUpFeeAmount: any = null;
  formattedRemittanceAmount: any = null;
  storeAddedBuyerRow = [];
  shipmentAmount: string = "";
  shipmentCurrency: string;
  remittanceCurrency: string;
  remittanceAmount: any;
  setUpFeeCurrency: string;
  setUpFeeAmount: any;
  totalCurrencyList: any;
  isPDC: boolean = false;
  ifBuyerOne = false;

  displayedColumns: string[] = [
    "field",
    "operation",
    "oldValue",
    "updatedValue",
    "remarks",
    "actionBy",
    "when",
  ];
  dataSource = [];
  totalRecords: any;
  historydataSource: any;
  address = {
    city: "",
    district: "",
    state: "",
    pinCode: "",
    location: "",
  };
  mileStoneList = [];
  transactionTypeList: any = [
    "Post-ship",
    "Pre-ship",
    "Import Factoring",
    "Term Loan",
  ];
  millionReason: any;
  statelLocation: any;
  selectedInsurerName: any;
  incorporatedDate: string;
  countryRatingList: any;
  selectedInsuranceSet: any = new Set();
  selectedInsuranceArray: any = [];
  allInsuranceInfo: any[];
  error: number;
  selectedinsuranceOptions: any;
  taskAssignedToEligibility: any;
  isnewBuyerAdded: boolean = false;
  assigneeList: any;
  additionalDetails: any = [];
  historyDataSource: any;
  currency: any;
  showPdfContent: any = false;
  rmUserList: any;
  currenyHistory: any[];
  laneSortName: string;
  creditAnalystList: any;
  creditAnayst: any;
  leadUnderwriter: any;
  gstUrl: any;
  sequenceNumberOfEnabledMilestones: any;
  mexicoStateList: any = [];
  isContactChecked: boolean;
  isLeadFromSobSource: boolean;
  isDiscountRateLessFromStandard: boolean = false;
  currentMilestoneName: any;
  ROIFile: any;
  roiuploadFileID: any;
  roiFilename: any;
  buyerSettings : any;
  tradeRelationSettings : any; 
  supplierArrayList: any;
  scoreCardBuyerList : any = [];
  supplierCount : number = 0;
  isSupplierDetailsAvalible : boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public toasterService: ToastrService,
    public dialog: MatDialog,
    public apiService: ApiService,
    private sidService: SidService,
    private cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    public settingServ: SettingService,
    public _kService: KanbanService,
    private pagesService: PagesService,
    private authService: AuthService,
    public renderer: Renderer2,
    private dataService: DataService,
    private cdRef: ChangeDetectorRef,
    private readonly puppeteerService: PuppeteerService,
    private gstGenerateService: GstGenerateService
  ) {
    const items = [];
    this.keyValueForm = this.formBuilder.group({
      details: this.formBuilder.array(items),
    });
    this.contactForm = this.formBuilder.group({
      items: this.formBuilder.array(items),
    });

    this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) =>
        fruit ? this._filterabc(fruit) : this.allFruits.slice()
      )
    );
  }

  // testingGetApi(): Observable<any> {
  //   const url = 'https://uat-credlix-kanban.credlixlab.com/lane-task/contact-details?laneTaskId=6527e0738637323c9b38e0f5';
  //   return this.apiService.callRestful('GET', url,);
  // }

  // testingPostApi(obj) {
  //   const url = 'https://uat-credlix-kanban.credlixlab.com/comment/search';
  //   return this.apiService.callRestful('POST', url, { body: obj });
  // }

  ngOnInit() {
    let getSessionStorage = JSON.parse(localStorage.getItem("USERINFO"));
    if (getSessionStorage && getSessionStorage["role"]) {
      this.currentUser = getSessionStorage["role"]["userName"];
    }
    let masterAccId = getSessionStorage["role"]["masterAccount"]["id"];
    this.pagesService.getHeaderList(masterAccId).subscribe((list) => {
      this.authService.storageListHeaders(list["result"]);
    });
    this.activatedRoute.params.subscribe((params) => {
      this.laneTaskIdByUrl = params.id;
      this.getLaneDetailsById(true);
      this.updateFormattedAmount();
      this.updateFormattedSetUpFeeAmount();
      this.updateformattedRemittanceAmount();
      this.getAdditionalDetails(this.laneTaskIdByUrl, this.laneTaskIDDetail);
    });
    this.contactForm = this.formBuilder.group({
      items: this.formBuilder.array([this.conatctInfo()]),
    });
    (this.additionalDetilasForm = this.formBuilder.group({
      fundingRequirement: [null, Validators.required],
      goLiveMonth: [null, Validators.required],
      transactionType: [null, Validators.required],
      probability: [null, Validators.required],
      relationshipManagerId: [null, Validators.required],
      leadSource: [null, Validators.required],
      currency: [null, Validators.required],
      region: [null, Validators.required],
    })),
    this.setinitiasValue();
    this.getCountryRatings();
    this.getRMList();
    this.getMileStoneList();
    this.getScoreCardFields();
  }

  displayMonthYear: any;
  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = moment(normalizedYear);
    this.updateMonthYearDisplay(ctrlValue);
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = moment(normalizedMonth);
    this.updateMonthYearDisplay(ctrlValue);
    datepicker.close();
  }
  updateMonthYearDisplay(momentObj: moment.Moment) {
    this.displayMonthYear = momentObj.format("YYYY-MM-DD");
    this.additionalDetilasForm
      .get("goLiveMonth")
      .setValue(this.displayMonthYear);
  }

  get buerGeography() {
    return Object.keys(this.countryRatingList);
  }

  getRMList() {
    this._kService.getRmUserList().subscribe((list) => {
      this.rmUserList = list["result"];
    });
  }

  getAdditionalDetails(laneTaskId: string, laneTaskDetails) {
    const obj = {
      autoSuggest: false,
      where: {
        value: [
          {
            field: "laneTask.$id",
            value: [laneTaskId],
          },
        ],
      },
      pageNo: 0,
      size: 6,
    };
    this._kService.searchLeads(obj).subscribe((response) => {
      if (response["status"] == 200 && response["result"].length > 0) {
        this.additionalDetails = response["result"][0];
        this.currentMilestoneName =
          this.additionalDetails.currentMilestone.name;
        this.sequenceNumberOfEnabledMilestones =
          response["result"][0].sequenceNumberOfEnabledMilestones;
        this.setAdditionalDetails(response["result"][0], laneTaskDetails);
      }
    });
  }

  getMileStoneList() {
    this._kService.getMileStoneListForSales().subscribe((res) => {
      this.mileStoneList = res["result"];
    });
  }

  setAdditionalDetails(data, laneTaskDetails) {
    let modifiedData = this.modifyResponce(data, laneTaskDetails);
    this.additionalDetilasForm.patchValue(modifiedData);
    this.additionalDetilasForm.patchValue({
      relationshipManagerId: modifiedData.relationshipManager.userName,
      region: modifiedData.region,
    });
    this.displayMonthYear = modifiedData.goLiveMonth
      ? new Date(modifiedData.goLiveMonth)
      : null;

    if (
      this.additionalDetails &&
      this.additionalDetails.creditAnalyst &&
      this.additionalDetails.creditAnalyst.id
    ) {
      this.creditAnayst = this.additionalDetails.creditAnalyst.id;
      this.leadUnderwriter = this.additionalDetails.leadUnderwriter.id;
    }
  }

  modifyResponce(data, laneTaskDetails) {
    data.relationshipManager.userId = data.relationshipManager.id;
    data.relationshipManager.userName = data.relationshipManager.name;
    if (
      laneTaskDetails &&
      laneTaskDetails.businessCountry == "MEXICO" &&
      !this.additionalDetails.region
    ) {
      data.region = "Mexico";
    }
    return data;
  }
  getCountryRatings() {
    this._kService.getCountryRating().subscribe((res) => {
      this.countryRatingList = res["result"]["ratings"];
    });
  }

  buyerGeographyChnage(country) {
    this.countryRatingList.forEach((rating) => {});
  }

  radioChange(event: any) {
    let selectedRadio = event.value;
  }

  getSelectedTabIndex(event) {
    this.selectedTabIndex = event;
    if (event == 1) {
      // this.getBuyerData();
    } else if (event == 0) {
      // this.fetchUploadLeadEntries();
    }
  }

  getHistoryField(value) {
    if (!value) {
      return "";
    }
    let keys = Object.keys(value);
    return keys[0];
  }
  toLowerCase = (text) => {
    if (!text) {
      return "";
    }
    return text.toLowerCase();
  };

  getHistoryValue(value) {
    if (!value) {
      return "";
    }
    let keys = Object.keys(value);
    // return value[keys[0]] || "  -";
    if (value[keys[0]] && value[keys[0]].hasOwnProperty("name")) {
      return value[keys[0]].name || "   -";
    } else if (
      value[keys[0]] &&
      value[keys[0]][0] &&
      value[keys[0]][0].hasOwnProperty("amount")
    ) {
      return value[keys[0]][0].amount || "   -";
    } else {
      return value[keys[0]] || "   -";
    }
  }

  isPdcChecked(event: any, lane: any) {
    let isPdcChecked = event.target.checked;
    if (isPdcChecked) {
      this.isPDC = true;
      lane.value[0].value = null;
    } else if (!isPdcChecked) {
      this.isPDC = false;
      lane.value[1].value = null;
    }
  }

  checkStringNull(val, type) {
    if (val == null || val.toString().trim() == "") {
      this.toasterService.error("Incomplete Supplier Details");
      this.termSheetForm.controls[type].setErrors({ incorrect: true });
    } else {
      this.termSheetForm.controls[type].setErrors(null);
    }
  }

  setinitiasValue() {
    this.termSheetForm = this.formBuilder.group({
      gracePeriod: [
        "",
        [Validators.required, Validators.pattern(this.numericNumberReg)],
      ],
      chargesBeyndGracePeriod: [
        "",
        [Validators.required, Validators.pattern(this.numericNumberReg)],
      ],
      isDiscussedWithCreditTeam: [],
      fundedAmount: [
        "",
        [Validators.required, Validators.pattern(this.numericNumberReg)],
      ],
      factoringFee: [
        "",
        [Validators.required, Validators.pattern(this.numericNumberReg)],
      ],
      faciliyFeeAmountSection: [true, [Validators.required]],
      bankRateSettingSection: [true, [Validators.required]],
      bankChargesSection: [true, [Validators.required]],
      discountRateSection: [true, [Validators.required]],
      supplierName: [true, [Validators.required]],
      supplierAddress: [true, [Validators.required]],
      otherConditions: [""],
      isPersonalGuaranteeSelected: [],
    });
  }

  boardList = [];
  buyerDataIndexConfig: any;
  getBoardList() {
    this._kService.getBoardList().subscribe((res) => {
      this.boardList = res["result"];
      this.boardList.map((val) => {
        if (this.boardId == val.id) {
          this.buyerDataIndexConfig = val.buyerDataConfig;
        }
      });
    });
  }
  getActionClass(lane) {
    if (lane.forwardMove == true) {
      return "success-background";
    } else if (lane.backMove == true) {
      return "warning-background";
    } else if (lane.cold == true) {
      return "error-background";
    }
    return "";
  }

  addDiscountRatePercentage() {
    this.newDiscountObj = { paymentTerms: "", minimumDaysCharged: "" };
    this.discountRateArray.push(this.newDiscountObj);
    this.termSheetForm.controls["discountRateSection"].setErrors({
      incorrect: true,
    });
    return true;
  }
  addBankCharges() {
    if (this.islastRowFilled(this.bankChargeArray)) {
      let newChargeObj = {};
      newChargeObj = { amount: "", currency: "" };
      this.bankChargeArray.push(newChargeObj);
      this.termSheetForm.controls["bankChargesSection"].setErrors({
        incorrect: true,
      });
      return true;
    }
  }

  addDiscountRateSetting() {
    if (this.islastRowFilled(this.bankRateSetting)){
      let newChargeObj = {};
      newChargeObj = { percentage: "", currency: "" };
      this.bankRateSetting.push(newChargeObj);
      this.termSheetForm.controls["bankRateSettingSection"].setErrors({
        incorrect: true,
      });
      return true;
    }
  }
  islastRowFilled(listData){
    if (listData.length >= 1){
      if (listData[listData.length - 1].percentage  && listData[listData.length - 1].currency){
        return true;
      }
      else{
        this.toasterService.error("Please fill the last row of discount rate");
        return false;
      }
    }
    else{
      return true;
    }
  }
  addFacilityFeeAmount() {
    let newChargeObj = {};
    if (this.laneTaskIDDetail.lane.board.name == "domestic") {
      newChargeObj = { amount_percentage: "", currency: "INR", amount: "" };
    } else {
      newChargeObj = { amount_percentage: "", currency: "", amount: "" };
    }
    this.faciliyFeeAmount.push(newChargeObj);
    this.termSheetForm.controls["faciliyFeeAmountSection"].setErrors({
      incorrect: true,
    });
    return true;
  }

  deleteSheetRow(index, sheetName) {
    if (sheetName == "Exporter") {
      this.exporterArray.splice(index, 1);
    } else if (sheetName == "discountRate") {
      this.discountRateArray.splice(index, 1);
      this.checkNull(this.discountRateArray, "discountRateSection", null, true);
    } else if (sheetName == "discountRateSetting") {
      this.bankRateSetting.splice(index, 1);
      if(this.bankRateSetting && this.bankRateSetting.length == 0){
        this.isDiscountRateLessFromStandard = false;
        this.roiuploadFileID = null;
        this.enableReuploadRoi();
      }
      this.checkNull(
        this.bankRateSetting,
        "bankRateSettingSection",
        null,
        true
      );
    } else if (sheetName == "bankCharges") {
      this.bankChargeArray.splice(index, 1);
      this.checkNull(this.bankChargeArray, "bankChargesSection", null, true);
    } else if (sheetName == "faciliyFeeAmount") {
      this.faciliyFeeAmount.splice(index, 1);
      this.checkNull(
        this.faciliyFeeAmount,
        "faciliyFeeAmountSection",
        null,
        true
      );
    }
  }

  RPASheetResponse = [];
  getRPASheet() {
    this._kService
      .getRPASheetData(this.laneTaskIDDetail.id)
      .subscribe((res) => {
        this.RPASheetResponse = res["result"];
        if (this.RPASheetResponse != null && this.RPASheetResponse.length > 0) {
          (this.loginUserName = this.RPASheetResponse[0].loginUserName),
            (this.loginUserEmail = this.RPASheetResponse[0].loginUserEmail),
            (this.loginPhoneNumber = this.RPASheetResponse[0].loginPhoneNumber),
            (this.loginDesignation = this.RPASheetResponse[0].loginDesignation),
            (this.businessState = this.RPASheetResponse[0].businessState),
            (this.businessType = this.RPASheetResponse[0].businessType),
            (this.discountingFee = this.RPASheetResponse[0].discountingFees),
            (this.fundingRate = this.RPASheetResponse[0].fundingRate),
            (this.rpaApprovedAmount =
              this.RPASheetResponse[0].rpaApprovedAmount),
            (this.aggrementEffectiveDate =
              this.RPASheetResponse[0].aggrementEffectiveDate),
            (this.aggrementSigningDate =
              this.RPASheetResponse[0].aggrementSigningDate);
        }
      });
  }

  termSheetResponse = [];
  currentDate: any = new Date();

  getPrepareTermSheetData() {
    this._kService
      .getPrepareTermSheetData(this.laneTaskIDDetail.id)
      .subscribe((res) => {
        this.termSheetResponse = res["result"];
        this.termSheetDocument = res["result"];
        this.termSheetResponse = [this.laneTaskIDDetail.termSheetData];

        if (
          this.termSheetResponse != null &&
          this.termSheetResponse.length > 0
        ) {
          if (
            this.termSheetResponse && this.termSheetResponse.length > 0 &&
            this.termSheetResponse[0] && this.termSheetResponse[0]["discountRate"] && 
            this.termSheetResponse[0]["discountRate"].length > 0
          ) {
            this.discountRateArray = [];
            for (
              let i = 0;
              i < this.termSheetResponse[0]["discountRate"].length;
              i++
            ) {
              this.discountRateArray.push({
                paymentTerms:
                  this.termSheetResponse[0]["discountRate"][i].paymentTerms,
                minimumDaysCharged:
                  this.termSheetResponse[0]["discountRate"][i]
                    .minimumDaysCharged,
              });
            }
          }
          if (
            this.termSheetResponse[0] &&
            this.termSheetResponse[0]["bankCharges"] != null &&
            this.termSheetResponse[0]["bankCharges"].length > 0
          ) {
            this.bankChargeArray = [];
            for (
              let i = 0;
              i < this.termSheetResponse[0]["bankCharges"].length;
              i++
            ) {
              this.bankChargeArray.push({
                amount: this.termSheetResponse[0]["bankCharges"][i].amount,
                currency: this.termSheetResponse[0]["bankCharges"][i].currency,
                formttedBankChnarge: this.formatAmount(
                  this.termSheetResponse[0]["bankCharges"][i].amount.toString()
                ),
              });
            }
          }
          if (
            this.termSheetResponse[0] &&
            this.termSheetResponse[0]["facilityFeeAmount"] != null &&
            this.termSheetResponse[0]["facilityFeeAmount"].length > 0
          ) {
            this.faciliyFeeAmount = [];
            for (
              let i = 0;
              i < this.termSheetResponse[0]["facilityFeeAmount"].length;
              i++
            ) {
              this.faciliyFeeAmount.push({
                amount: this.formatAmount(
                  this.termSheetResponse[0]["facilityFeeAmount"][i].amount
                ),
                amount_percentage:
                  this.termSheetResponse[0]["facilityFeeAmount"][i]
                    .amount_percentage,
                currency:
                  this.termSheetResponse[0]["facilityFeeAmount"][i].currency,
              });
            }
          }

          if (
            this.termSheetResponse[0] &&
            this.termSheetResponse[0]["discountRateSetting"] != null &&
            this.termSheetResponse[0]["discountRateSetting"].length > 0
          ) {
            this.bankRateSetting = [];
            for (
              let i = 0;
              i < this.termSheetResponse[0]["discountRateSetting"].length;
              i++
            ) {
              this.bankRateSetting.push({
                percentage:
                  this.termSheetResponse[0]["discountRateSetting"][i]
                    .percentage,
                currency:
                  this.termSheetResponse[0]["discountRateSetting"][i].currency,
              });
            }
          }
          if (this.termSheetResponse[0]){
            (this.fundedAmount = this.termSheetResponse[0].fundedAmount),
              (this.factoringFee = this.termSheetResponse[0].factoringFee),
              (this.isDiscussedWithCreditTeam = this.termSheetResponse[0]
                .isDiscussedWithCreditTeam
                ? this.termSheetResponse[0].isDiscussedWithCreditTeam
                : false);
            this.gracePeriod = this.termSheetResponse[0].overDueFees
              ? this.termSheetResponse[0].overDueFees.gracePeriod
              : "";
            this.chargesBeyndGracePeriod = this.termSheetResponse[0].overDueFees
              ? this.termSheetResponse[0].overDueFees.chargesBeyondGracePeriod
              : "";
            this.isPersonalGuaranteeSelected = this.termSheetResponse[0]
              .isPersonalGuaranteeSelected
              ? this.termSheetResponse[0].isPersonalGuaranteeSelected
              : false;
            this.otherConditions = this.termSheetResponse[0].otherConditions
              ? this.termSheetResponse[0].otherConditions
              : "";
          }
        }
      });
  }
  checkNull(data, type, keyToCheck, showToast) {
    if (type == "faciliyFeeAmountSection") {
      if (data == null || data.length < 1) {
        if (showToast) {
          this.toasterService.error("Please fill all required data*");
        }
        this.termSheetForm.controls["faciliyFeeAmountSection"].setErrors({
          incorrect: true,
        });
      } else {
        let error = false;
        for (let i = 0; i < data.length; i++) {
          let keys = Object.keys(data[i]);
          for (let k = 0; k < keys.length; k++) {
            if (keys[k] == "amount") {
              continue;
            }
            if (keyToCheck != null && keyToCheck != keys[k]) {
              continue;
            }
            if (
              data[i][keys[k]] == null ||
              data[i][keys[k]].toString().trim() == ""
            ) {
              error = true;
              break;
            }
          }
          if (error) {
            break;
          }
        }
        if (!error) {
          if (keyToCheck == null || !this.checkAllValues(data, "amount")) {
            this.termSheetForm.controls["faciliyFeeAmountSection"].setErrors(
              null
            );
          }
        } else {
          if (showToast) {
            this.toasterService.error("Please fill all required data*");
          }
          this.termSheetForm.controls["faciliyFeeAmountSection"].setErrors({
            incorrect: true,
          });
        }
      }
    } else if (type == "bankRateSettingSection") {
      if (data == null || data.length < 1) {
        if (showToast) {
          this.toasterService.error("Please fill all required data*");
        }
        this.termSheetForm.controls["bankRateSettingSection"].setErrors({
          incorrect: true,
        });
      } else {
        let error = false;
        for (let i = 0; i < data.length; i++) {
          let keys = Object.keys(data[i]);
          for (let k = 0; k < keys.length; k++) {
            if (keyToCheck != null && keyToCheck != keys[k]) {
              continue;
            }
            if (
              data[i][keys[k]] == null ||
              data[i][keys[k]].toString().trim() == ""
            ) {
              error = true;
              break;
            }
          }
          if (error) {
            break;
          }
        }
        if (!error) {
          if (keyToCheck == null || !this.checkAllValues(data, null)) {
            this.termSheetForm.controls["bankRateSettingSection"].setErrors(
              null
            );
          }
        } else {
          if (showToast) {
            this.toasterService.error("Please fill all required data*");
          }
          this.termSheetForm.controls["bankRateSettingSection"].setErrors({
            incorrect: true,
          });
        }
      }
    } else if (type == "bankChargesSection") {
      if (data == null || data.length < 1) {
        if (showToast) {
          this.toasterService.error("Please fill all required data*");
        }
        this.termSheetForm.controls["bankChargesSection"].setErrors({
          incorrect: true,
        });
      } else {
        let error = false;
        for (let i = 0; i < data.length; i++) {
          let keys = Object.keys(data[i]);
          for (let k = 0; k < keys.length; k++) {
            if (keyToCheck != null && keyToCheck != keys[k]) {
              continue;
            }
            if (
              data[i][keys[k]] == null ||
              data[i][keys[k]].toString().trim() == ""
            ) {
              error = true;
              break;
            }
          }
          if (error) {
            break;
          }
        }
        if (!error) {
          if (keyToCheck == null || !this.checkAllValues(data, null)) {
            this.termSheetForm.controls["bankChargesSection"].setErrors(null);
          }
        } else {
          if (showToast) {
            this.toasterService.error("Please fill all required data*");
          }
          this.termSheetForm.controls["bankChargesSection"].setErrors({
            incorrect: true,
          });
        }
      }
    } else if (type == "discountRateSection") {
      if (data == null || data.length < 1) {
        if (showToast) {
          this.toasterService.error("Please fill all required data*");
        }
        this.termSheetForm.controls["discountRateSection"].setErrors({
          incorrect: true,
        });
      } else {
        let error = false;
        for (let i = 0; i < data.length; i++) {
          let keys = Object.keys(data[i]);
          for (let k = 0; k < keys.length; k++) {
            if (keyToCheck != null && keyToCheck != keys[k]) {
              continue;
            }
            if (
              data[i][keys[k]] == null ||
              data[i][keys[k]].toString().trim() == ""
            ) {
              error = true;
              break;
            }
          }
          if (error) {
            break;
          }
        }
        if (!error) {
          if (keyToCheck == null || !this.checkAllValues(data, null)) {
            this.termSheetForm.controls["discountRateSection"].setErrors(null);
          }
        } else {
          if (showToast) {
            this.toasterService.error("Please fill all required data*");
          }
          this.termSheetForm.controls["discountRateSection"].setErrors({
            incorrect: true,
          });
        }
      }
    }
  }

  discountRateChange(event, i, bankRateSetting) {
    let inpValue = event.target.value.trim();
    if (inpValue != null && inpValue != "" && inpValue < 12.75) {
      this.isDiscountRateLessFromStandard = true;
      this.enableReuploadRoi();
    } else {
      this.isDiscountRateLessFromStandard = false;
      this.roiuploadFileID = null;
    }
  }

  uploadDiscountrateProofFile() {
    if(this.ROIFile){
      this._kService
      .uploadDiscountRateProof(this.ROIFile, this.laneTaskIdByUrl, "ROIDoc")
      .subscribe((res) => {
        if (res["status"] == 200) {
          this.roiuploadFileID = res["result"].id;
          this.toasterService.success("File uploaded successfully");
          this.saveTermSheetData(null);
        }
      });
    }else{
      this.toasterService.error("Please Upload Roi Approval Document");
    }
  }

  isTermsheetIdPresent() {
    if (
      this.laneTaskIDDetail &&
      this.laneTaskIDDetail.termSheetData &&
      this.laneTaskIDDetail.termSheetData && this.laneTaskIDDetail.termSheetData.documentId
    ){
      return true;}
    else{
      return false;}
  }

  downloadRoiUploadedFile(download) {
    this._kService
      .getDownloadedRoiFileDetails(this.laneTaskIDDetail.termSheetData ? this.laneTaskIDDetail.termSheetData.documentId : this.roiuploadFileID)
      .subscribe({
        next: (res) => {
          this.roiFilename = res['name'];
          let roiFiletype = res['fileType'];
          if(download){
            this.downloadFile(roiFiletype);
          }
        },
        error: (error: HttpErrorResponse) => {
          console.error("Error downloading the file.", error.message);
        },
      });

  }

  getRoiUploadedFileDetails(download) {
    this._kService
      .getDownloadedRoiFileDetails(this.laneTaskIDDetail.termSheetData.documentId || this.roiuploadFileID)
      .subscribe({
        next: (res) => {
          this.roiFilename = res['name'];
          let roiFiletype = res['fileType'];
          if(download){
            this.downloadFile(roiFiletype);
          }
        },
        error: (error: HttpErrorResponse) => {
          console.error("Error downloading the file.", error.message);
        },
      });

  }

  downloadFile(fileType) {
    this._kService.downloadROIFiles(this.roiuploadFileID).subscribe({
      next: (resp: any) => {
        this.downloadFilfromApi(resp,fileType);
      },
      error: (error: HttpErrorResponse) => {
        console.error("Error downloading the file.", error.message);
      },
    });
  }

  private downloadFilfromApi(resp: any,fileType) {
    if (resp) {
      const url = window.URL.createObjectURL(resp);
      let name = this.roiFilename + fileType;
      saveAs(url, name);
      window.URL.revokeObjectURL(url);
      this.toasterService.success("File Downloaded successfully");
    }
  }

  uploadROIDocument(event) {
    this.ROIFile = event.target.files[0];
    this.roiFilename = event.target.files[0].name;
  }

  enableReuploadRoi(){
    if(this.laneTaskIDDetail.termSheetData && this.laneTaskIDDetail.termSheetData.documentId){
      this.laneTaskIDDetail.termSheetData.documentId = null;
    }
    this.roiuploadFileID = null;
    this.ROIFile = null;
    this.roiFilename = null;
  }


  checkAllValues(data, keyToCheck) {
    let error = false;
    for (let i = 0; i < data.length; i++) {
      let keys = Object.keys(data[i]);
      for (let k = 0; k < keys.length; k++) {
        if (keyToCheck != null && keys[k] == keyToCheck) {
          continue;
        }
        if (
          data[i][keys[k]] == null ||
          data[i][keys[k]].toString().trim() == ""
        ) {
          error = true;
          break;
        }
      }
      if (error) {
        break;
      }
    }
    return error;
  }
  updateBuyerData(isFromTermSheet) {
    this._kService
      .updateBuyerData(this.laneTaskIDDetail.id, this.fileUploadData)
      .subscribe((res) => {
        this.toasterService.success("Buyer Data Update Successfully");
      });
  }

  getPrepareTermSheet() {
    this._kService
      .getPrepareTermSheetData(this.laneTaskIDDetail.id)
      .subscribe((res) => {
        this.termSheetDocument = res["result"];
      });
  }

  submitPrepareTermSheet(param) {
    if (
      this.oldLeadSupplierName != this.leadNameSuplier ||
      this.oldLeadSupplierAddress != this.leadSupplierAddress
    ) {
      this.updateTaskReqObj = {
        id: this.laneTaskIDDetail.id,
        laneId: this.laneTaskIDDetail.lane.id,
        toLaneID: this.toLaneId,
      };
      this.updateTaskReqObj["data"] = [
        {
          field: "supplier_name",
          type: "string",
          value: this.leadNameSuplier,
          optional: false,
          display: true,
          displayName: "Supplier Name",
          values: null,
          additionalData: null,
          operationsData: null,
          operations: null,
        },
        {
          field: "supplier_address",
          type: "string",
          value: this.leadSupplierAddress,
          optional: false,
          display: true,
          displayName: "Supplier Address",
          values: null,
          additionalData: null,
          operationsData: null,
          operations: null,
        },
      ];
      this._kService.updateLaneTask(this.updateTaskReqObj).subscribe((res) => {
        if (res["status"] == 201) {
          this.toasterService.success("Data Saved Successfully");
          this.oldLeadSupplierName = this.leadNameSuplier;
          this.oldLeadSupplierAddress = this.leadSupplierAddress;

          // this.termSheetGenerate(param, true);
          this.termSheetGenerateFromUI();
          this.getAdditionalDetails(this.laneTaskIdByUrl, this.laneTaskIDDetail);
        }
      });
    } else {
      // this.termSheetGenerate(param, false);
      // this.isVisible = true;
      setTimeout(() => {
        this.termSheetGenerateFromUI();
      }, 1000);
    }
  }

  saveTermSheetData(param) {
    let obj = {
      fundedAmount: this.fundedAmount,
      facilityFeeAmount: this.faciliyFeeAmount,
      discountRateSetting: this.bankRateSetting,
      overDueFees: {
        gracePeriod: this.gracePeriod,
        chargesBeyondGracePeriod: this.chargesBeyndGracePeriod,
      },
      isPersonalGuaranteeSelected: this.isPersonalGuaranteeSelected,
      otherConditions: this.otherConditions,
      forceGenerate: true,
    };
    obj["buyerDataList"] = this.fileUploadData;

    if (this.laneTaskIDDetail.lane.board.name == "exim") {
      this.bankChargeArray.forEach((bankCharge) => {
        bankCharge.amount = bankCharge.formttedBankChnarge.replace(/\D/g, "");
      });
      if (this.roiuploadFileID) {
        obj['documentId'] = this.roiuploadFileID;
      }
      obj["bankCharges"] = this.bankChargeArray;
      obj["factoringFee"] = this.factoringFee;
      obj["discountRate"] = this.discountRateArray;
    } else if (this.laneTaskIDDetail.lane.board.name == "domestic") {
      obj["isDiscussedWithCreditTeam"] = this.isDiscussedWithCreditTeam;
    }
    this._kService
  .saveTermSheetData(obj, this.laneTaskIDDetail.id)
  .subscribe({
    next: (res) => {
      if (res) {
        this.toasterService.success("Saved Successfully");
      }
    },
    error: (error: HttpErrorResponse) => {
      this.getAdditionalDetails(this.laneTaskIdByUrl, this.laneTaskIDDetail);
      console.error("Error saving the data.", error.message);
    },
    complete: () => {
      this.getAdditionalDetails(this.laneTaskIdByUrl, this.laneTaskIDDetail);
    }
  });
  }
  termSheetGenerate(param, force) {
    let obj = {
      fundedAmount: this.fundedAmount,
      facilityFeeAmount: this.faciliyFeeAmount,
      discountRateSetting: this.bankRateSetting,
      overDueFees: {
        gracePeriod: this.gracePeriod,
        chargesBeyondGracePeriod: this.chargesBeyndGracePeriod,
      },
      isPersonalGuaranteeSelected: this.isPersonalGuaranteeSelected,
      otherConditions: this.otherConditions,
      forceGenerate: true,
    };
    obj["buyerDataList"] = this.fileUploadData;
    if (this.laneTaskIDDetail.lane.board.name == "exim") {
      obj["bankCharges"] = this.bankChargeArray;
      obj["factoringFee"] = this.factoringFee;
      obj["discountRate"] = this.discountRateArray;
    } else if (this.laneTaskIDDetail.lane.board.name == "domestic") {
      obj["isDiscussedWithCreditTeam"] = this.isDiscussedWithCreditTeam;
    }
    //  this._kService.ValidateTermSheetPDF(obj, this.laneTaskIDDetail.id)
    // .subscribe((res) => {
    this._kService
      .generateTermSheetPDF(obj, this.laneTaskIDDetail.id)
      .subscribe((res) => {
        if (res) {
          this.toasterService.success("Saved Successfully");
        }
        if (param) {
          var contentDispositionHeader = res.headers.get("content-disposition");
          let result = contentDispositionHeader
            .split(";")[1]
            .trim()
            .split("=")[1];
          result = result.replace(/"/g, "");
          saveAs(res.body, result);
          return result.replace(/"/g, "");
        }
      });
  }

  termSheetGenerateFromUI() {
    let obj = {
      fundedAmount: this.fundedAmount,
      facilityFeeAmount: this.faciliyFeeAmount,
      discountRateSetting: this.bankRateSetting,
      overDueFees: {
        gracePeriod: this.gracePeriod,
        chargesBeyondGracePeriod: this.chargesBeyndGracePeriod,
      },
      isPersonalGuaranteeSelected: this.isPersonalGuaranteeSelected,
      otherConditions: this.otherConditions,
      forceGenerate: true,
    };
    obj["buyerDataList"] = this.fileUploadData;
    if (this.laneTaskIDDetail.lane.board.name == "exim") {
      obj["bankCharges"] = this.bankChargeArray;
      obj["factoringFee"] = this.factoringFee;
      obj["discountRate"] = this.discountRateArray;
    } else if (this.laneTaskIDDetail.lane.board.name == "domestic") {
      obj["isDiscussedWithCreditTeam"] = this.isDiscussedWithCreditTeam;
    }
    this._kService
      .ValidateTermSheetPDF(obj, this.laneTaskIDDetail.id)
      .subscribe((res) => {
        if (res["result"]) {
          this._kService
            .getGeneratedTermSheetData(obj, this.laneTaskIDDetail.id)
            .subscribe((res) => {
              if (res && res["result"]) {
                this.isVisible = true;
                this.buyerCurrencyBaseTable_termsheet(
                  res["result"].buyerDataList
                );
                this.captureAndDownloadPDF(res["result"]);
                this.getLaneDetailsById(this.laneTaskIdByUrl);
                this.toasterService.success("Termsheet generated Successfully");
              }
            });
        }
      });
  }

  buyerCurrencyBaseTable_termsheet(buyerdata: any) {
    this.currenyHistory = [];
    let totalUSD_currency = 0;
    let totalEuro_currency = 0;
    let GBP_currency = 0;
    buyerdata.forEach((buyer) => {
      if (buyer.currencyOfInvoice === "USD") {
        totalUSD_currency = totalUSD_currency + buyer.totalLimitAmount;
      }
      if (buyer.currencyOfInvoice === "EUR") {
        totalEuro_currency = totalEuro_currency + buyer.totalLimitAmount;
      }
      if (buyer.currencyOfInvoice === "GBP") {
        GBP_currency = GBP_currency + buyer.totalLimitAmount;
      }
    });
    if (totalUSD_currency) {
      this.currenyHistory.push({
        currency: "USD",
        value: totalUSD_currency,
        srNo: this.currenyHistory.length + 1,
      });
    }
    if (totalEuro_currency) {
      this.currenyHistory.push({
        currency: "EURO",
        value: totalEuro_currency,
        srNo: this.currenyHistory.length + 1,
      });
    }
    if (GBP_currency) {
      this.currenyHistory.push({
        currency: "GBP",
        value: GBP_currency,
        srNo: this.currenyHistory.length + 1,
      });
    }
  }
  changeRpaDate(event, type) {
    if (type == "effectiveDate") {
      let endValue = event.value;
      this.aggrementEffectiveDate = moment(endValue).format("YYYY-MM-DD");
    } else if (type == "signingDate") {
      let value = event.value;
      this.aggrementSigningDate = moment(value).format("YYYY-MM-DD");
    }
  }
  submitRPASheet() {
    if (this.RPADocumentList.length <= 0) {
      this.toasterService.error("Please upload RPA to proceed");
      return;
    }
    this.updateLoginUserDetails();
  }

  updateLoginUserDetails() {
    let obj = {
      id: this.laneTaskIDDetail.id,
      loginUserEmail: this.loginUserEmail,
      loginPhoneNumber: this.loginPhoneNumber,
      loginUserName: this.loginUserName,
      loginDesignation: this.loginDesignation,
    };
    this._kService.updateLoginUserDetails(obj).subscribe((res) => {
      if (res && res["status"] == 200) {
        this.generateRPAReport();
      }
    });
  }

  generateRPAReport() {
    let obj = {
      businessState: this.businessState,
      businessType: this.businessType,
      discountingFees: this.discountingFee,
      fundingRate: this.fundingRate,
      rpaApprovedAmount: this.rpaApprovedAmount,
      aggrementEffectiveDate: this.aggrementEffectiveDate,
      aggrementSigningDate: this.aggrementSigningDate,
      manualRpaDocument: this.rpaDocData,
    };
    this._kService.saveRPA(obj, this.laneTaskIDDetail.id).subscribe(
      (resp) => {
        if (resp) {
          this.toasterService.success("Data Saved Successfully");
        }
      },
      async (error) => {
        const jsonError = await new Response(error.error).json();
        await this.toasterService.error(jsonError.message);
      }
    );
  }
  downloadRPASheet(id: string) {
    this._kService.downloadRPA(id).subscribe((res) => {
      let blob = new Blob([res], {
        type: "application/pdf",
      });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "RPASent.pdf";
      link.click();
      window.URL.revokeObjectURL(link.href);
      this.fetchRPADocument();
    });
  }
  manualRPASheet(event, lane) {
    if (lane) {
      return;
    }
    if (event[0]) {
      this.configFile = event[0];
    } else {
      this.configFile = event.target.files[0];
    }
    const uploadData = new FormData();
    uploadData.append("file", this.configFile);
    this._kService
      .generateManualRPAPDF(uploadData, this.laneTaskIDDetail.id)
      .subscribe((res) => {
        this.toasterService.success("File Uploaded Successfully!");
        this.rpaUploadedFile(this.laneTaskIDDetail.id);
      });
  }
  downloadRpaDocument(docId) {
    this._kService.downloadRPAManual(docId).subscribe({
      next: (resp: any) => {
        var contentDispositionHeader = resp.headers.get("content-disposition");
        let result = contentDispositionHeader
          .split(";")[1]
          .trim()
          .split("=")[1];
        result = result.replace(/"/g, "");
        saveAs(resp.body, result);
        return result.replace(/"/g, "");
      },
    });
  }
  deleteRpaDocument(docId) {
    this._kService.deleteRpa(docId).subscribe({
      next: (resp: any) => {
        if (resp.result) {
          this.toasterService.success("File Deleted Successfully!");
        } else {
          this.toasterService.error("Something Went Wrong!");
        }
      },
      complete: () => {
        this.rpaUploadedFile(this.laneTaskIDDetail.id);
      },
    });
  }
  rpaUploadedFile(id) {
    this._kService.rpaFiles(id).subscribe((resp) => {
      this.rpaDocData = resp["result"];
      this.rpaManualDoc = resp["result"];
      this.fetchRPADocument();
    });
  }

  downloadBuyerTemplate() {
    this.JSONToCSVConvertor(this.templateHeaderList(), "template", true);
  }

  templateHeaderList() {
    let responseList = [];
    let orderObject = {};
    if (this.laneTaskIDDetail.lane.board.name == "exim") {
      orderObject["Buyer Legal Name"] = "Sample Text (text only)";
      orderObject["Buyer Address"] = "Sample Text (text only)";
      orderObject["Nature of Business"] = "Service/Product (select only one)";
      orderObject["Currency Of Invoice"] = "INR (select only one)";
      orderObject["Limit Required"] = "100 (number only)";
      orderObject["Insurer"] = "Sample Text (text only)";
      orderObject["Tenor Requested"] = "100 (number only)";
      orderObject["Buyer Geography"] = "India (select only one)";
    } else if (this.laneTaskIDDetail.lane.board.name == "domestic") {
      orderObject["Buyer Name"] = "Sample Text (text only)";
      orderObject["Buyer Address"] = "Sample Text (text only)";
      orderObject["Buyer Rating"] = "Sample Text (text only)";
      orderObject["Buyer Turnover"] = "100 (number only)";
    }

    responseList.push(orderObject);

    return responseList;
  }

  JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
    var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
    var CSV = "";
    if (ShowLabel) {
      var row = "";
      for (var index in arrData[0]) {
        row += index + ",";
      }

      row = row.slice(0, -1);
      CSV += row + "\r\n";
    }

    for (var i = 0; i < arrData.length; i++) {
      var row = "";
      for (var index in arrData[i]) {
        row += '"' + arrData[i][index] + '",';
      }

      row.slice(0, row.length - 1);
      CSV += row + "\r\n";
    }

    if (CSV == "") {
      alert("Invalid data");
      return;
    }

    var fileName = "MyReport_";
    fileName += ReportTitle.replace(/ /g, "_");
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);
    var link = document.createElement("a");
    link.href = uri;
    link.setAttribute("visibility", "hidden");
    link.download = "template_sample" + ".csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  getBoardLaneById() {
    this._kService.getLaneWithUserAccess(this.boardId, "").subscribe((res) => {
      this.boardLane = res["result"]["levelAccess"];
      this.changeLaneTask(this.laneTaskIDDetail.lane.id);
    });
  }

  deletePrepareTermDocs(id) {
    this._kService.deleteTermSheet(id).subscribe((res) => {
      if (res["status"] == 200) {
        this.toasterService.success("Term Sheet Delete Successfully");
        this._kService
          .getPrepareTermSheetData(this.laneTaskIDDetail.id)
          .subscribe((res) => {
            this.termSheetDocument = res["result"];
          });
      }
    });
  }
  getLaneName(name) {
    return name.replaceAll("-", " ").toLowerCase();
  }
  changeLaneTask(laneTaskDetailsId) {
    let currentLaneStatus: string;
    currentLaneStatus = this.laneTaskIDDetail.lane.name;
    this.selectedBoardLane = [];
    let forwardMove;
    let backMove;
    for (let i = 0; i < this.boardLane.length; i++) {
      this.boardLane[i]["laneDTO"]["backMove"] = false;
      this.boardLane[i]["laneDTO"]["forwardMove"] = false;
      this.boardLane[i]["laneDTO"]["selected"] = false;
      this.boardLane[i]["laneDTO"]["current"] = false;
      this.boardLane[i]["laneDTO"]["cold"] = false;
      if (this.boardLane[i]["laneDTO"].id == laneTaskDetailsId) {
        let laneAccessTypeDTO = this.boardLane[i]["accessType"];
        if (laneAccessTypeDTO.length == 1) {
          if (laneAccessTypeDTO.includes("VIEW")) {
            this.isEditableAccess = false;
          }
        }
        this.selectedLanePosition = this.boardLane[i]["laneDTO"].position;
        forwardMove = this.selectedLanePosition + 1;
        backMove = this.selectedLanePosition - 1;
        this.selectedBoardLane.push(this.boardLane[i]["laneDTO"]);
        this.boardLane[i]["laneDTO"]["current"] = true;
        this.boardLane[i]["laneDTO"]["selected"] = true;
        this.boardLane[i]["laneDTO"]["isEditable"] =
          this.boardLane[i]["accessType"][0];
        this.selectedlane = this.boardLane[i]["laneDTO"].name;
      }
    }

    if (currentLaneStatus == "cold-leads") {
      let laneResultPosition = this.laneTaskIDDetail.fromLane.position - 1;
      this.boardLane[laneResultPosition]["laneDTO"]["backMove"] = true;
      this.boardLane[laneResultPosition]["laneDTO"]["selected"] = false;
      this.selectedBoardLane.push(
        this.boardLane[laneResultPosition]["laneDTO"]
      );
      //manually Pushing account-created lane in List to show in lane  while Lead in Cold Leads Stage
      this.laneTaskIDDetail.accountCreatedLane["laneDTO"] = {};
      this.laneTaskIDDetail.accountCreatedLane["laneDTO"]["backMove"] = true;
      this.laneTaskIDDetail.accountCreatedLane["laneDTO"]["selected"] = false;
      this.selectedBoardLane.push(this.laneTaskIDDetail.accountCreatedLane);
    } else {
      for (let i = 0; i < this.boardLane.length; i++) {
        if (this.selectedlane != "cold-leads") {
          if (this.boardLane[i]["laneDTO"].name == "cold-leads") {
            this.boardLane[i]["laneDTO"]["isEditable"] =
              this.boardLane[i]["accessType"][0];
            this.boardLane[i]["laneDTO"]["cold"] = true;
            this.selectedBoardLane.push(this.boardLane[i]["laneDTO"]);
          }
        }
        if (this.selectedlane != "cold-leads") {
          if (this.boardLane[i]["laneDTO"].position == forwardMove) {
            if (this.selectedlane != "buyer-acceptance") {
              this.boardLane[i]["laneDTO"]["forwardMove"] = true;
              this.boardLane[i]["laneDTO"]["selected"] = false;
              this.boardLane[i]["laneDTO"]["isEditable"] =
                this.boardLane[i]["accessType"][0];
              this.selectedBoardLane.push(this.boardLane[i]["laneDTO"]);
            }
          } else if (this.boardLane[i]["laneDTO"].position == backMove) {
            this.boardLane[i]["laneDTO"]["backMove"] = true;
            this.boardLane[i]["laneDTO"]["selected"] = false;
            this.boardLane[i]["laneDTO"]["isEditable"] =
              this.boardLane[i]["accessType"][0];
            this.selectedBoardLane.push(this.boardLane[i]["laneDTO"]);
          }
        }
      }
    }
    //removing mannually prepare termsheet lane moving feature while leads region is from Mexico.
    if (
      this.laneTaskIDDetail.businessCountry == "MEXICO" &&
      this.laneTaskIDDetail.lane.name == "eligiblity-check"
    ) {
      let indexToRemove = null;
      indexToRemove = this.selectedBoardLane.findIndex(
        (item) => item.name === "prepare-termsheet"
      );
      if (indexToRemove != null) {
        this.selectedBoardLane.splice(indexToRemove, 1);
      }
    }
  }

  generateCAMReport() {
    this._kService.generateCAMReport(this.getGSTIN).subscribe((res) => {
      if (res["status"] == 200) {
        this.toasterService.success("Download Successfully");
        var contentDispositionHeader = res.headers.get("content-disposition");
        let result = contentDispositionHeader
          .split(";")[1]
          .trim()
          .split("=")[1];
        result = result.replace(/"/g, "");
        saveAs(res.body, result);
        return result.replace(/"/g, "");
      }
    });
  }
  getAllTags() {
    this._kService.getAllTags(this.boardId).subscribe((res: any) => {
      this.allTags = res["result"];

      this.allTags.forEach((ele) => {
        this.allTagList.push(ele.tagName);
      });
      this.filteredTags = this.tagInputCtrl.valueChanges.pipe(
        startWith(null),
        map((tagValue: string | null) =>
          tagValue ? this._filter(tagValue) : this.allTagList.slice()
        )
      );
    });
  }

  addTagInput(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || "").trim()) {
      this.allSelectedTagList.push(value.trim());
    }

    if (input) {
      input.value = "";
    }

    this.tagInputCtrl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.allSelectedTagList.indexOf(tag);

    if (index >= 0) {
      this.allSelectedTagList.splice(index, 1);
    }
  }
  newValue: string;
  selectedTags(event: MatAutocompleteSelectedEvent): void {
    this.newValue = event.option.viewValue;
    let isValPresent = this.allTagList.findIndex((x) => {
      return x == event.option.viewValue;
    });
    if (isValPresent == -1) {
      let subStr = event.option.viewValue.substring(
        0,
        event.option.viewValue.length - 10
      );
      this.newValue = subStr;
    }
    if (this.allSelectedTagList.includes(this.newValue)) {
    } else {
      this.allSelectedTagList.push(this.newValue);
    }
    this.tagListInput.nativeElement.value = "";
    this.tagInputCtrl.setValue(null);
    requestAnimationFrame(() => {
      this.openAuto(this.matACTrigger);
    });
    let tagObj = [];
    let reqObj = {};

    tagObj.push({
      boardId: this.boardId,
      tagName: this.newValue,
    });

    reqObj = {
      id: this.laneTaskIDDetail.id,
      laneId: this.laneTaskIDDetail.lane.id,
      toLaneID: this.toLaneId,
    };
    reqObj["tags"] = tagObj;
    this._kService.updateLaneTask(reqObj).subscribe((res) => {
      if (res["status"] == 201) {
        //  this.getLaneTaskHistory();
        this.getLaneDetailsById(false);
      }
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allTagList.filter(
      (tag) => tag.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  openAuto(trigger: MatAutocompleteTrigger) {
    trigger.openPanel();
    this.tagListInput.nativeElement.focus();
  }

  selectedAssigneSend;
  onAssigneeChange(event) {
    this.selectedAssigneSend = event.option.value.id;
  }

  setValue(item) {
    const formArray = new FormArray([]);
    let key = Object.keys(item);
    for (let x of key) {
      formArray.push(
        this.formBuilder.group({
          key: x,
          value: item[x],
        })
      );
    }
    this.keyValueForm.setControl("details", formArray);
    this.customInfo = this.keyValueForm.get("details") as FormArray;
  }

  getLaneCofigFields(response) {
    this._kService
      .getLaneConfigById(this.laneTaskIDDetail.lane.id)
      .subscribe((res) => {
        if (res["status"] == 200) {
          this.laneTaskConfig = res["result"]["config"];
          if (response["data"] != null && response["data"].length > 0) {
            let checkarrya = response["data"];
            for (let i = 0; i < checkarrya.length; i++) {
              this.laneTaskConfig.push(checkarrya[i]);
            }
          }
          this.laneTaskConfig = Object.values(
            this.laneTaskConfig.reduce(
              (acc, cur) => Object.assign(acc, { [cur.field]: cur }),
              {}
            )
          );
        }
      });
  }
  getSupplierAddress(leadData: any) {
    if (leadData && leadData.length > 0) {
      const supplierAddressObj = leadData.find(
        (data) => data.field == "supplier_address"
      );
      if (supplierAddressObj) {
        return supplierAddressObj.value;
      }
    }
    return "";
  }

  laneArrayKeys = [];
  oldLeadSupplierName: string;
  oldLeadSupplierAddress: string;
  leadNameSuplier: string;
  leadSupplierAddress: any;
  getLaneDetailsById(isFromInit) {
    this._kService.detailsByLaneId(this.laneTaskIdByUrl).subscribe((res) => {
      this.laneArrayKeys = [];
      this.selectedUserListOptions = [];
      this.selectedLeadOwnerListOptions = [];
      this.fruits = [];
      this.allSelectedTagList = [];
      this.laneTaskIDDetail = res["result"];
      this.whatsappActive = this.laneTaskIDDetail.whatsappActive;
      this.boardId = res["result"].boardId;
      this.taskAssignedToEligibility = res["result"].taskAssignedToEligibility;
      this.currentTabId = this.laneTaskIDDetail.lane.id;
      this.currentTabName = this.laneTaskIDDetail.lane.name;
      this.exporterName = this.laneTaskIDDetail.name;
      this.leadNameSuplier = this.exporterName;
      this.leadExporterName = this.laneTaskIDDetail.name;
      if(this.laneTaskIDDetail && this.laneTaskIDDetail.termSheetData && this.laneTaskIDDetail.termSheetData.documentId){
        this.roiuploadFileID = this.laneTaskIDDetail.termSheetData.documentId;
        this.getRoiUploadedFileDetails(false);
      }
      if(
        res["result"] &&
        res["result"].termSheetData && res["result"].termSheetData.discountRateSetting &&
        res["result"].termSheetData.discountRateSetting.length &&
        res["result"].termSheetData.discountRateSetting[0].percentage &&
        res['result'].termSheetData.discountRateSetting[0].percentage < 12.75){
          this.isDiscountRateLessFromStandard = true;
        }else{
          this.isDiscountRateLessFromStandard = false;
        }

      this.leadSupplierAddress = this.getSupplierAddress(
        this.laneTaskIDDetail.data
      );
      this.checkLeadFromSob(res); //if Lead is coming From Supplier Onboarding Flow then we will disabled lead source input field & and pre-filled to SOB
      if (res["result"])
        this.demo1TabIndex = this.laneTaskIDDetail.lane.position;
      this.laneTaskConfig = res["result"]["laneWiseData"];
      let keys = Object.keys(this.laneTaskConfig);
      let isPresent = keys.findIndex((x) => {
        return x == "prepare-termsheet";
      });
      if (isPresent != -1) {
        this.isPrepareTermSheet = true;
      }
      let isColdAdded = false;
      for (let i = 0; i < keys.length; i++) {
        let obj = {
          keys: keys[i],
          value: this.laneTaskConfig[keys[i]],
        };
        // this.panelOpenState.push({ value: false });
        if (
          this.laneTaskIDDetail.lane.name == "cold-leads" &&
          this.laneTaskIDDetail.fromLane.position <= i
        ) {
          if (isColdAdded == false) {
            this.laneArrayKeys.push({
              keys: keys[keys.length - 1],
              value: this.laneTaskConfig[keys[keys.length - 1]],
            });
            isColdAdded = true;
          }
          continue;
        }
        this.laneArrayKeys.push(obj);
      }
      this.laneArrayKeys = this.laneArrayKeys.map((item) => {
        Object.keys(item).forEach((key) => {
          if (item[key] == "new-leads") {
            let detailsArra = [];
            detailsArra = item["value"];
            for (let i = 0; i < detailsArra.length; i++) {
              if (detailsArra[i]["field"] == "supplier_address") {
                this.oldLeadSupplierAddress = detailsArra[i].value;
                this.leadSupplierAddress = detailsArra[i].value;
              }
              if (detailsArra[i]["field"] == "supplier_name") {
                this.oldLeadSupplierName = detailsArra[i].value;
                this.leadNameSuplier = detailsArra[i].value;
              }
              if (this.laneTaskIDDetail.lane.board.name == "domestic") {
                if (detailsArra[i]["field"] == "supplier_name") {
                  detailsArra[i].value = this.leadExporterName;
                }
                this.leadNameSuplier = this.leadExporterName;
              }
            }
          }
          item["value"] = item["value"].map((data) => {
            if (
              data["type"] == "boolean" &&
              data["value"] != false &&
              data["value"] != true
            ) {
              data["value"] = data["value"] != "false";
            }
            return data;
          });
        });
        return item;
      });

      this.laneArrayKeys = this.laneArrayKeys.map((ele) => {
        if (ele.keys == "new-leads" || ele.keys == "account-created-sales") {
          for (let i = 0; i < ele.value.length; i++) {
            if (ele.value[i].field == "monthly_shipments_amount") {
              if (
                ele.value[i]["values"] != null &&
                ele.value[i]["values"].length != 0
              ) {
                this.shipmentAmount = ele.value[i]["values"][0].amount;
                this.formattedAmount = this.formatAmount(this.shipmentAmount);
                this.shipmentCurrency = ele.value[i]["values"][0].currency;
                this.millionReason = ele.value[i]["values"][0].reason;
              }
            }
            if (ele.value[i].field == "remittance_charge") {
              if (
                ele.value[i]["values"] != null &&
                ele.value[i]["values"].length != 0
              ) {
                this.remittanceAmount = ele.value[i]["values"][0].amount;
                this.formattedRemittanceAmount = this.formatAmount(
                  this.remittanceAmount
                );
                this.remittanceCurrency = ele.value[i]["values"][0].currency;
              }
            }
            if (ele.value[i].field == "setup_fee") {
              if (
                ele.value[i]["values"] != null &&
                ele.value[i]["values"].length != 0
              ) {
                this.setUpFeeAmount = ele.value[i]["values"][0].amount;
                this.formattedSetUpFeeAmount = this.formatAmount(
                  this.setUpFeeAmount
                );
                this.setUpFeeCurrency = ele.value[i]["values"][0].currency;
              }
            }
            if (ele.value[i].field == "identification_number") {
              if (
                ele.value[i]["values"] != null &&
                ele.value[i]["values"].length != 0
              ) {
                this.docNumber = ele.value[i]["values"][0].number;
                this.identificationType = ele.value[i]["values"][0].type;
              }
            }
            if (ele.value[i].field == "buyer_region") {
              if (
                ele.value[i]["dataList"] != null &&
                ele.value[i]["dataList"].length != 0
              ) {
                this.buyerRegionList = [];
                this.buyerRegionList = ele.value[i]["dataList"];
              }
            }
            if (ele.value[i].field == "state") {
              if (
                ele.value[i]["dataList"] != null &&
                ele.value[i]["dataList"].length != 0
              ) {
                this.statelLocation = undefined;
                this.statelLocation = ele.value[i]["dataList"];
              }
            }

            if (ele.value[i].field == "gstin") {
              this.getGSTIN = ele.value[i]["value"];
            }
          }
        }
        return ele;
      });
      if (res["result"].customInfo != null) {
        if (!isEmpty(res["result"].customInfo)) {
          this.setValue(res["result"].customInfo);
        }
      }
      if (res["result"].assignee != null && res["result"].assignee.length > 0) {
        for (let i = 0; i < res["result"].assignee.length; i++) {
          if (res["result"].assignee[i].user) {
            this.selectedUserListOptions.push(
              res["result"].assignee[i].user["name"]
            );
          }
        }
      }
      if (res["result"].leadOwner != null) {
        this.selectedLeadOwnerListOptions.push(res["result"].leadOwner["name"]);
      }

      if (res["result"].watchers != null && res["result"].watchers.length > 0) {
        for (let i = 0; i < res["result"].watchers.length; i++) {
          if (res["result"].watchers[i].user) {
            this.fruits.push(res["result"].watchers[i].user["name"]);
          }
        }
      }
      if (res["result"].tags != null && res["result"].tags.length > 0) {
        for (let j = 0; j < res["result"]["tags"].length; j++) {
          this.fetchSelectedtags.push(res["result"]["tags"][j]);
          this.allSelectedTagList.push(res["result"]["tags"][j].tagName);
        }
      }

      if (this.laneTaskConfig) {
        this.getUploadedFileData("config", this.laneTaskIDDetail.lane.id);
      }
      if (this.laneTaskIDDetail.lane.name == "new-leads") {
        this.getContactDetails();
        this.getAdditionalDetails(this.laneTaskIdByUrl, this.laneTaskIDDetail);
      }
      if (this.laneTaskIDDetail.lane.name == "pre-risk") {
        this.getAdditionalDetails(this.laneTaskIdByUrl, this.laneTaskIDDetail);
      }
      if (isFromInit) {
        this.getBoardLaneById();
        this.getBoardList();
        this.getAllTags();
        this.getUserList();
        this.getTaskComment();
        this.getCountryList();
        this.fetchCountryList();
        this.fetchUploadedTaskDocuments();
        this.fetchRPADocument();
        this.getRPASheet();
        this.getDropdownData();

        this.getPrepareTermSheetData();
        this.rpaUploadedFile(this.laneTaskIDDetail.id);
        setTimeout(() => {
          this.checkNull(
            this.discountRateArray,
            "discountRateSection",
            null,
            false
          );
          this.checkNull(
            this.bankRateSetting,
            "bankRateSettingSection",
            null,
            false
          );
          this.checkNull(
            this.bankChargeArray,
            "bankChargesSection",
            null,
            false
          );
          this.checkNull(
            this.faciliyFeeAmount,
            "faciliyFeeAmountSection",
            null,
            false
          );
        }, 1000);
      }
      this.activatedRoute.queryParams.subscribe((params) => {
        this.selectedIndex = params.tabIndex;
        if (params.tabIndex == 1) {
          this.getLaneTaskHistory();
        }
      });
    });
  }
  allUserList = [];
  pageNumber: number = 0;
  pageSize: number = 10;
  totalLaneHistory: number;
  userlist = [];

  //if Lead is coming From Supplier Onboarding Flow then we will disabled lead source input field & and pre-filled to SOB
  checkLeadFromSob(res) {
    this.isLeadFromSobSource = res["result"].leadSource == "SOB";
    this.isLeadFromSobSource ? this.leadsSourceList.push("SOB") : null;
  }
  validatePhoneNumber() {
    if (this.loginPhoneNumber.toString().length == 10) {
      return false;
    }
  }

  restrictToTenDigits(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    // Allow: Backspace, Delete, Arrow keys
    if (
      ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(event.key)
    ) {
      return;
    }
    // If length exceeds 10, prevent the input
    if (input.value.length >= 10) {
      event.preventDefault();
    }
  }
  getUserList() {
    this._kService.getKanbanUserList().subscribe((list) => {
      if (list["status"] == 200) {
        this.options = list["result"];
        this.options.forEach((ele) => {
          this.allUserList.push(ele.userName);
          this.userlist = this.allUserList;
        });
        this.filteredUserList = this.assigneeInputCtrl.valueChanges.pipe(
          startWith(null),
          map((userValue: string | null) =>
            userValue
              ? this._filterUserList(userValue)
              : this.allUserList.slice()
          )
        );

        this.filteredOwnerList = this.leadOwnerInputCtrl.valueChanges.pipe(
          startWith(null),
          map((userValue: string | null) =>
            userValue
              ? this._filterUserList(userValue)
              : this.allUserList.slice()
          )
        );

        this.filteredWatcherList = this.fruitCtrl.valueChanges.pipe(
          startWith(null),
          map((fruit: string | null) =>
            fruit ? this._filterabc(fruit) : this.allUserList.slice()
          )
        );
      }
    });
  }

  getMoreHistory(event: any) {
    this.pageNumber = event.pageIndex;
    // this.pageNumber = this.pageNumber + 1;
    this.getLaneTaskHistory();
  }

  selectedUserList(event: MatAutocompleteSelectedEvent, type) {
    let newValue = event.option.viewValue;
    let isValPresent = this.allUserList.findIndex((x) => {
      return x == event.option.viewValue;
    });
    if (isValPresent == -1) {
      let subStr = event.option.viewValue.substring(
        0,
        event.option.viewValue.length - 15
      );
      newValue = subStr;
    }
    if (type == "assignee") {
      if (this.selectedUserListOptions.includes(newValue)) {
        //this.selectedUserListOptions = [this.selectedUserListOptions.filter(tag => tag !== newValue)];
      } else {
        this.selectedUserListOptions.push(newValue);
      }
      this.userListInput.nativeElement.value = "";
      this.assigneeInputCtrl.setValue(null);

      // keep the autocomplete opened after each item is picked.
      requestAnimationFrame(() => {
        this.openAssigneeAuto(this.matACAssignnTrigger, "assignee");
      });
      this.assignMake(newValue);
    } else if (type == "leadOwner") {
      if (this.selectedLeadOwnerListOptions.includes(newValue)) {
      } else {
        this.selectedLeadOwnerListOptions.push(newValue);
      }
      this.userLeadListInput.nativeElement.value = "";
      this.leadOwnerInputCtrl.setValue(null);

      // keep the autocomplete opened after each item is picked.
      requestAnimationFrame(() => {
        this.openAssigneeAuto(this.matACAssignnTrigger, "lead");
      });
      this.leadOwnerAssign(newValue);
    }
  }

  openAssigneeAuto(trigger: MatAutocompleteTrigger, value) {
    if (value == "assignee") {
      trigger.openPanel();
      this.userListInput.nativeElement.focus();
    } else {
      trigger.openPanel();
      this.userLeadListInput.nativeElement.focus();
    }
  }
  private _filterUserList(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allUserList.filter(
      (user) => user.toLowerCase().indexOf(filterValue) >= 0
    );
  }
  addAssigneeInput(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;

    if ((value || "").trim()) {
      this.selectedUserListOptions.push(value.trim());
    }

    if (input) {
      input.value = "";
    }
    this.assigneeInputCtrl.setValue(null);
  }

  addLeadOwnerInput(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;

    if ((value || "").trim()) {
      this.selectedLeadOwnerListOptions.push(value.trim());
    }

    if (input) {
      input.value = "";
    }
    this.leadOwnerInputCtrl.setValue(null);
  }

  getAssigneeName(name) {
    var matches = name.match(/\b(\w)/g);
    if (matches.length > 2) {
      matches.splice(2, matches.length);
    }
    this.userProfileName = matches.join("").toUpperCase();
    return this.userProfileName;
  }
  addLaneTask() {
    this.customInfo = this.keyValueForm.get("details") as FormArray;
    this.customInfo.push(this.createItem());
  }
  createItem(): FormGroup {
    return this.formBuilder.group({
      key: [],
      value: [],
    });
  }
  conatctInfo(): FormGroup {
    return this.formBuilder.group({
      name: ["", Validators.required],
      email: ["", Validators.required],
      phone: ["", Validators.required],
      designation: ["", Validators.required],
      isPrimary: [false, Validators.required],
    });
  }

  contactInfoArray: any;
  addContactInfo() {
    this.contactInfoArray = this.contactForm.get("items") as FormArray;
    if (this.contactForm.valid) {
      this.contactInfoArray.push(this.conatctInfo());
    }
  }
  primarySet(event, index) {
    this.contactInfoArray = this.contactForm.get("items") as FormArray;
    for (let i = 0; i < this.contactInfoArray.length; i++) {
      if (i != index) {
        this.contactInfoArray.controls[i].get("isPrimary").setValue(false);
      } else {
        this.contactInfoArray.controls[i].get("isPrimary").setValue(true);
      }
    }
  }
  shouldMilestoneDisabled(optionMilestoneSeq) {
    let optionMilestone = optionMilestoneSeq;
    if (this.sequenceNumberOfEnabledMilestones) {
      let flag =
        this.sequenceNumberOfEnabledMilestones.includes(optionMilestone);
      return !flag;
    }
  }
  getColdLaneMoveReason: string;
  laneId: string;
  onLeadChange(event) {
    this.laneId;
    this.selectedBoardLane.map((data) => {
      if (event == data["name"]) {
        this.laneId = data["id"];
      }
    });
    let configobj = {
      id: this.laneTaskIdByUrl,
      laneId: this.laneTaskIDDetail.lane.id,
      previousLaneName: this.laneTaskIDDetail.lane.name,
      currentLaneName: event,
      assignees: this.laneTaskIDDetail["assignee"],
      boardName: this.laneTaskIDDetail.lane.board.name,
      laneTaskIDDetail:
        this.laneTaskIDDetail.laneWiseData[this.laneTaskIDDetail.lane.name],
      fileUploadData: this.fileUploadData,
      exporterName: this.exporterName,
      eximBuyerId: this.eximBuyerInd,
      additionalDetails: {
        currency: this.additionalDetilasForm.value.currency,
        laneTaskId: this.laneTaskIDDetail.id,
        fundingRequirement: this.additionalDetilasForm.value.fundingRequirement,
        goLiveMonth: this.additionalDetilasForm.value.goLiveMonth,
        transactionType: this.additionalDetilasForm.value.transactionType,
        probability: this.additionalDetilasForm.value.probability,
        relationshipManagerId: this.additionalDetilasForm.value
          .relationshipManagerId
          ? this.rmUserList
              .filter(
                (assignee) =>
                  assignee.name ===
                  this.additionalDetilasForm.value.relationshipManagerId
              )
              .map((assignee) => assignee.id)[0]
          : null,
        leadSource: this.additionalDetilasForm.value.leadSource,
        region: this.additionalDetilasForm.value.region,
        creditTracker: {
          creditAnalyst: this.creditAnayst,
          leadUnderwriter: this.leadUnderwriter,
        },
      },
    };
    const dialogRef = this.dialog.open(LaneMoveComponent, {
      disableClose: true,
      width: "55%",
      height: "70%",
      data: {
        obj: configobj,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != false) {
        this.getColdLaneMoveReason = result;
      }
      if (result == undefined || result != false) {
        this.selectedlane = event;
        this.assignLaneTaskOneToAnother(this.laneId);
        if(event == 'gst-details'){     // api to disable score card edit functionality for sales team 
          this.setSalesAccessDisable();
        }
      } else if (result == false) {
        this.selectedlane = this.laneTaskIDDetail.lane.name;
      }
    });

    // }
  }
  getLeadStatus = (lead) => {
    let status = {
      application_status: "",
      lead_status: "",
    };
    if (lead.lane.name == "new-leads") {
      status["application_status"] = "Application Created";
    } else if (lead.lane.position > 1 && lead.lane.position < 5) {
      status["application_status"] = "Application Processing";
    } else if (lead.lane.position == 5) {
      status["application_status"] = "Awaiting Termsheet";
    } else if (lead.lane.position > 5) {
      status["application_status"] = "Termsheet Issued";
    }
    status["lead_status"] = lead.lane.displayName;
    return status;
  };
  showHistory(task) {
    const dialogRef = this.dialog.open(HistoryTabComponent, {
      disableClose: false,
      panelClass: "boardLaneHistoryeg",
      width: "70%",
      // height: '95%',
      //  position : {bottom: '0px', right:'0px'} ,
      data: {
        laneTask: this.laneTaskIDDetail,
      },
    });
  }

  getLaneTaskHistory(reset?) {
    if (reset) {
      this.pageNumber = 0;
    }
    let obj = {
      from: this.pageNumber,
      size: this.pageSize,
    };
    this._kService
      .getLaneTaskHistory(this.laneTaskIDDetail.id, obj)
      .subscribe((res) => {
        this.totalRecords = res["total"];
        this.pageNumber = this.pageNumber;
        this.historyDataSource = res["result"];
      });
  }

  watcherPopup() {
    const dialogRef = this.dialog.open(WatcherPopupComponent, {
      disableClose: false,
      panelClass: "watcherPopupWrap",
      data: {
        currentBoardID: this.boardId,
        laneTaskDetails: this.laneTaskIDDetail,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getLaneDetailsById(false);
    });
  }

  onSideTabToggle = (type) => {
    let component = null;
    if (type == "users") {
      component = LaneTaskUsersTabComponent;
      const dialogRef = this.dialog.open(component, {
        disableClose: false,
        width: "30%",
        height: "100%",
        position: { bottom: "0px", right: "0px" },
        data: {
          laneTask: this.laneTaskIDDetail,
          userList: this.options,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.getLaneDetailsById(true);
      });
    } else if (type == "documents") {
      component = LaneTaskDocumentsTabComponent;
      const dialogRef = this.dialog.open(component, {
        disableClose: false,
        width: "35%",
        height: "50%",
        // position : {bottom: '0px', right:'0px'} ,
        data: {
          laneTask: this.laneTaskIDDetail,
          userList: this.options,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {});
    } else {
      component = CommentsTabComponent;
      const dialogRef = this.dialog.open(component, {
        disableClose: false,
        width: "30%",
        height: "100%",
        position: { bottom: "0px", right: "0px" },
        data: {
          laneTask: this.laneTaskIDDetail,
          userList: this.options,
        },
      });
      // dialogRef.afterClosed().subscribe((result) => {
      // });
    }
  };
  doSubmit() {}

  setTagColor(tag) {
    for (let i = 0; i < this.allTags.length; i++) {
      if (this.allTags[i].tagName == tag) {
        return this.allTags[i].tagColor;
      }
    }
  }
  checkLaneMovement() {}
  currentTabId: any;
  tabName: string;
  currentTabName: string;

  tabChanged(event?) {
    if (event.tab.textLabel == "new-leads") {
      this.getContactDetails();
    } else if (event.index == 0) {
      this.getContactDetails();
    } else if (event.index == 2) {
      this.getCreditTrackerDropdownList();
    }
    this.demo1TabIndex = this.demo1TabIndex ? event.index : this.laneTaskIDDetail.lane.position;
    if (this.boardLane != null && this.boardLane.length != 0) {
      this.boardLane.map((val) => {
        if (val.laneDTO.name == this.laneArrayKeys[event.index].keys) {
          this.currentTabId = val.laneDTO.id;
          this.currentTabName = val.laneDTO.name;
        }
      });
      this.getBuyerData(this.currentTabId);
    }
    this.tabName = event.tab.textLabel;
    this.getContactDetails();
  }

  getCreditTrackerDropdownList() {
    this.getCreditAnalystList();
  }

  getCreditAnalystList() {
    this._kService.getRmUserListForCreditTracker().subscribe((list) => {
      this.creditAnalystList = list["result"];
    });
  }

  downloadGStReport() {
    const obj = {
      url: this.gstGenerateService.gstUrl,
    };
    this.gstGenerateService.getGstReportDownloadFile(obj).subscribe({
      next: (blob: Blob) => {
        const url = window.URL.createObjectURL(blob);
        saveAs(url, "invoice-template.xls");
        window.URL.revokeObjectURL(url);
      },
      error: (error: HttpErrorResponse) => {
        console.error("Error downloading the file.", error.message);
      },
    });
  }

  downloadfile(res) {
    var contentDispositionHeader = res.headers.get("content-disposition");
    this.resultDownload = contentDispositionHeader
      .split(";")[1]
      .trim()
      .split("=")[1];
    let fileName = this.resultDownload.replace(/"/g, "");
    saveAs(res.body, fileName);
    return fileName;
  }

  assignLaneTaskOneToAnother(laneId) {
    for (let j = 0; j < this.selectedBoardLane.length; j++) {
      if (this.selectedlane == this.selectedBoardLane[j].name) {
        this.toLaneId = this.selectedBoardLane[j].id;
      }
    }
    let obj = {
      id: this.laneTaskIDDetail.id,
      laneId: this.laneTaskIDDetail.lane.id,
      toLaneID: this.toLaneId,
      coldLeadReasons: this._kService.selectedColdLeadReason,
      revivalReason: this._kService.selectedReVivalReasons,
    };
    if (obj.revivalReason.length == 0) {
      delete obj.revivalReason;
    }
    if (this.getColdLaneMoveReason != null) {
      obj["coldLeadReasons"] = this.getColdLaneMoveReason;
    }
    this._kService.assignLaneTaskMovement(obj).subscribe(
      (res) => {
        if (res["status"] == 200) {
          this.getLaneDetailsById(true);
          this.getAdditionalDetails(
            this.laneTaskIdByUrl,
            this.laneTaskIDDetail
          );
        }
      },
      (error) => {
        if (error) {
          this.selectedlane = this.laneTaskIDDetail.lane.name;
        }
      }
    );
  }
  chaneAssigne() {
    let obj = [];
    for (let i = 0; i < this.selectedUserListOptions.length; i++) {
      for (let j = 0; j < this.options.length; j++) {
        if (this.selectedUserListOptions[i] == this.options[j].name) {
          obj.push({
            remarks: "test",
            userId: this.options[j].id,
          });
          this._kService
            .addAssigneeToLaneTask(obj, this.laneTaskIDDetail.id)
            .subscribe((res) => {});
        }
      }
    }
  }
  selectedIndex: any;
  cardDetailsTabChnage(event) {
    if (event == "1") {
      this.getLaneTaskHistory("true");
    } else if (event == "0") {
    }
  }

  closeDialog() {}

  assignMake(userName) {
    let req = [];
    this.options.map((ele) => {
      if (userName == ele.userName) {
        req.push({
          remarks: "test",
          userId: ele.userId,
        });
      }
    });
    this._kService
      .addAssigneeToLaneTask(req, this.laneTaskIDDetail.id)
      .subscribe((res) => {
        if (res["status"] == 201) {
          this.getLaneDetailsById(false);
        }
      });
  }

  removeSelectedRow(currentfiledata, index) {
    this.fileUploadData.splice(index, 1);
  }

  AddMoreBuyer(currentfiledata, index) {
    this.fileUploadData.push("");
  }
  leadOwnerAssign(userName) {
    let req;
    this.options.map((ele) => {
      if (userName == ele.userName) {
        req = {
          id: this.laneTaskIDDetail.id,
          leadOwner: {
            id: ele.userId,
          },
        };
      }
    });
    this._kService.addLeadOwnerToLaneTask(req).subscribe((res) => {
      if (res["status"] == 200) {
        // this.getLaneTaskHistory();
        this.getLaneDetailsById(false);
      }
    });
  }

  fruitCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  fruits: string[] = [];
  allFruits: string[] = ["Apple", "Lemon", "Lime", "Orange", "Strawberry"];

  @ViewChild("fruitInput") fruitInput: ElementRef<HTMLInputElement>;

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || "").trim()) {
      this.fruits.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }

    this.fruitCtrl.setValue(null);
  }

  selectedWatchersList(event: MatAutocompleteSelectedEvent): void {
    let newValue = event.option.viewValue;
    let isValPresent = this.allUserList.findIndex((x) => {
      return x == event.option.viewValue;
    });
    if (isValPresent == -1) {
      let subStr = event.option.viewValue.substring(
        0,
        event.option.viewValue.length - 15
      );
      newValue = subStr;
    }
    if (this.fruits.includes(newValue)) {
    } else {
      this.fruits.push(newValue);
    }
    this.fruitInput.nativeElement.value = "";
    this.fruitCtrl.setValue(null);

    requestAnimationFrame(() => {
      this.openAssigneeAuto(this.matACAssignnTrigger, "");
    });
    this.assignWatchers();

    // this.fruits.push(event.option.viewValue);
    // this.fruitInput.nativeElement.value = '';
    // this.fruitCtrl.setValue(null);
  }

  assignWatchers() {
    let req = [];
    for (let i = 0; i < this.options.length; i++) {
      this.fruits.map((val) => {
        if (val == this.options[i].userName) {
          req.push({
            notificationTypes: ["EMAIL"],
            userId: this.options[i].userId,
          });
        }
      });
    }
    this._kService
      .addWatchersToLaneTask(req, this.laneTaskIDDetail.id)
      .subscribe((res) => {
        if (res["status"] == 200) {
          //this.getLaneTaskHistory();
          this.getLaneDetailsById(false);
        }
      });
  }

  private _filterabc(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allUserList.filter(
      (fruit) => fruit.toLowerCase().indexOf(filterValue) === 0
    );
  }

  submitContactDetails() {
    let result = [];
    result = this.contactForm.value["items"].filter(
      (item) => item.name != null
    );
    this._kService
      .saveContactDetails(result, this.laneTaskIDDetail.id)
      .subscribe((res) => {
        if (res["status"] == 200) {
          this.getContactDetails();
        }
      });
  }
  submit() {}

  deleteContactDetails(item, index) {
    if(this.contactInfoArray.length == 1){
      this.toasterService.error("At least one contact is required");
      return;
    }
    else{
      let contactId = item.value.id;
      if (
        this.storeContactDetails != null &&
        this.storeContactDetails.length != 0
      ) {
        if (contactId != null && contactId != undefined) {
          this._kService
            .deleteContactDetails(this.laneTaskIDDetail.id, contactId)
            .subscribe((res) => {
              if (res["status"] == 200) {
                var formData = this.contactForm.get("items") as FormArray;
                formData.reset();
                this.toasterService.success("Successfully Deleted");
                this.getContactDetails();
              }
            });
        }
        else {
          this.contactInfoArray.removeAt(index);
          this.toasterService.success("Successfully Deleted");
        }
      } else {
        this.contactInfoArray.removeAt(index);
        this.toasterService.success("Successfully Deleted");
      }
    }
  }
  storeContactDetails = [];
  getContactDetails() {
    this._kService
      .getContactDetails(this.laneTaskIDDetail.id)
      .subscribe((res) => {
        this.storeContactDetails = res["result"];
        const formArray = new FormArray([]);
        //let key = Object.keys(res['result']);
        if (
          (res["result"] == null || res["result"].length < 1) &&
          this.contactInfoArray == undefined
        ) {
          formArray.push(
            this.formBuilder.group({
              name: "",
              email: "",
              phone: "",
              designation: "",
              id: "",
              isPrimary: true,
            })
          );
          this.contactForm.setControl("items", formArray);
          this.contactInfoArray = this.contactForm.get("items") as FormArray;
        } else if (res["result"] != null && res["result"].length > 0) {
          for (let x of res["result"]) {
            formArray.push(
              this.formBuilder.group({
                name: x.name,
                email: x.email,
                phone: x.phone,
                designation: x.designation,
                id: x.id,
                isPrimary: x.isPrimary ? x.isPrimary : false,
              })
            );
          }
          this.contactForm.setControl("items", formArray);
          this.contactInfoArray = this.contactForm.get("items") as FormArray;
        }
        this.contactFromDisabled;
        //  if Any contact details is not primary then set first contact as primary from Ui side .
        if (
          this.primaryContact &&
          this.primaryContact.length > 0 &&
          !this.isAnyContactIsPrimary()
        ) {
          const contact = this.primaryContact.at(0) as FormGroup;
          contact.get("isPrimary").setValue(true);
        }
      });
  }

  get primaryContact() {
    return this.contactForm.get("items") as FormArray;
  }
  isAnyContactIsPrimary() {
    let value = false;
    for (let i = 0; i < this.contactInfoArray.length; i++) {
      if (this.contactInfoArray.controls[i].get("isPrimary").value) {
        value = true;
        break;
      }
    }
    return value;
  }
  get contactFromDisabled() {
    if (this.laneTaskIDDetail.lane.name != "new-leads") {
      this.contactForm.disable();
    } else {
      this.contactForm.enable();
    }
    return true;
  }

  saveBuyerDataRow() {
    if (this.laneTaskIDDetail.lane.board.name == "exim") {
      this.fileUploadData.forEach((ele) => {
        if (ele.buyerIndustry == "Others") {
          ele["buyerIndustry"] = this.eximBuyerInd;
        }
        ele.insuranceInfo.forEach((element) => {
          if (element.insuranceDataPresent == "false") {
            this.clearInsuranceData(element);
          }
        });
      });
    }
    this._kService
      .createBuyerDataRow(this.fileUploadData, this.laneTaskIDDetail.id)
      .subscribe((res) => {
        if (res) {
          this.getBuyerData(this.laneTaskIDDetail.lane.id);
          this.getLaneDetailsById(false);
        }
      });
  }

  clearInsuranceData(insuranceInfo) {
    insuranceInfo.insuranceStatus = null;
    insuranceInfo.insuranceRating = null;
    insuranceInfo.insuranceAppliedAmount = null;
    insuranceInfo.insuranceApprovedAmount = null;
    insuranceInfo.insuranceAppliedDate;
    insuranceInfo.changeInNameOfBuyer = null;
    insuranceInfo.changeInAddressOfBuyer = null;
    insuranceInfo.insuranceApprovedDate = null;
    insuranceInfo.insuranceComment = null;

    insuranceInfo.finalInsuranceStatus = null;
    insuranceInfo.finalInsuranceRating = null;
    insuranceInfo.finalInsuranceAppliedAmount = null;
    insuranceInfo.finalInsuranceApprovedAmount = null;
    insuranceInfo.finalInsuranceAppliedDate;
    insuranceInfo.finalChangeInNameOfBuyer = null;
    insuranceInfo.finalchangeInAddressOfBuyer = null;
    insuranceInfo.finalInsuranceApprovedDate = null;
    insuranceInfo.finalInsuranceComment = null;
  }

  isValidBuyerData() {
    for (const buyer of this.fileUploadData) {
      // Check if the buyer has insuranceInfo
      if (buyer.insuranceInfo && Array.isArray(buyer.insuranceInfo)) {
        // Push each insurance object to the allInsuranceInfo array
        for (const insurance of buyer.insuranceInfo) {
          insurance.buyerName = buyer.buyerName;
          this.allInsuranceInfo.push(insurance);
        }
      }
    }
    for (let i = 0; i < this.allInsuranceInfo.length; i++) {
      for (let i = 0; i < this.allInsuranceInfo.length; i++) {
        if (
          this.allInsuranceInfo[i]["insuranceStatus"] &&
          this.allInsuranceInfo[i]["insuranceRating"] &&
          this.allInsuranceInfo[i]["insuranceAppliedAmount"] &&
          this.allInsuranceInfo[i]["insuranceApprovedAmount"] &&
          this.allInsuranceInfo[i]["changeInNameOfBuyer"] &&
          this.allInsuranceInfo[i]["changeInAddressOfBuyer"] &&
          this.allInsuranceInfo[i]["insuranceApprovedDate"] &&
          this.allInsuranceInfo[i]["insuranceComment"]
        ) {
        } else {
          this.error = this.error + 1;
        }
      }
    }
    if (this.error > 0) {
      return false;
    } else {
      return true;
    }
  }

  saveAdditionalInformation() {
    const obj = {
      currency: this.additionalDetilasForm.value.currency,
      laneTaskId: this.laneTaskIDDetail.id,
      fundingRequirement: this.additionalDetilasForm.value.fundingRequirement,
      goLiveMonth: this.additionalDetilasForm.value.goLiveMonth,
      transactionType: this.additionalDetilasForm.value.transactionType,
      probability: this.additionalDetilasForm.value.probability,
      relationshipManagerId: this.additionalDetilasForm.value
        .relationshipManagerId
        ? this.rmUserList
            .filter(
              (assignee) =>
                assignee.name ===
                this.additionalDetilasForm.value.relationshipManagerId
            )
            .map((assignee) => assignee.id)[0]
        : null,
      leadSource: this.additionalDetilasForm.value.leadSource,
      region: this.additionalDetilasForm.value.region,
    };
    this._kService.saveAdditionalInformation(obj).subscribe((response) => {
      if (response["status"] === 200) {
        this.getAdditionalDetails(this.laneTaskIdByUrl, this.laneTaskIDDetail);
      } else {
        this.toasterService.error("Error saving additional1");
      }
    });
  }

  submitForm(lane, laneName) {
    this.updateTaskReqObj = {
      id: this.laneTaskIDDetail.id,
      laneId: this.laneTaskIDDetail.lane.id,
      toLaneID: this.toLaneId,
    };
    if (lane != undefined || lane != null) {
      this.updateTaskReqObj["data"] = lane["value"];
    }
    if (
      this.keyValueForm.value["details"] != null &&
      this.keyValueForm.value["details"].length > 0
    ) {
      this.keyValueForm.value["details"] = this.keyValueForm.value[
        "details"
      ].filter((item) => item.key != null || item.value != null);
      let formDataFormat = this.keyValueForm.value;
      var dataMap = {};
      // get custom info from form
      var customInfo = formDataFormat;
      customInfo["details"].forEach((element) => {
        dataMap[element.key] = element.value;
      });
      this.updateTaskReqObj["customInfo"] = dataMap;
    }
    if (this.deleteAssignList != null && this.deleteAssignList.length > 0) {
      this.updateTaskReqObj["removeAssignees"] = this.deleteAssignList;
    }
    if (laneName == "new-leads") {
      this.updateTaskReqObj["name"] = this.exporterName;
    }
    let arry = [];
    arry.push(lane);
    lane = arry.map((ele) => {
      if (
        ele.keys == "new-leads" &&
        (this.shipmentAmount != undefined || this.shipmentCurrency != undefined)
      ) {
        for (let i = 0; i < ele.value.length; i++) {
          if (ele.value[i].field == "monthly_shipments_amount") {
            ele.value[i]["values"] = [
              {
                amount: this.shipmentAmount,
                currency: this.shipmentCurrency,
                reason: this.millionReason,
              },
            ];
          }
          if (ele.value[i].field == "supplier_name") {
            ele.value[i]["value"] = this.exporterName;
          }
        }
      }
      if (ele.keys == "account-created-sales") {
        for (let i = 0; i < ele.value.length; i++) {
          if (
            ele.value[i].field == "remittance_charge" &&
            (this.remittanceAmount != undefined ||
              this.remittanceCurrency != undefined)
          ) {
            //TODO: incase of 0 amount below condition will break
            ele.value[i]["values"] = [
              {
                amount: this.remittanceAmount
                  ? this.remittanceAmount.toString().replace(/\D/g, "")
                  : "",
                currency: this.remittanceCurrency,
              },
            ];
          }
          if (
            ele.value[i].field == "setup_fee" &&
            (this.setUpFeeAmount != undefined ||
              this.setUpFeeCurrency != undefined)
          ) {
            //TODO: incase of 0 amount below condition will break
            ele.value[i]["values"] = [
              {
                amount: this.setUpFeeAmount
                  ? this.setUpFeeAmount.toString().replace(/\D/g, "")
                  : "",
                currency: this.setUpFeeCurrency,
              },
            ];
          }

          if (
            ele.value[i].field == "identification_number" &&
            ele.value[i].display &&
            (this.identificationType != undefined ||
              this.docNumber != undefined)
          ) {
            ele.value[i]["values"] = [
              {
                type: this.identificationType,
                number: this.docNumber,
              },
            ];
          }
          if (ele.value[i].field == "supplier_gstin") {
            ele.value[i]["value"] = this.docNumber;
          } else if (
            ele.value[i].field == "identification_number" &&
            ele.value[i].display
          ) {
            ele.value[i]["value"] = this.docNumber;
          }
        }
      }
      return ele;
    });

    if (this.fileUploadData != null && this.fileUploadData.length > 0) {
      this.saveBuyerDataRow();
    }
    this._kService.updateLaneTask(this.updateTaskReqObj).subscribe((res) => {
      if (res["status"] == 201) {
        if (laneName == "pre-risk") {
          this.saveCreditData();
        }
        if (laneName == "new-leads") {
          this.saveAdditionalInformation();
          lane = arry.map((ele) => {
            for (let i = 0; i < ele.value.length; i++) {
              if (ele.value[i].field == "contact_details") {
                if (
                  this.contactForm.value["items"] != null &&
                  this.contactForm.value["items"].length != 0
                ) {
                  this.submitContactDetails();
                }
              }
            }
          });
        }
        if (laneName == "insurance") {
          this.toasterService.success("Insurance data saved successfully ");
        } else {
          this.toasterService.success("Data Saved Successfully");
        }
      }
    });
  }

  saveCreditData() {
    this._kService
      .saveCreditTrackerData(
        this.laneTaskIDDetail.id,
        this.creditAnayst,
        this.leadUnderwriter
      )
      .subscribe((data) => {
        if (data["status"] == 200) {
        }
      });
  }

  selectMilestone(option, currentIndex) {
    let data = {
      title: "Please Confirm",
      content: "Are you sure to update?",
      additional: "",
      class: "green",
    };
    const dialogRef = this.dialog.open(ConfirmationModelComponent, {
      disableClose: true,
      width: "400px",
      height: "250px",
      data: data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const obj = {
          laneTaskId: this.laneTaskIDDetail.id,
          currentMilestoneId: option.id,
          milestoneUpdates: {
            actionDate: null,
            actionItem: null,
            currentMilestone: option.name,
            pendingdOn: null,
          },
        };
        this._kService.selectMilestone(obj).subscribe((data) => {
          if (data["status"] == 200) {
            this.additionalDetails.currentMilestone = option;
            if (data && data["result"] && data["result"]["msg"]) {
              alert(data["result"]["msg"]);
              this.getLaneDetailsById(this.laneTaskIdByUrl);
            } else {
              this.toasterService.success("Current Milestone Updated");
            }
            this.getAdditionalDetails(
              this.laneTaskIdByUrl,
              this.laneTaskIDDetail
            );
          } else {
            this.toasterService.error("Current Milestone Updation Failed");
          }
        });
      }
    });
  }

  initPageLoading() {
    this.getLaneDetailsById(true);
    this.updateFormattedAmount();
    this.updateFormattedSetUpFeeAmount();
    this.updateformattedRemittanceAmount();
    this.getAdditionalDetails(this.laneTaskIdByUrl, this.laneTaskIDDetail);
  }
  SubmitForms() {
    if (this.saveBtn1) {
      this.saveBtn1["_elementRef"]["nativeElement"].click();
    } else if (this.saveBtn2 && this.laneTaskIDDetail.lane.name != "rpa-sent") {
      this.saveBtn2["_elementRef"]["nativeElement"].click();
    } else {
      this.saveBtn3["_elementRef"]["nativeElement"].click();
    }
  }
  changeLeadName(leadName) {
    this.updateTaskReqObj = {
      id: this.laneTaskIDDetail.id,
      laneId: this.laneTaskIDDetail.lane.id,
      toLaneID: this.toLaneId,
      name: leadName,
    };
    this._kService.updateLaneTask(this.updateTaskReqObj).subscribe((res) => {
      if (res["status"] == 201) {
        this.isIconVisible = false;
        this.toasterService.success("Data Saved Successfully");
        this.laneArrayKeys = this.laneArrayKeys.map((ele) => {
          if (ele.keys == "new-leads") {
            for (let i = 0; i < ele.value.length; i++) {
              if (ele.value[i].field == "supplier_name") {
                ele.value[i].value = this.leadExporterName;
              }
            }
          }
          this.leadNameSuplier = this.leadExporterName;
          return ele;
        });
      }
    });
  }

  isIconVisible: boolean = false;
  getLeadName(leadname) {
    if (leadname != this.laneTaskIDDetail.name) {
      this.isIconVisible = true;
    } else {
      this.isIconVisible = false;
    }
  }
  resetLeadName() {
    this.leadExporterName = this.laneTaskIDDetail.name;
    this.isIconVisible = false;
  }

  copyBuyerDataRow(row) {
    //this.fileUploadData.push(row);
    let obj = {
      approvedLimit: row.approvedLimit,
      buyerAddress: "",
      buyerName: "",
      currency: null,
      currencyOfInvoice: row.currencyOfInvoice,
      declineReasonComment: null,
      email: "",
      expectedNoOfInvoices: row.expectedNoOfInvoices,
      expectedOpenAccountTurnover: row.expectedNoOfInvoices,
      expectedTotalSellerTurnover: row.expectedTotalSellerTurnover,
      id: "",
      isSelected: row.isSelected,
      limitRecommended: row.limitRecommended,
      limitRequired: row.limitRequired,
      max_limit: row.max_limit,
      natureOfBusiness: "",
      negotiatedROI: row.negotiatedROI,
      number_of_days: row.number_of_days,
      reasonForDecline: row.reasonForDecline,
      requestedLimit: row.requestedLimit,
      starting_from_invoice: row.starting_from_invoice,
      supplierAddress: row.supplierAddress,
      supplierName: row.supplierName,
      tenor: row.tenor,
      tenorFrom: row.tenorFrom,
      tenorRequested: row.tenorRequested,
      totalLimitAmount: row.totalLimitAmount,
      insuranceInfo: JSON.parse(JSON.stringify(row.insuranceInfo)),
    };
    this.fileUploadData.push(obj);
  }
  deleteBuyerData(id, i) {
    if (id) {
      let confObj = {
        data: "kanbanBoard",
      };
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: true,
        width: "30%",
        height: "auto",
        data: confObj,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this._kService
            .deleteBuyerData(this.laneTaskIDDetail.id, id)
            .subscribe((res) => {
              if (res["status"] == 200) {
                this.fileUploadData = this.fileUploadData.filter(
                  (item) => item !== id
                );
                this.getBuyerData(this.laneTaskIDDetail.lane.id);
              }
            });
        }
      });
    } else {
      this.fileUploadData.splice(i, 1);
    }
  }
  //upload Documents in lane task
  onFileChanged(event) {
    this.isProgressVisible = true;
    if (event[0]) {
      this.file = event[0];
    } else {
      this.file = event.target.files[0];
    }
    this.fileUpload();
  }
  fileUpload(file?) {
    if (file) {
      this.file = file;
      this.file.name = file.name;
    }
    const uploadData = new FormData();
    uploadData.append("file", this.file, this.file.name);
    this._kService
      .uploadTaskDocuments(uploadData, this.laneTaskIDDetail.id)
      .subscribe((file) => {
        if (file["status"] == 200) {
          this.isProgressVisible = false;
          this.fetchUploadedTaskDocuments();
        }
      });
  }

  checkSupplierDetails() {}
  fileUploadedRes = [];
  onConfigFileChanged(event, fieldName, datatype, laneKey) {
    if (event[0]) {
      this.configFile = event[0];
    } else {
      this.configFile = event.target.files[0];
    }
    const uploadData = new FormData();
    uploadData.append("file", this.configFile, this.configFile.name);
    uploadData.append("dataType", datatype);
    uploadData.append("remarks", fieldName);
    uploadData.append("laneTaskId", this.laneTaskIDDetail.id);

    if (fieldName == "buyer_data") {
      this.pagesService.getUploadMappingHeaders(uploadData).subscribe((res) => {
        if (res["status"] == 200) {
          const dialogRef = this.dialog.open(UploadMappingComponent, {
            disableClose: true,
            maxWidth: "100vw",
            width: "100vw",
            height: "100vh",
            data: {
              fileName: this.configFile.name,
              pageName: "kanban",
              data: res,
              uploadData: uploadData,
              boardId: this.boardId,
              buyerDataConfig: this.buyerDataIndexConfig,
            },
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result == true) {
              // this._kService.uploadCofigDocuments(uploadData).subscribe(file => {
              this.fileUploadedRes = result["result"];
              //if (result['status'] == 200) {
              this._kService
                .detailsByLaneId(this.laneTaskIdByUrl)
                .subscribe((res) => {
                  this.laneTaskIDDetail = res["result"];
                  this.boardId = res["result"].boardId;
                  if (res["result"])
                    this.laneTaskConfig = res["result"]["laneWiseData"];
                  let keys = Object.keys(this.laneTaskConfig);
                  let arryKeys = [];
                  for (let i = 0; i < keys.length; i++) {
                    let obj = {
                      keys: keys[i],
                      value: this.laneTaskConfig[keys[i]],
                    };
                    arryKeys.push(obj);
                  }
                  let dataIndex = this.laneArrayKeys.findIndex((x) => {
                    return x.keys == laneKey;
                  });
                  let arrIndex = arryKeys.findIndex((x) => {
                    return x.keys == laneKey;
                  });
                  for (
                    let i = 0;
                    i < this.laneArrayKeys[dataIndex]["value"].length;
                    i++
                  ) {
                    for (
                      let j = 0;
                      j < arryKeys[arrIndex]["value"].length;
                      j++
                    ) {
                      if (
                        (this.laneArrayKeys[dataIndex]["value"][i]["type"] ==
                          "file" ||
                          this.laneArrayKeys[dataIndex]["value"][i]["type"] ==
                            "multi_upload") &&
                        this.laneArrayKeys[dataIndex]["value"][i]["field"] ==
                          fieldName &&
                        (arryKeys[arrIndex]["value"][j]["type"] == "file" ||
                          arryKeys[arrIndex]["value"][j]["type"] ==
                            "multi_upload") &&
                        arryKeys[arrIndex]["value"][j]["field"] == fieldName
                      ) {
                        this.laneArrayKeys[dataIndex]["value"][i] =
                          arryKeys[arrIndex]["value"][j];
                      }
                    }
                  }
                  this.getUploadedFileData(
                    datatype,
                    this.laneTaskIDDetail.lane.id
                  );
                });
              // }
              //});
            }
          });
        }
      });
    } else if (fieldName != "buyer_data") {
      this._kService.uploadCofigDocuments(uploadData).subscribe((file) => {
        this.fileUploadedRes = file["result"];
        if (file["status"] == 200) {
          this._kService
            .detailsByLaneId(this.laneTaskIdByUrl)
            .subscribe((res) => {
              this.laneTaskIDDetail = res["result"];
              this.boardId = res["result"].boardId;
              if (res["result"])
                this.laneTaskConfig = res["result"]["laneWiseData"];
              let keys = Object.keys(this.laneTaskConfig);
              let arryKeys = [];
              for (let i = 0; i < keys.length; i++) {
                let obj = {
                  keys: keys[i],
                  value: this.laneTaskConfig[keys[i]],
                };
                arryKeys.push(obj);
              }
              let dataIndex = this.laneArrayKeys.findIndex((x) => {
                return x.keys == laneKey;
              });
              let arrIndex = arryKeys.findIndex((x) => {
                return x.keys == laneKey;
              });
              for (
                let i = 0;
                i < this.laneArrayKeys[dataIndex]["value"].length;
                i++
              ) {
                for (let j = 0; j < arryKeys[arrIndex]["value"].length; j++) {
                  if (
                    (this.laneArrayKeys[dataIndex]["value"][i]["type"] ==
                      "file" ||
                      this.laneArrayKeys[dataIndex]["value"][i]["type"] ==
                        "multi_upload") &&
                    this.laneArrayKeys[dataIndex]["value"][i]["field"] ==
                      fieldName &&
                    (arryKeys[arrIndex]["value"][j]["type"] == "file" ||
                      arryKeys[arrIndex]["value"][j]["type"] ==
                        "multi_upload") &&
                    arryKeys[arrIndex]["value"][j]["field"] == fieldName
                  ) {
                    this.laneArrayKeys[dataIndex]["value"][i] =
                      arryKeys[arrIndex]["value"][j];
                  }
                }
              }

              this.getUploadedFileData(datatype, this.laneTaskIDDetail.lane.id);
            });
        }
      });
    }
  }

  removeConfigFile() {
    this.configFile = null;
  }

  clearStack(event) {
    event.target.value = null;
  }

  fileUploadByBuyerId(event, buyerData, type) {
    if (event[0]) {
      this.buyerConfigUploadFile = event[0];
    } else {
      this.buyerConfigUploadFile = event.target.files[0];
    }
    const uploadData = new FormData();
    uploadData.append(
      "file",
      this.buyerConfigUploadFile,
      this.buyerConfigUploadFile.name
    );
    this._kService
      .uploadBuyerDocumentsById(uploadData, buyerData.id, type)
      .subscribe((file) => {
        if (file["status"] == 200) {
          this.getBuyerData(this.laneTaskIDDetail.lane.id);
          this.toasterService.success("File uploaded Successfully");
        }
      });
  }

  autoFilldata() {
    this.sidService
      .getBuyerDetails(this.doctype, this.docNumber)
      .subscribe((res) => {
        if (res["status"] == 200) {
          this.toasterService.success("Details successfully Fetched");
          this.laneArrayKeys = this.laneArrayKeys.map((ele) => {
            if (ele.keys == "account-created-sales") {
              for (let i = 0; i < ele.value.length; i++) {
                if (ele.value[i].field == "identification_number") {
                  ele.value[i].value = res["result"].gstin;
                }
                if (ele.value[i].field == "gstin") {
                  ele.value[i].value = res["result"].gstin;
                } else if (ele.value[i].field == "incorporated_since") {
                  let incrportadDate = moment(
                    res["result"].incorporationDate,
                    "YYYY-MM-DD HH:mm:ss"
                  ).format();
                  this.incorporatedDate = incrportadDate;
                  ele.value[i].value = incrportadDate;
                }
                if (this.laneTaskIDDetail.lane.board.name == "domestic") {
                  if (ele.value[i].field == "incorporated_since_supplier") {
                    let incrportadDate = moment(
                      res["result"].incorporationDate,
                      "YYYY-MM-DD HH:mm:ss"
                    ).format();
                    ele.value[i].value = incrportadDate;
                  }
                }
              }
            }
            return ele;
          });
        }
      });
  }
  docNumberChanged() {}
  identificationType: string = "GSTIN";
  changeList(event) {
    this.docNumber = "";
    this.identificationType = event;
    this.laneArrayKeys = this.laneArrayKeys.map((ele) => {
      if (ele.keys == "account-created-sales") {
        for (let i = 0; i < ele.value.length; i++) {
          if (ele.value[i].field == "gstin") {
            ele.value[i].value = "";
          } else if (ele.value[i].field == "incorporated_since") {
            ele.value[i].value = "";
          }
        }
      }
      return ele;
    });
  }

  addBuyerDataRow() {
    let tableObj = {
      buyerName: "",
      buyerAddress: "",
      natureOfBusiness: "",
      currencyOfInvoice: "",
      limitRequired: "",
      tenorRequested: "",
      expectedNoOfInvoices: "",
      expectedTotalSellerTurnover: "",
      expectedOpenAccountTurnover: "",
      insuranceInfo: [{}],
      isnewBuyer: true,
    };
    this.fileUploadData.push(tableObj);
  }

  checkDataTypeValidation(type, value) {
    if (type == "email") {
      let pattern = /\S+@\S+\.\S+/;
      let email = pattern.test(value);
      if (email == false) {
        this.toasterService.error("Please enter the correct Email ID");
      }
    }

    if (type == "phone") {
      let patrn = "^((\\+91-?)|0)?[0-9]{10}$";
      let checkPhne = value.match(patrn);
      if (checkPhne == false) {
        this.toasterService.error("Please Enter valid Number");
      }
    }
  }
  restrictNumber(event, displayName) {
    if (displayName == 'No of Shipment'){
      const input = event.target as HTMLInputElement
      const allowedPattern = /^[0-9]$/
      const inputChar = String.fromCharCode(event.keyCode)
      const isValidChar = allowedPattern.test(inputChar)
      if (!isValidChar || input.value.length >= 6) {
        event.preventDefault();
      }
    }
    if (displayName == 'Decision Maker Name') {
      const input = event.target as HTMLInputElement
      const allowedPattern = /^[a-z A-Z]$/
      const inputChar = String.fromCharCode(event.keyCode);
      const isValidChar = allowedPattern.test(inputChar)
      if (!isValidChar || input.value.length >= 50) {
        event.preventDefault()
      }
    }
    if (displayName == 'City' || displayName == 'State' || displayName == 'District') {
      const input = event.target as HTMLInputElement
      const allowedPattern = /^[a-z A-Z]$/
      const inputChar = String.fromCharCode(event.keyCode);
      const isValidChar = allowedPattern.test(inputChar)
      if (!isValidChar || input.value.length >= 15) {
        event.preventDefault()
      }
    }
  }
  onKeyDownInput(event, type) {
    if (
      (type == "long" || type == "int" || type == "phone") &&
      event.key == "."
    ) {
      event.preventDefault();
    }
  }

  deleteCustomRow(index) {
    this.customInfo.removeAt(index);
  }
  deleteDocument(buyerId, docId) {
    this._kService.deleteBuyer(buyerId, docId).subscribe((res) => {
      if (res) {
        this.getBuyerData(this.laneTaskIDDetail.lane.id);
      }
    });
  }
  deleteTaskDocuments(id, field, dataType, laneKey) {
    if (field == "buyer_data") {
      let confObj = {
        data: "buyerData",
      };
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: true,
        width: "30%",
        height: "auto",
        data: confObj,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result == true) {
          this.fileUploadData = [];
          this.configFile = null;
          this._kService
            .deleteUploadedDocuments(
              id,
              field,
              this.laneTaskIDDetail.id,
              dataType
            )
            .subscribe((res) => {
              if (field != "") {
                this._kService
                  .detailsByLaneId(this.laneTaskIdByUrl)
                  .subscribe((res) => {
                    this.laneTaskIDDetail = res["result"];
                    this.boardId = res["result"].boardId;
                    if (res["result"])
                      this.laneTaskConfig = res["result"]["laneWiseData"];
                    let keys = Object.keys(this.laneTaskConfig);
                    let arryKeys = [];
                    for (let i = 0; i < keys.length; i++) {
                      let obj = {
                        keys: keys[i],
                        value: this.laneTaskConfig[keys[i]],
                      };
                      arryKeys.push(obj);
                    }
                    let dataIndex = this.laneArrayKeys.findIndex((x) => {
                      return x.keys == laneKey;
                    });
                    let arrIndex = arryKeys.findIndex((x) => {
                      return x.keys == laneKey;
                    });
                    for (
                      let i = 0;
                      i < this.laneArrayKeys[dataIndex]["value"].length;
                      i++
                    ) {
                      for (
                        let j = 0;
                        j < arryKeys[arrIndex]["value"].length;
                        j++
                      ) {
                        if (
                          (this.laneArrayKeys[dataIndex]["value"][i]["type"] ==
                            "file" ||
                            this.laneArrayKeys[dataIndex]["value"][i]["type"] ==
                              "multi_upload") &&
                          this.laneArrayKeys[dataIndex]["value"][i]["field"] ==
                            field &&
                          (arryKeys[arrIndex]["value"][j]["type"] == "file" ||
                            arryKeys[arrIndex]["value"][j]["type"] ==
                              "multi_upload") &&
                          arryKeys[arrIndex]["value"][j]["field"] == field
                        ) {
                          this.laneArrayKeys[dataIndex]["value"][i] =
                            arryKeys[arrIndex]["value"][j];
                        }
                      }
                    }
                  });
                this.fetchUploadedTaskDocuments();
              }
            });
        }
      });
    } else {
      this._kService
        .deleteUploadedDocuments(id, field, this.laneTaskIDDetail.id, dataType)
        .subscribe((res) => {
          if (field != "") {
            this.configFile = null;
            if (field == "buyer_data") {
              this.fileUploadData = null;
            }
            this._kService
              .detailsByLaneId(this.laneTaskIdByUrl)
              .subscribe((res) => {
                this.laneTaskIDDetail = res["result"];
                this.boardId = res["result"].boardId;
                if (res["result"])
                  this.laneTaskConfig = res["result"]["laneWiseData"];
                let keys = Object.keys(this.laneTaskConfig);
                let arryKeys = [];
                for (let i = 0; i < keys.length; i++) {
                  let obj = {
                    keys: keys[i],
                    value: this.laneTaskConfig[keys[i]],
                  };
                  arryKeys.push(obj);
                }
                let dataIndex = this.laneArrayKeys.findIndex((x) => {
                  return x.keys == laneKey;
                });
                let arrIndex = arryKeys.findIndex((x) => {
                  return x.keys == laneKey;
                });
                for (
                  let i = 0;
                  i < this.laneArrayKeys[dataIndex]["value"].length;
                  i++
                ) {
                  for (let j = 0; j < arryKeys[arrIndex]["value"].length; j++) {
                    if (
                      (this.laneArrayKeys[dataIndex]["value"][i]["type"] ==
                        "file" ||
                        this.laneArrayKeys[dataIndex]["value"][i]["type"] ==
                          "multi_upload") &&
                      this.laneArrayKeys[dataIndex]["value"][i]["field"] ==
                        field &&
                      (arryKeys[arrIndex]["value"][j]["type"] == "file" ||
                        arryKeys[arrIndex]["value"][j]["type"] ==
                          "multi_upload") &&
                      arryKeys[arrIndex]["value"][j]["field"] == field
                    ) {
                      this.laneArrayKeys[dataIndex]["value"][i] =
                        arryKeys[arrIndex]["value"][j];
                    }
                  }
                }
              });
            this.fetchUploadedTaskDocuments();
          }
          // if(field == 'ledger-document'){
          //   this.getBuyerData(this.laneTaskIDDetail.lane.id);

          // }
        });
    }
  }

  deleteSelectedTags(tag) {
    let selectedTagId: string;
    const index = this.allSelectedTagList.indexOf(tag);
    if (index >= 0) {
      this.allSelectedTagList.splice(index, 1);
    }

    for (let i = 0; i < this.allTags.length; i++) {
      if (tag == this.allTags[i].tagName) {
        selectedTagId = this.allTags[i].id;
      }
    }
    this._kService
      .removeAssignedtags(selectedTagId, this.laneTaskIDDetail.id)
      .subscribe((res) => {
        if (res["status"] == 200) {
        }
      });
  }
  deleteAssignList = [];

  deleteSelectedAssignee(AssignName) {
    if (this.selectedUserListOptions.includes(AssignName)) {
      for (let i = 0; i < this.options.length; i++) {
        if (AssignName == this.options[i].userName) {
          this.deleteAssignList.push(this.options[i].userId);
          const index = this.selectedUserListOptions.indexOf(AssignName);
          if (index >= 0) {
            this.selectedUserListOptions.splice(index, 1);
          }
        }
      }
    }
    let obj = {
      laneTaskId: this.laneTaskIDDetail.id,
      assigneeIds: this.deleteAssignList,
    };
    this._kService.removeAssignee(obj).subscribe((res) => {
      if (res["status"] == 200) {
      }
    });
  }

  deleteSelectedWatcher(watcherName) {
    if (this.fruits.includes(watcherName)) {
      for (let i = 0; i < this.options.length; i++) {
        if (watcherName == this.options[i].userName) {
          this.deleteAssignList.push(this.options[i].userId);
          const index = this.fruits.indexOf(watcherName);
          if (index >= 0) {
            this.fruits.splice(index, 1);
          }
        }
      }
    }
    let obj = {
      laneTaskId: this.laneTaskIDDetail.id,
      watcherIds: this.deleteAssignList,
    };
    this._kService.removeWatchers(obj).subscribe((res) => {
      if (res["status"] == 200) {
      }
    });
  }

  commentPageNumber: number = 0;
  totalComments: number;
  getTaskComment(save?, load?) {
    if (save) {
      this.commentPageNumber = 0;
    }
    if (load) {
      this.commentPageNumber = this.commentPageNumber + 1;
    }
    let reqObj = {
      from: this.commentPageNumber,
      size: 10,
      where: {
        "laneTask.$id": { type: "search", value: this.laneTaskIDDetail.id },
      },
    };
    this._kService.getTaskComment(reqObj).subscribe((res) => {
      this.totalComments = res["total"];
      // if (!save) {
      //   this.commentPageNumber = this.commentPageNumber + 10;
      // }
      if (this.commentPageNumber != 0) {
        this.commentlist = this.commentlist.concat(res["result"]);
      } else {
        this.commentlist = res["result"];
      }
    });
  }
  commentUserName: string;
  getCommentUserName(name) {
    if (name != null) {
      let userName = name["byUser"]["name"];
      if (userName != null && userName != "") {
        var matches = userName.match(/\b(\w)/g);
        if (matches.length > 2) {
          matches.splice(2, matches.length);
        }
        this.commentUserName = matches.join("").toUpperCase();
        return this.commentUserName;
      }
    }
  }

  setLoggedUser() {
    if (this.currentUser != null) {
      if (this.currentUser != null && this.currentUser != "") {
        var matches = this.currentUser.match(/\b(\w)/g);
        if (matches.length > 2) {
          matches.splice(2, matches.length);
        }
        this.commentUserName = matches.join("").toUpperCase();
        return this.commentUserName;
      }
    }
  }
  getHistoryUserName(name) {
    if (name != null) {
      let userName = name["user"]["name"];
      if (userName != null && userName != "") {
        var matches = userName.match(/\b(\w)/g);
        if (matches.length > 2) {
          matches.splice(2, matches.length);
        }
        this.commentUserName = matches.join("").toUpperCase();
        return this.commentUserName;
      }
    }
  }
  showSuppliers() {
    this.router.navigate(["/credlixboard"]);
  }
  saveTaskComment() {
    let obj = {
      commentId: "string",
      laneTaskId: this.laneTaskIDDetail.id,
      message: this.addTaskComment,
    };
    this._kService.addTaskComment(obj).subscribe((res) => {
      if (res["status"] == 200) {
        this.addTaskComment = "";
        this.getTaskComment("save");
      }
    });
  }
  cancelComment() {
    this.addTaskComment = "";
  }
  fetchUploadedTaskDocuments() {
    let obj = {
      where: {
        "laneTask.$id": {
          type: "search",
          value: this.laneTaskIDDetail.id,
        },
        source: {
          type: "search",
          value: "LANETASK",
        },
        isDeleted: {
          type: "search",
          value: false,
        },
      },
    };
    this._kService.searchUploadedTaskDocuments(obj).subscribe((res) => {
      this.uploadedDocumentList = res["result"];
    });
  }

  RPADocumentList = [];
  fetchRPADocument() {
    let obj = {
      where: {
        "laneTask.$id": {
          type: "search",
          value: this.laneTaskIDDetail.id,
        },
        source: {
          type: "search",
          value: "RPA",
        },
        isDeleted: {
          type: "search",
          value: false,
        },
      },
    };
    this._kService.searchUploadedTaskDocuments(obj).subscribe((res) => {
      this.RPADocumentList = res["result"];
    });
  }

  getCountryList() {
    this._kService.getCountryList().subscribe((res) => {
      if (res["result"] != null && res["result"].length > 0) {
        this.countryList = res["result"];
      }
    });
  }

  buyerDataCountryList = [];
  fetchCountryList() {
    this._kService.CountryList().subscribe((res) => {
      this.buyerDataCountryList = res["result"];
    });
  }
  getSelectedCountry(event, value) {
  }
  buyerRegionList = [];
  getmultipleBuyerRegion(event) {
    this.buyerRegionList = event;
  }

  getstateName(event) {
    this.statelLocation = event;
  }

  deleteBuyerRegion(region, i) {
    this.buyerRegionList = this.buyerRegionList.filter((e) => e !== region);
    this.laneArrayKeys = this.laneArrayKeys.map((ele) => {
      if (ele.keys == "new-leads" || ele.keys == "account-created-sales") {
        for (let i = 0; i < ele.value.length; i++) {
          if (ele.value[i].field == "buyer_region") {
            if (
              ele.value[i]["dataList"] != null &&
              ele.value[i]["dataList"].length != 0
            ) {
              ele.value[i]["dataList"] = this.buyerRegionList;
            }
          }
        }
      }
      return ele;
    });
  }

  removeSelectedRegion(i) {
    this.buyerRegionList.splice(i);
  }

  changeConfigDate(event, value) {
  }
  changePlantVisit(event, value) {}

  formatPaymentType(value) {
    return this._.startCase(value);
  }
  onConfigCheckboxChange(isChekboxCheckd) {
  }
  resultDownload;
  downloadSingleDocument(id) {
    this._kService.downloadSingleUploadDocument(id).subscribe((res) => {
      this.downloadSheetCommonMethod(res);
    });
  }

  downloadUIGeneratedSingleDocument(file) {
    if (file.documents && file.documents.id) {
      this.downloadSingleDocument(file.documents.id);
    } else if (file.buyerDataList && file.buyerDataList.length > 0) {
      this.isVisible = true;
      this.buyerCurrencyBaseTable_termsheet(file.buyerDataList);
      this.captureAndDownloadPDF(file);
    }
  }

  downloadDocumentsInZip() {
    this._kService
      .downloadZipDocumentsByLaneId(this.laneTaskIDDetail.id)
      .subscribe((res) => {
        this.downloadSheetCommonMethod(res);
      });
  }

  downloadSheetCommonMethod(res) {
    var contentDispositionHeader = res.headers.get("content-disposition");
    this.resultDownload = contentDispositionHeader
      .split(";")[1]
      .trim()
      .split("=")[1];
    let fileName = this.resultDownload.replace(/"/g, "");
    saveAs(res.body, fileName);
    return fileName;
  }

  getOwnerShipList = [];
  getCurrencyList = [];
  getIndustries = [];
  getStates = [];
  getcountryList = [];
  getStateList = [];
  locationList = [];
  bussinessModel = [];
  getEximBuyerIndustry = [];
  getInsuranceList = [];
  getColdReasonList = [];
  getDropdownData() {
    this._kService.getDropdowndata().subscribe((res) => {
      this.getcountryList = res["countryList"];
      // this.getCurrencyList = res['currencies'];
      this.totalCurrencyList = res["currencies"];
      this.getCurrencyList = ["USD", "EUR", "GBP"];
      this.getIndustries = res["industries"];
      this.getStates = res["states_india"];
      this.getInsuranceList = [];
      res["insurance"].forEach((i) => this.getInsuranceList.push(i));
      this.getInsuranceList.push("Others");
      this.getOwnerShipList = res["type_of_ownership"];
      this.getColdReasonList = res["cold-lead-reasons"];
      this.getOwnerShipList.push("Others");
      this.getEximBuyerIndustry = res["buyer_industries"];
      this.getEximBuyerIndustry.push("Others");
      this.bussinessModel = ["Manufacturing", "Trading", "Service", "Others"];
      this.mexicoStateList = res["states_mexico"];
      this.getStateList = [
        "Andaman and Nicobar Islands",
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chandigarh",
        "Chhattisgarh",
        "Dadra and Nagar Haveli and Daman and Diu",
        "Delhi",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Ladakh",
        "Lakshadweep",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Puducherry",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal",
      ];
      this.locationList = ["Tier 1", "Tier 2", "Tier 3"];
    });
  }

  storeUploadedBuyerData = [];
  getUploadedFileData(datatype, laneId?) {
    if (datatype == "config") {
      this.getBuyerData(laneId);
    }
  }

  // handleInsuranceData(){
  //   const insuranceProviders: any[] = [];
  //   // Iterate through the outer array
  //   for (let i = 0; i < this.fileUploadData.length; i++) {
  //     const insuranceInfoArray = this.fileUploadData[i]['insuranceInfo'];

  //     // Iterate through the inner insuranceInfo array
  //     for (let j = 0; j < insuranceInfoArray.length; j++) {
  //       const insuranceProvider = insuranceInfoArray[j]['insuranceProvider'];

  //       // Check if the insuranceProvider is not null and not already in the array
  //       if (insuranceProvider && !insuranceProviders.includes(insuranceProvider)) {
  //         insuranceProviders.push({
  //             key : insuranceProvider,
  //             value:true
  //         });
  //         this.getInsuranceList = this.getInsuranceList.filter(insurance => insuranceProviders.includes(insurance));
  //       }
  //     }
  //   }
  // }
  getBuyerData(laneId) {
    this._kService
      .getBuyerData(this.laneTaskIDDetail.id, laneId)
      .subscribe((res) => {
        if (res["status"] == 200) {
          this.fileUploadData = res["result"];
          this.noOfBuyers = res["size"];
          // this.handleInsuranceData();
          this.fileUploadData = this.fileUploadData.map((val) => {
            val["formattedLimitRequired"] = val.limitRequired
              ? this.formatAmount(val.limitRequired.toString())
              : null;
            val["formattedLimitRecommended"] = val.limitRecommended
              ? this.formatAmount(val.limitRecommended.toString())
              : null;
            val["formattedMax_limit"] = val.max_limit
              ? this.formatAmount(val.max_limit.toString())
              : null;
            val["formattedRequestedLimit"] = val.requestedLimit
              ? this.formatAmount(val.requestedLimit.toString())
              : null;
            val["formattedApprovedLimit"] = val.approvedLimit
              ? this.formatAmount(val.approvedLimit.toString())
              : null;
            val["formattedTotalLimitAmount"] = val.totalLimitAmount
              ? this.formatAmount(val.totalLimitAmount.toString())
              : null;
            val["formattedExpectedTotalSellerTurnover"] =
              val.expectedTotalSellerTurnover
                ? this.formatAmount(val.expectedTotalSellerTurnover.toString())
                : null;
            val["formattedExpectedOpenAccountTurnover"] =
              val.expectedOpenAccountTurnover
                ? this.formatAmount(val.expectedOpenAccountTurnover.toString())
                : null;
            if (val["insuranceInfo"] == null) {
              val["insuranceInfo"] = [];
            }
            if (
              val["currencyOfInvoice"] != null ||
              val["natureOfBusiness"] != null
            ) {
              val["currencyOfInvoice"] = val["currencyOfInvoice"]
                ? val["currencyOfInvoice"].toUpperCase()
                : null;
              val["natureOfBusiness"] = val["natureOfBusiness"]
                ? val["natureOfBusiness"].toUpperCase()
                : "";
            }
            if (this.laneTaskIDDetail.lane.board.name == "exim") {
              this.getEximBuyerIndustry.map((ele) => {
                if (val["buyerIndustry"] != ele) {
                  this.eximBuyerInd = val["buyerIndustry"];
                  val["buyerIndustry"] = "Others";
                }
              });
            }
            if (val) return val;
          });
          this.updateInsuranceHeaders();
          if(["document-collection-submission","underwriting"].includes(this.laneTaskIDDetail.lane.name)){
            this.getScoreCardDetailsById(this.laneTaskIdByUrl);     // check scorecard data is filled or not
          }
        }
      });
  }

  chnageLimitrequiredInput(event: any, fileData, index) {
    let limitrequired = event.target.value;
    fileData[index]
      ? (fileData[index].limitRequired = limitrequired.replace(/\D/g, ""))
      : (fileData.limitRequired = limitrequired.replace(/\D/g, ""));
  }

  chnageRecommendedInput(event: any, fileData, index) {
    let limitRecommended = event.target.value;
    if (fileData[index]) {
      fileData[index].limitRecommended = limitRecommended.replace(/\D/g, "");
    } else {
      fileData.limitRecommended = limitRecommended.replace(/\D/g, "");
    }
  }

  chnageMax_limitInput(event: any, fileData, index) {
    let max_limit = event.target.value;
    fileData[index]
      ? (fileData[index].max_limit = max_limit.replace(/\D/g, ""))
      : (fileData.max_limit = max_limit.replace(/\D/g, ""));
  }

  chnageRequestedLimitInput(event: any, fileData, index) {
    let requestedLimit = event.target.value;
    fileData[index]
      ? (fileData[index].max_limit = requestedLimit.replace(/\D/g, ""))
      : (fileData.requestedLimit = requestedLimit.replace(/\D/g, ""));
  }

  chnageApprovedLimitInput(event: any, fileData, index) {
    let approvedLimit = event.target.value;
    fileData[index]
      ? (fileData[index].approvedLimit = approvedLimit.replace(/\D/g, ""))
      : (fileData.approvedLimit = approvedLimit.replace(/\D/g, ""));
  }

  chnageformattedTotalLimitAmountInput(event: any, fileData, index) {
    let totalLimitAmount = event.target.value;
    fileData[index]
      ? (fileData[index].totalLimitAmount = totalLimitAmount.replace(/\D/g, ""))
      : (fileData.totalLimitAmount = totalLimitAmount.replace(/\D/g, ""));
  }

  chnageExpectedTotalSellerTurnoverInput(event: any, fileData, index) {
    let expectedTotalSellerTurnover = event.target.value;
    fileData[index]
      ? (fileData[index].expectedTotalSellerTurnover =
          expectedTotalSellerTurnover.replace(/\D/g, ""))
      : (fileData.expectedTotalSellerTurnover =
          expectedTotalSellerTurnover.replace(/\D/g, ""));
  }

  chnageExpectedOpenAccountTurnoverInput(event: any, fileData, index) {
    let expectedOpenAccountTurnover = event.target.value;
    fileData[index]
      ? (fileData[index].expectedOpenAccountTurnover =
          expectedOpenAccountTurnover.replace(/\D/g, ""))
      : (fileData.expectedOpenAccountTurnover =
          expectedOpenAccountTurnover.replace(/\D/g, ""));
  }

  insuranceInfo = [];
  addInsurance(event) {
    let obj = {
      insuranceProvider: event,
      insuranceDataPresent: false,
      finalDataPresent: false,
      ignoreBeforeEligibility: true,
    };
    for (let i = 0; i < this.fileUploadData.length; i++) {
      let clone = JSON.parse(JSON.stringify(obj));
      this.fileUploadData[i]["insuranceInfo"].push(clone);
      this.updateInsuranceHeaders();
      // setTimeout(() => {
      //   this.renderer.selectRootElement('#newInsurance').focus();
      // }, 300)
    }
  }

  addBulkInsuranceList(event, selectedValue) {
    if (event.checked) {
      this.selectedInsuranceSet.add(selectedValue);
    } else {
      this.selectedInsuranceSet.delete(selectedValue);
    }
  }

  addBulkInsurance() {
    this.selectedInsuranceArray = Array.from(this.selectedInsuranceSet);
    this.selectedInsuranceArray.forEach((element) => {
      if (this.validInsurance(element)) {
        this.onInsuranceCompany(element);
        this.updateInsuranceHeaders();
      } else {
        this.toasterService.error("Insurer Already Added");
      }
    });
  }

  validInsurance(insurer) {
    if (this.insuranceProviders.includes(insurer)) {
      return false;
    }
    this.allInsuranceInfo = [];
    // Iterate through the insurancedata array
    for (const buyer of this.fileUploadData) {
      // Check if the buyer has insuranceInfo
      if (buyer.insuranceInfo && Array.isArray(buyer.insuranceInfo)) {
        // Push each insurance object to the allInsuranceInfo array
        for (const insurance of buyer.insuranceInfo) {
          insurance.buyerName = buyer.buyerName;
          this.allInsuranceInfo.push(insurance);
        }
      }
    }
    this.error = 0;
    for (let i = 0; i < this.allInsuranceInfo.length; i++) {
      if (this.allInsuranceInfo[i].insuranceProvider != insurer) {
      } else {
        this.error = this.error + 1;
      }
    }
    if (this.error > 0) {
      return false;
    } else {
      return true;
    }
  }

  openFilledInsureanceData() {
    this.dialog.open(InsuranceDataListComponent, {
      disableClose: false,
      width: "90%",
      height: "70%",
      data: {
        obj: this.fileUploadData,
      },
    });
  }

  onInsuranceCompany(selectedValue) {
    if (selectedValue != "Others") {
      this.selectedInsurerName = selectedValue;
      this.addInsurance(selectedValue);
    } else if (selectedValue == "Others") {
      const dialogRef = this.dialog.open(FillInsuranceComponent, {
        disableClose: true,
        width: "55%",
        height: "50%",
        data: {
          isOtherInsurance: true,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          result.insList.forEach((val) => {
            if (val.checked && this.validInsurance(val.insurerName)) {
              this.addInsurance(val.insurerName);
            }
          });
        }
      });
    }
  }

  openInsuredComapnyDataPopup(obj, columnIndex, rowIndex) {
    let isVisible = this.laneTaskIDDetail.lane.name == "eligiblity-check";
    let tenorRequested = this.fileUploadData[rowIndex].tenorRequested;
    let tenorFrom = this.fileUploadData[rowIndex].tenorFrom;
    const limitRequired = this.fileUploadData[rowIndex].limitRequired;
    const dialogRef = this.dialog.open(FillInsuranceComponent, {
      disableClose: false,
      width: "55%",
      height: "900px",
      panelClass: "insurancePopupWrap",
      data: {
        obj: obj,
        isVisible,
        tenorRequested,
        tenorFrom,
        limitRequired,
      },
    });

    dialogRef.componentInstance.onSave = (obj) => {
      this.fileUploadData[rowIndex]["insuranceInfo"][columnIndex] = obj;
      this.fileUploadData[rowIndex].buyerName = obj.finalChangeInNameOfBuyer
        ? obj.finalChangeInNameOfBuyer
        : this.fileUploadData[rowIndex].buyerName;
      this.fileUploadData[rowIndex].buyerAddress =
        obj.finalChangeInAddressOfBuyer
          ? obj.finalChangeInAddressOfBuyer
          : this.fileUploadData[rowIndex].buyerAddress;
      this.SubmitForms();
    };
  }

  chnageCurrency(event: any) {
    let currency = event.target.value;
    event.target.value = currency.toUpperCase();
  }

  validateCurrencyOfInvoice(event: any) {
    let currency = event.option ? event.option.value : event.target.value;
    if (!currency || !this.totalCurrencyList.includes(currency)) {
      if (event && event.target && event.target.value) {
        event.target.value = null;
        alert("selected Currency is not Valid");
      } else if (event && event.option && event.option.value) {
        event.option.value = null;
        alert("selected Currency is not Valid");
      }
    }
  }
  deleteInsuranceDetails(index) {
    for (let i = 0; i < this.fileUploadData.length; i++) {
      this.fileUploadData[i]["insuranceInfo"].splice(index, 1);
    }
    this.updateInsuranceHeaders();
  }

  getDynamicDropdownList(fieldName) {
    if (fieldName == "monthly_shipments_currency") {
      return this.getCurrencyList;
    } else if (
      fieldName == "type_of_ownership" ||
      fieldName == "type_of_company" ||
      fieldName == "constitution"
    ) {
      return this.getOwnerShipList;
    } else if (fieldName == "type_of_supplier") {
      return this.supplierList;
    } else if (fieldName == "plant_visit_done") {
      return this.plantVisit;
    } else if (fieldName == "credit_memo") {
      return this.creditMemo;
    } else if (
      fieldName == "seller_industry" ||
      fieldName == "buyer_industry"
    ) {
      return this.getIndustries;
    } else if (fieldName == "country") {
      return this.getcountryList;
    } else if (fieldName == "state") {
      if (this.laneTaskIDDetail.businessCountry == "MEXICO") {
        return this.mexicoStateList;
      } else {
        return this.getStateList;
      }
    } else if (fieldName == "location") {
      return this.locationList;
    } else if (fieldName == "business_model") {
      return this.bussinessModel;
    }
  }

  getMultipleSelectionList(fieldName) {
    if (fieldName == "buyer_region") {
      return this.countryList;
    } else if (fieldName == "reason_to_move") {
      return this.getColdReasonList;
    }
  }
  getCardDetailsTabChange(event) {
    if (event.index == 1) {
      // this.getLaneTaskHistory()
    } else if (event.index == 0) {
      this.getTaskComment();
    }
  }
  getLeadContactDetails = (lead) => {
    var contact = {
      email: null,
      phone: null,
    };
    if (lead["data"] != null && lead["data"].length > 0) {
      lead["data"].find((element) => {
        if (element["field"] == "contact_details") {
          if (
            element["operationsData"] != null &&
            element["operationsData"]["CONTACT_DETAILS"] != null
          ) {
            let contactDetails = element["operationsData"]["CONTACT_DETAILS"];
            if (contactDetails != null && contactDetails.length > 0) {
              for (let k = 0; k < contactDetails.length; k++) {
                let ele = contactDetails[k];
                if (contact["email"] != null && contact["phone"] != null) {
                  break;
                }
                if (
                  ele["phone"] != null &&
                  ele["isPrimary"] &&
                  contact["phone"] == null
                ) {
                  contact["phone"] = ele["phone"];
                }
                if (
                  ele["email"] != null &&
                  ele["isPrimary"] &&
                  contact["email"] == null
                ) {
                  contact["email"] = ele["email"];
                }
              }
            }
            if (contact["email"] == null && contact["phone"] == null) {
              contact["phone"] =
                element.operationsData.CONTACT_DETAILS[0]["phone"];
              contact["email"] =
                element.operationsData.CONTACT_DETAILS[0]["email"];
              return contact;
            }
          }
        }
      });
    }
    return contact;
  };
  updateFormattedAmount() {
    // Remove non-digit characters from the input value
    const value = this.shipmentAmount.replace(/\D/g, "");
    // Format the value with commas
    this.formattedAmount = this.formatAmount(value);
  }
  onStatusChange(event) {
    let obj = {
      id: this.laneTaskIDDetail.id,
      laneId: this.laneTaskIDDetail.lane.id,
      toLaneID: this.toLaneId,
      whatsappActive: event.checked,
    };
    this._kService.updateLaneTask(obj).subscribe((res) => {
      if (res["status"] == 201) {
        event.checked == true
          ? this.toasterService.success("Whats App Notification is Activated")
          : this.toasterService.success(
              "Whats App Notification is Deactivated"
            );
      }
    });
  }

  updateFormattedSetUpFeeAmount() {
    // Remove non-digit characters from the input value
    if (this.setUpFeeAmount) {
      const value = this.setUpFeeAmount.toString().replace(/\D/g, "");
      // Format the value with commas
      this.formattedSetUpFeeAmount = this.formatAmount(value);
    }
  }

  updateformattedRemittanceAmount() {
    if (this.remittanceAmount) {
      const value = this.remittanceAmount.toString().replace(/\D/g, "");
      // Format the value with commas
      this.formattedRemittanceAmount = this.formatAmount(value);
    }
  }

  public formatAmount(value: string): string {
    // Format the value with commas
    if (value && value.length > 0) {
      return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return "";
    }
  }

  changeInput(event) {
    let shimpmentAmount = event.target.value;
    this.shipmentAmount = shimpmentAmount.replace(/\D/g, "");
  }
  chnageSetUpFee(event) {
    let setUpFeeAmount = event.target.value;
    this.setUpFeeAmount = setUpFeeAmount.replace(/\D/g, "");
  }
  chnageRemittanceAmount(event) {
    let remittanceAmount = event.target.value;
    this.remittanceAmount = remittanceAmount.replace(/\D/g, "");
  }

  updateInsurerInBuyerInsurerInfo(insuranceProviders) {
    this.fileUploadData.forEach((buyer) => {
      let updatedInsuranceInfo = [];
      buyer.insuranceInfo.forEach((insuranceInfo, index) => {
        if (
          insuranceProviders[index] &&
          insuranceInfo.insuranceProvider != insuranceProviders[index]
        ) {
          const insurerIndex = insuranceProviders.indexOf(
            insuranceInfo.insuranceProvider
          );
          if (insurerIndex >= 0) {
            updatedInsuranceInfo[insurerIndex] = insuranceInfo;
          }
        } else {
          updatedInsuranceInfo[index] = insuranceInfo;
        }
      });
      this.updateMisingInsuranceProviderInfo(
        insuranceProviders,
        updatedInsuranceInfo
      );
      buyer.insuranceInfo = updatedInsuranceInfo;
      if (
        buyer.insuranceInfo &&
        insuranceProviders &&
        buyer.insuranceInfo.length > insuranceProviders.length
      ) {
        buyer.insuranceInfo.splice(
          insuranceProviders.length,
          buyer.insuranceInfo.length - insuranceProviders.length
        );
      }
    });
  }

  updateMisingInsuranceProviderInfo(insuranceProvider, updatedInsuranceInfo) {
    if (insuranceProvider && insuranceProvider.length > 0) {
      insuranceProvider.forEach((insurer, index) => {
        if (!updatedInsuranceInfo[index]) {
          updatedInsuranceInfo[index] = {
            insuranceProvider: insurer,
            insuranceDataPresent: false,
            finalDataPresent: false,
            ignoreBeforeEligibility: true,
          };
        }
      });
    }
  }

  get anyBuyerHasInsurerInfo() {
    for (let buyer of this.fileUploadData) {
      if (!buyer.insuranceInfo) continue;
      for (let insuranceInfo of buyer.insuranceInfo) {
        if (insuranceInfo && insuranceInfo.ignoreBeforeEligibility != true) {
          return true;
        }
      }
    }
    return false;
  }
  insuranceProviders = [];
  updateInsuranceHeaders() {
    this.insuranceProviders = [];
    if (this.fileUploadData && this.fileUploadData.length > 0) {
      this.fileUploadData.forEach((buyer) => {
        buyer.insuranceInfo.forEach((insuranceInfo) => {
          if (
            insuranceInfo.insuranceProvider &&
            insuranceInfo.insuranceProvider.trim() != "" &&
            !this.insuranceProviders.includes(insuranceInfo.insuranceProvider)
          ) {
            this.insuranceProviders.push(insuranceInfo.insuranceProvider);
          }
        });
      });
    }
    if (
      this.laneTaskIDDetail.lane.name == "eligiblity-check" &&
      this.currentTabName == "eligiblity-check"
    )
      this.updateInsurerInBuyerInsurerInfo(this.insuranceProviders);
    return this.insuranceProviders;
  }

  get disableInsurerInputBox() {
    if (
      this.laneTaskIDDetail.lane.name != "account-created-sales" &&
      this.laneTaskIDDetail.lane.name != "pre-risk"
    ) {
      return true;
    } else {
      if (
        this.laneTaskIDDetail.lane.name === "pre-risk" &&
        this.currentTabName === "account-created-sales"
      ) {
        return true;
      }
    }
    return false;
  }

  termsheetData: any;
  bindDataIntoDocumentHtml(termsheetData) {
    this.termsheetData = null;
    this.termsheetData = termsheetData;
    this.laneSortName = this.getInitials(termsheetData.laneTask.name);
  }

  getImgHeight() {
    let imgHeight;
    if (this.termsheetData.buyerDataList.length == 1) {
      imgHeight = 890;
    } else {
      imgHeight = 1182.5038520801234;
    }
    return imgHeight;
  }

  isVisible: any = false;
  public captureAndDownloadPDF(termsheetData) {
    this.bindDataIntoDocumentHtml(termsheetData);
    const element = document.getElementById("contentToConvert")
      ? document.getElementById("contentToConvert")
      : null; // Your content container
    if (!element) {
      this.isVisible = true;
      setTimeout(() => {
        this.captureAndDownloadPDF(termsheetData);
      }, 500);
    }

    this.puppeteerService.generatePdf(element.innerHTML).subscribe((res) => {
      let blob = new Blob([res.body], {
        type: res.headers.get("Content-Type"),
      });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      // const fileExtensionSplit = res.headers.get("Content-Disposition").split(".");
      // const fileExtension = fileExtensionSplit[fileExtensionSplit.length - 1];
      link.download = termsheetData.laneTask.name + "_ts" + ".pdf";
      link.click();
      window.URL.revokeObjectURL(link.href);
      this.getPrepareTermSheetData();
      this.getLaneDetailsById(this.laneTaskIdByUrl);
    });
    this.showPdfContent = false;
    this.isVisible = false;
  }

  get screenWidth() {
    return window.screen.width;
  }

  getTotalLimitAmout() {
    let totalLimitAmount: any = 0;
    this.termsheetData.buyerDataList.forEach((element) => {
      totalLimitAmount = totalLimitAmount + element.totalLimitAmount;
    });
    return totalLimitAmount;
  }

  getInitials(name: string) {
    if (typeof name !== "string") {
      return "";
    }
    const words = name.split(" ");
    const initials = words.map((word) => word[0]);
    return initials.join("");
  }
  formatYear(): string {
    let currentYear = new Date().getFullYear();
    const currentYearStr = currentYear.toString();
    const nextYear = currentYear + 1;
    const nextYearStr = nextYear.toString().slice(-2);
    return `${currentYearStr}-${nextYearStr}`;
  }
  setTime(date) {
    return moment(date).format("dddd, MMMM Do YYYY, h:mm:ss a");
  }

  getLeadAddress(laneTask) {
    let city: any = Array.from(laneTask).filter(
      (lane) => lane["field"] == "City"
    )[0];
    let state: any = Array.from(laneTask).filter(
      (lane) => lane["field"] == "state"
    )[0];
    let pinCode: any = Array.from(laneTask).filter(
      (lane) => lane["field"] == "pinCode"
    )[0];
    let finalAddress = city.value + "," + state.value + "," + pinCode.value;
    return finalAddress;
  }

  fetchGstNumber() {
    let gstFields =
      this.laneTaskIDDetail.laneWiseData["account-created-sales"][0];
    if (gstFields.field == "identification_number") {
      return gstFields.value;
    }
  }

  downloadEximBuyerTemplate() {
    this._kService.downloadEximBuyerTemplate().subscribe((res) => {
      var contentDispositionHeader = res.headers.get("content-disposition");
      let result = contentDispositionHeader.split(";")[1].trim().split("=")[1];
      result = result.replace(/"/g, "");
      saveAs(res.body, result);
      return result.replace(/"/g, "");
    });
  }
  openScoreCard(lane){
    let isEditable;
    this.selectedBoardLane.forEach(val => {
      if(val.name == "document-collection-submission" || val.name == "underwriting"){
        isEditable = val.isEditable;
      }
    });
    let obj = {
      laneId : this.laneTaskIdByUrl,
      buyerList  : this.fileUploadData,
      buyerSettings : this.buyerSettings,
      tradeRelationSettings : this.tradeRelationSettings, 
      supplierArrayList: this.supplierArrayList,
      isEditable : isEditable,
      laneDetails : lane
    }
    const dialogRef = this.dialog.open(ScoreCardComponent,{
      disableClose: false,
      maxWidth: '100vw',
      maxHeight: '90vh',
      height: '100%',
      width: '100%',
      panelClass: 'scoreCardPopup',
      data : obj
    });
    dialogRef.afterClosed().subscribe(result =>{
      console.log(result);
      this.getScoreCardDetailsById(this.laneTaskIdByUrl);
    })
  }

  getScoreCardFields(){
    this._kService.getDefaultSetting().subscribe(res =>{
      let data = res['result'];
      this.supplierArrayList = data.supplier;
      this.buyerSettings = data.buyer;
      this.tradeRelationSettings = data.tradeRelationship;
    })
  }
  getScoreCardDetailsById(id) {     // lane Movement Disable method
    this._kService.getSupplierScore(id).subscribe(res => {
      if (res['result'] && res['result'][0]) {
        let data = res['result'][0];
        if (data && data.buyerDetails) {
          this.scoreCardBuyerList = data.buyerDetails;
          this.checkLaneMovementByScoreCard(this.fileUploadData, this.scoreCardBuyerList);
        }
        if (data && data.scoreCount && data.scoreCount.supplierScore) {
          this.supplierCount = data.scoreCount.supplierScore;
          this.isSupplierDetailsAvalible = Number(this.supplierCount) <= 0 ? false : true;
        }
       else {
        this.isSupplierDetailsAvalible = true;
        this.freezedLaneMovement = true;
      }
    }
    else{
        this.isSupplierDetailsAvalible = true;
        this.freezedLaneMovement = true;
    }
    });
  }

  freezedLaneMovement : boolean = false;
  checkLaneMovementByScoreCard(laneBuyerList, scoreCardBuyerList){
    if(scoreCardBuyerList.length > 0 && laneBuyerList.length === scoreCardBuyerList.length){
      const isFreezed = this.checkAllDataFilledInScoreCard(scoreCardBuyerList);
      this.freezedLaneMovement = isFreezed;
    } else {
      this.freezedLaneMovement = true;
    }
  }
  checkAllDataFilledInScoreCard(scoreCardBuyerDataList){
    return scoreCardBuyerDataList.every(val => val.buyerScore && val.buyerScore <= 0);
  }
  getDisabledMessage(lane){
    if(lane.forwardMove == true && this.freezedLaneMovement && this.isSupplierDetailsAvalible){
      return 'Please fill all details on score card to enable forward lane movement' 
    } else {
      return ''
    }
  }
  setSalesAccessDisable(){
    let obj = {
      supplierId : this.laneTaskIdByUrl,
      isEditDisable : true
    }
    this._kService.salesEditDisable(obj).subscribe(res =>{
      console.log('Access Lane for Sales Team',res);
    });
  }
  /**
 * Navigates to the GST report tab by setting the appropriate index.
 */
  routeToGSTReport() {
    // Set the tab index to point to the GST report tab
    this.demo1TabIndex = 4;
  }

  /**
   * Restricts input fields to only allow alphanumeric characters and certain special characters
   * (&, (, ), -, _, and ,).
   *
   * @param event The input event.
   */
  restrictSpecificChars(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const regex = /^[a-zA-Z0-9&()\-_,]*$/; // Allow a-z, A-Z, 0-9, &, (, ), -, _, and ,
    const value = inputElement.value;
    // Remove invalid characters
    inputElement.value = value.split('').filter(char => regex.test(char)).join('');
  }

  checkPercantage(event){
    let value = event.target.value;
    if(value > 100){
      event.target.value = null;
      this.toasterService.error("Percentage should be less than or Equal to 100");
      return false;
    }
    else{
      return true;
    }
  }
}
