import { Component, OnInit, Inject } from '@angular/core';
import { KanbanService } from '../../kanban.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as lodash from 'lodash';


@Component({
  selector: 'app-add-new-lead',
  templateUrl: './add-new-lead.component.html',
  styleUrls: ['./add-new-lead.component.scss']
})
export class AddNewLeadComponent implements OnInit {
  public keyValueForm: FormGroup;
  boardLane = [];
  userList = [];
  laneTaskConfig = [];
  selectedLane: string;
  fetchLaneConfigById: string;
  selectedUserList = [];
  leadName: string;
  countryList = [];
  selectedBoardName: string
  _: any = lodash;
  customCountry: string;
  isIndiaSelected: boolean = true;
  isMexicoSelected: boolean = false;
  create:boolean=false;

  constructor(public _kService: KanbanService, public toasterService: ToastrService, private formBuilder: FormBuilder, public dialogRef: MatDialogRef<AddNewLeadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(data)
    const items = [];
    this.keyValueForm = this.formBuilder.group({
      details: this.formBuilder.array(items)
    });
    this.selectedBoardName = data.boardName
  }

  ngOnInit() {
    //this.fetchBoardLane();
  }

  fetchBoardLane() {
    this._kService.getLaneWithUserAccess(this.data.boardId, '').subscribe(res => {
      for (let i = 0; i < res['result']['levelAccess'].length; i++) {
        if (res['result']['levelAccess'][i].laneDTO.position == 1) {
          this.boardLane.push(res['result']['levelAccess'][i].laneDTO);
          this.selectedLane = res['result']['levelAccess'][i].laneDTO.name;
          this.fetchLaneConfigById = res['result']['levelAccess'][i].laneDTO.id;
        }
      }
      this.getLaneCofigFields();
    })
  }

  leadCountryChange(countryName) {
    if (countryName == "INDIA") {
      this.isIndiaSelected = true;
      this.isMexicoSelected = false;
    }
    else if (countryName == "MEXICO") {
      this.isIndiaSelected = false;
      this.isMexicoSelected = true;
    }
  }
  getLaneCofigFields() {
    this._kService.getLaneConfigById(this.fetchLaneConfigById).subscribe(res => {
      if (res['status'] == 200) {
        this.laneTaskConfig = res['result']['config'];
      }
    });
  }

  getselectedUserId(ids) {
    this.selectedUserList = ids;
  }

  customInfo
  addCustomFields() {
    this.customInfo = this.keyValueForm.get('details') as FormArray;
    this.customInfo.push(this.createItem());
  }
  createItem(): FormGroup {
    return this.formBuilder.group({
      key: [],
      value: []
    });
  }

  deleteCustomRow(index) {
    this.customInfo.removeAt(index)
  }
  shipmentAmount;
  shipmentCurrency;
  createNewLead() {
    this.create = true;
    if (this.leadName == null || this.leadName.trim() == '') {
      this.toasterService.error("Please Enter Lead name");
      this.leadName = null;
      return;
    }

    let obj =
    {
      "laneId": this.data.boardId,
      "name": this.leadName.trim(),
      "pinned": true,
      "leadSource": "SYSTEM",
    }
    if (this.selectedBoardName == 'exim') {
      obj['businessCountry'] = this.isIndiaSelected ? "INDIA" : "MEXICO";
    }
    this._kService.createLaneTask(obj).subscribe({
      next:(res) => {
      this.toasterService.success("New Lead created Successfully");
      this.openCardDetails(res['result'].id)
      this.dialogRef.close(true);
    },
    complete:() => this.create = false
  }
  );
  }
  cancelDialog() {
    this.dialogRef.close();
  }

  openCardDetails(task) {
    // task['boardId'] = this.boardId
    this.boardLane.map(ele => {
      if (ele.laneDTO.id == task.laneId) {
        task['isEditAccess'] = ele.laneDTO.isEditAccess
      }
    })
    // window.open('/#/cardDetails/' + task.id, "_blank");
    if(this.data.boardType == 'EXIM'){
    window.location.href = '/#/cardDetails/' + task;}
  else{
    window.location.href = '/#/lead-details/new-leads/' + task;
  }
  }

  getmultipleBuyerRegion(event) {

  }
  matchCurrentUser() {
    let currentUserID;
    let getSessionStorage = JSON.parse(localStorage.getItem("USERINFO"));
    if (getSessionStorage && getSessionStorage['role']) {
      currentUserID = getSessionStorage['role']['userId'];
    }
    this.userList.map(data => {
      if (data['userId'] == currentUserID) {
        this.selectedUserList = [];
        this.selectedUserList.push(currentUserID);
      }
    })
  }
  getOwnerShipList = [];
  getCurrencyList = [];
  getIndustries = [];
  getDropdownData() {
    this._kService.getDropdowndata().subscribe(res => {
      this.getCurrencyList = res['currencies'];
      this.getIndustries = res['industries'];
      this.getOwnerShipList = res['type_of_ownership'];
      this.getOwnerShipList.push('Others');
    });
  }
  restrictSpecificChars(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const regex = /^[a-zA-Z0-9&()\-_, ]*$/;// Allow a-z, A-Z, 0-9, &, (, ), -, _, and ,
    const value = inputElement.value;

    // Remove invalid characters
    inputElement.value = value.split('').filter(char => regex.test(char)).join('');
  }
}
