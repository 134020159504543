import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appNumberValidation]',
})
export class NumberValidationDirective {
    private allowedChars = /^[0-9]*$/; // Regex allows only digits (0-9)

    constructor(private el: ElementRef) { }

    @HostListener('input', ['$event']) onInputChange(event: Event): void {
        const inputElement = this.el.nativeElement as HTMLInputElement;

        // Replace any non-digit character with an empty string
        const sanitizedValue = inputElement.value.replace(/[^0-9]/g, '');

        // Update input value only if it has changed
        if (sanitizedValue !== inputElement.value) {
            inputElement.value = sanitizedValue;
            // Trigger Angular's change detection
            inputElement.dispatchEvent(new Event('input'));
        }
    }

    @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent): void {
        // Prevent keypress for any character that's not a digit
        const key = event.key;
        if (!/^[0-9]$/.test(key)) {
            event.preventDefault();
        }
    }

    @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent): void {
        // Get clipboard data from the event
        const clipboardData = event.clipboardData; // Correct usage of clipboardData
        const pastedText = clipboardData.getData('text');

        // Prevent pasting if the content is not numeric
        if (!/^[0-9]*$/.test(pastedText)) {
            event.preventDefault();
        }
    }
}
