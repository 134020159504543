import { Component, OnInit } from '@angular/core';
import { RejectpopupComponent } from './rejectpopup/rejectpopup.component';
import { MatDialog, MatPaginator, PageEvent } from "@angular/material";
import { PartnerService } from './partner.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-partner-program',
  templateUrl: './partner-program.component.html',
  styleUrls: ['./partner-program.component.scss']
})
export class PartnerProgramComponent implements OnInit {
  constructor(private dialog: MatDialog, private partnerService: PartnerService, public toasterService: ToastrService,
    private authService : AuthService) {
    if(this.authService.isPartnerProgrmaAdminAccess()){
      this.displayedColumns = ['name', 'email', 'phone', 'company', 'source', 'campaign', 'country', 'timestamp', 'remark', 'status', 'action']
    }
    else{
      this.displayedColumns = [
        'name', 'email', 'phone', 'company', 'source', 'campaign', 'country', 'timestamp', 'remark', 'status'];
    }
  }
  statusList = [{label:'Pending',value:"PENDING"}, {label: 'Approved',value: "APPROVED"}, {label: 'Rejected',value : "REJECTED"}];
  totalRecords = 10;
  pageNumber = 1;
  pageSize = 20;
  pageFrom = 0;
  dataSource: Array<any> = [];
  remark: string;
  currentStatus: any = {lable:null,value: null};
  displayedColumns: string[] = []
  ngOnInit() {
    this.partnerService.currentRemark.subscribe((res) => {
      this.remark = res;
    })
    this.getPartenerList();
  }

  getPartenerList() {
    const obj = {
      from: this.pageFrom,
      size: this.pageSize,
      status: this.currentStatus.value
    };
    this.partnerService.getPartenersList(obj).subscribe((res) => {
      this.dataSource = res['leadPartners'];
      this.totalRecords = res['total'];
    });
  }

  openAction(element,action) {
    const dialogRef = this.dialog.open(RejectpopupComponent, {
      width: "auto",
      height: "auto",
      data: {
        element, action
      },
    });
    dialogRef.afterClosed().subscribe((status) => {
      if (status == 'Accept') {
        this.takeActionOnPartner(element,"APPROVED");
      }else if(status == 'Reject') {
        this.takeActionOnPartner(element,"REJECTED");
      }
    });
  }

  getPaginatorData(event: PageEvent) {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.pageFrom = event.pageIndex * event.pageSize;
    this.getPartenerList();
  }

  applyFilter(status: any) {
    if (status) {
      this.currentStatus = status;
      this.getPartenerList();
    }
  }

  takeActionOnPartner(element,status) {
    const obj = {
      partnerId: element.id,
      remarks: this.remark,
      status: status
    }
    this.partnerService.getActionOnPartner(obj).subscribe((res) => {
      if(res){
        this.toasterService.success("Parterner " + this.formatStatus(status) + " Successfully");
        this.getPartenerList();
      }
      console.log(res);
    });
  }
  formatStatus(status: string): string {
    if (!status) return '';
    return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  }
  clearFilter(){
    this.currentStatus = { lable: null, value: null };;
    this.getPartenerList();
  }
}
